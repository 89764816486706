@import '../../colors.scss';

$margin: 15px;

.billing {
  .billing-chart {
    background: $secondary_bg;
    border-bottom: 1px solid $border;
  }

  .pending-row .ant-table-cell {
    background: $error_bg !important;
  }

  .pay-link {
    text-decoration: underline;
    color: $error;
    font-weight: 600;
  }

  .active-card {
    display: flex;
    align-items: center;
    padding: 20px 15px;
    background: white;
    border-bottom: 1px solid $border;
    width: calc(100% - $margin - $margin);
    margin: 15px auto;
    border: 1px solid $border;
    border-radius: 10px;
  }

  .active-card-img {
    width: 50px;
    margin-top: 5px;
    margin-right: 15px;
    display: inline-block;

    svg {
      width: 100%;
    }
  }

  .active-card-content {
    display: inline-block;
  }

  .active-card-label {
    margin-bottom: 0;
  }

  .active-card-details {
    margin-bottom: 0;
    font-weight: 600;
  }

  .change-card-btn {
    margin-left: auto;
    font-weight: 600;
    height: 38px;
    width: 38px;
  }

  .card-history {
    display: flex;
    align-items: center;
  }

  .card-history svg {
    height: 16px;
    margin-right: 10px;
  }

  .patient-link {
    color: $success;
  }

  .charge-created {
    font-size: 14px;
  }

  .status-text {
    font-size: 14px;
  }

  .Paid-status-text {
    color: $success;
  }

  .Overdue-status-text,
  .Pending-status-text {
    color: $error;
  }

  .Refunded-status-text {
    color: $secondary_text;
  }

  .refund-btn {
    background: white;
    color: $primary_text;
    border: 1px solid $border;
    padding: 0 8px;
    height: 30px;
    font-size: 14px;

    &:hover,
    &:focus {
      border-color: $success !important;
      color: $success !important;
    }

    &:active {
      border-color: darken($success, 10) !important;
      color: darken($success, 10) !important;
    }
  }

  .cash-container {
    max-width: 600px;
    padding: 16px 16px 0;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;

    .card-info {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .card-brand svg {
      width: 40px;
      height: 25px;
    }

    .card-details {
      display: flex;
      flex-direction: column;
    }

    .card-actions {
      display: flex;
      gap: 8px;

      .ant-btn-link {
        color: #8C8C8C;

        .anticon {
          color: #8C8C8C;
        }

        &:hover {
          color: #000;

          .anticon {
            color: #000;
          }
        }
      }
    }
  }

}