@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h4,
  $h5,
) {
  .questions {
    padding: 0 $horizontal_padding;
    margin-top: calc($vertical_padding/2);
    max-width: $max_width;
    margin-left: auto;
    margin-right: auto;

    .ant-collapse-header-text {
      font-size: $h4 !important;
      font-weight: 500 !important;
    }

    .ant-collapse-expand-icon {
      margin-top: 2px;
    }

    .answer {
      font-size: $h4;
    }

    .ant-collapse-arrow {
      font-size: $h5 !important;
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h4: $mobile_h4,
    $h5: $mobile_h5,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h4: $desktop_h4,
    $h5: $desktop_h5,
  );
}