@import '../../colors.scss';

.pay-membership-modal {
    .pay-membership-modal-description {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
        padding: 1rem;
        border-radius: 8px;
        margin: 1rem 0;

        // Left side container
        .pay-membership-modal-description-left {}

        // Price on right side
        .pay-membership-modal-description-price {
            font-size: 1rem;
            font-weight: 600;
        }


    }

    .pay-membership-modal-description-credit {
        background-color: #f0f9ff;
        border: 1px solid #bae6fd;
        border-radius: 6px;
        padding: 12px 16px;
        margin: 16px 0;
        display: flex;
        align-items: center;
        gap: 12px;

        .credit-icon {
            font-size: 20px;
        }

        .credit-message {
            color: #0369a1;
            font-weight: 500;
        }
    }

    .card-form .saved-card-details {
        margin-top: 1rem;
        background-color: transparent;
        border: 1px solid $border
    }

    .card-form .confirm-btn {
        margin-top: 1rem;
    }

}