@import '../../colors.scss';

.payment-link-register {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  padding: 0 10px;

  .register-title {
    font-size: 24px;
    font-weight: 600;
    color: $primary_text;
    margin-bottom: 0; 
  }

  .register-description {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 30px;
    color: $secondary_text;
  }

  .message-link {
    color: $success;

    &:hover {
      color: $success;
    }

    &:active {
      color: darken($success, 10);
    }
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }

  .submit-btn {
    width: 100%;
    height: 43px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
  }
}