@import '../../colors.scss';
@import '../../global.scss';

$mobile_height: 52px;

.mobile-header-padding {
  height: calc($mobile_height);
}

.mobile-header {
  position: fixed;
  height: $mobile_height;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; // Make sure elements are spaced apart
  padding: 0 15px;
  background: white;
  border-bottom: 1px solid $border;

  .menu-btn {
    position: relative;
    left: 0;
    border: 0;
    box-shadow: none;
    margin-top: 3px;
    // width: 40px;
    // height: 40px;
    // margin-top: 5px;
    // display: flex;
    // align-items: center; // Vertically center the icon
    // justify-content: center; // Horizontally center the icon (if needed)
  }


  .logo-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;

    .logo-icon {
      height: 18px;
      margin-right: 8px;
    }

    .logo-text {
      font-size: 15px;
      display: inline-block;
      letter-spacing: 0.3px;
      font-weight: 600;
    }
  }

  .login {
    cursor: pointer;
    font-size: 20px;
    display: flex;
    align-items: center; // Vertically center the icon
    justify-content: center; // Horizontally center the icon (if needed)
    width: 35px;
    height: 35px;
    position: absolute;
    right: 16px; // Adjust the right margin to align properly
    top: 20%; // Align it vertically with respect to the header

  }
}

.mobile-drawer {
  .ant-drawer-body {
    padding: 0;
    background: $secondary_bg;
  }
}