@import '../../../../colors.scss';

.schedule {
  .date-selection {
    margin-bottom: 40px;
    margin-top: 30px;
    width: 100%;
    position: relative;
    padding-left: 30px;
    padding-right: 30px;

    .ant-form-item-explain-error {
      margin-left: -30px;
    }

    .date-skeleton-container {
      width: 523px;
    }

    .date-skeleton {
      height: 123px !important;
    }

    .left-icon,
    .right-icon {
      width: 30px;
      position: absolute;
      top: 0;
      height: 104.5px;
      z-index: 1;
      border-radius: 0;
    }

    .adm-selector-item-disabled {
      opacity: 0.3;
    }

    .left-icon {
      left: 0;
    }

    .right-icon {
      right: 0;
    }

    .date-option {
      color: $primary_text;
      text-align: center;
      padding: 10px 0;
    }

    .date-day-of-week,
    .date-month {
      color: $primary_text;
      display: block;
      font-size: 11px;
    }

    .date-day {
      color: $primary_text;
      display: block;
      font-weight: 600;
      font-size: 24px;
      margin: 5px 0;
    }

    .adm-selector {
      display: block;
      width: 100%;
    }

    .adm-selector-item {
      background: #F9FBFC;
      color: $primary_text;
      border-radius: 0px;
      padding: 0;
      display: inline-block;
      border: 1px solid $border;
      border-left: 0;
      border-right: 0;
      width: 100%;
    }

    .adm-selector-item-multiple-active,
    .adm-selector-item-active {
      color: $success;
      background: $primary_bg;
      border: 1px solid $success !important;
    }

    .adm-space {
      display: block;
    }

    .adm-space-item {
      margin-right: 0;
      display: inline-block;
      width: calc(100%/5);
    }

    .adm-selector-check-mark-wrapper {
      border-right-color: $success;
      border-bottom-color: $success;
    }

    .adm-list-item {
      padding-left: 0;
      background: rgba(0, 0, 0, 0);
    }

    .adm-selector-item-active .date-day {
      color: $success;
    }
  }

  .loading-times {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $secondary_bg;
    padding: 20px 0;
    border-radius: 5px;
  }

  .loading-times-text {
    color: $secondary_text;
    padding-left: 10px;
  }

  .time-selection {
    .adm-space-wrap {
      display: block;
    }

    .adm-space-item {
      display: inline-block;
    }

    .adm-selector {
      display: inline-block;
      width: 100%;
    }

    .adm-selector-item {
      background: #F9FBFC;
      color: $primary_text;
      border-radius: 3px;
      border: 1px solid $border;
      display: inline-block;
      font-size: 12px;
      padding: 10px 0;
      width: 100%;
    }

    .adm-selector-item-multiple-active,
    .adm-selector-item-active {
      color: $success;
      background: $primary_bg;
      border: 1px solid $success;
    }

    .adm-selector-check-mark-wrapper {
      border-right-color: $success;
      border-bottom-color: $success;
    }

    .adm-list-item {
      padding-left: 0;
      background: rgba(0, 0, 0, 0);
    }

    .confirm-btn {
      height: 45px;
      padding: 0 40px;
      margin-top: 40px;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
  }

  .getlabs-time-selection {
    .adm-space-wrap {
      width: calc(100% + 10px);
    }

    .adm-space-item {
      margin-right: 10px;
      width: calc(50% - 10px);
    }
  }

  .instalab-time-selection,
  .facility-time-selection,
  .quest-time-selection {
    .adm-space-wrap {
      width: calc(100% + 10px);
    }

    .adm-space-item {
      margin-right: 10px;
      width: calc(25% - 10px);
    }
  }
}