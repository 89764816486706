@import '../../../colors.scss';

.cardio-advice {
  .test-item-list {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .test-chart {
    margin-top: 30px;
    margin-bottom: 30px;

  }

  .advice-section {
    margin-top: 30px;
  }

  .genetics-section {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .discordant-section {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .video-container {
    display: block;
    padding-top: 180%;
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 40px;
  }

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-icon {
    font-size: 80px;
    color: white;

    &:active {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.genetics-section {
  background: $secondary_bg;
  border-radius: 10px;
  padding: 30px 30px;


  .genetics-section-title {
    color: $primary_text;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.75rem;
  }
}