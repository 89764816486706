@import '../../colors.scss';

.patient-spread-modal {
  .test-select {
    width: 200px;
    float: left;
  }

  .patient-select {
    width: 200px;
    margin-left: 10px;
  }

  .test-select,
  .patient-select  {
    .ant-select-selection-item {
      font-size: 12px;
      font-weight: 500;
    }
  }

  .legend-col {
    padding-top: 10px;
  }

  .legend-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-radius: 3px;
  }

  .abnormal-legend-item:hover,
  .high-legend-item:hover {
    background: $error_bg;
  }

  .moderate-legend-item:hover {
    background: $warning_bg;
  }

  .optimal-legend-item:hover {
    background: $success_bg;
  }

  .legend-color {
    width: 15px;
    height: 15px;
    border-radius: 3px;
    margin-right: 10px;
    font-size: 7px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .legend-title {
    color: $primary_text;
    font-size: 10px;
  }

  .legend-percentage {
    font-size: 10px;
    color: $secondary_text;
    flex: 1;
    text-align: right;
  }
}