@import '../../colors.scss';

.flexible-table {
  td a {
    color: $success;
  }

  .ant-table-filter-trigger {
    margin-inline-end: 0 !important;
  }
}

.menu-overlay {
  .ant-dropdown-menu-item {
    padding: 0 !important;
  }
  
  button {
    width: 100%;
    border: 0;
    text-align: left;
    justify-content: flex-start;
    overflow: hidden;
  
    &:hover {
      background: $secondary_bg !important;
    }
  
    &:active {
      background: darken($secondary_bg, 3) !important;
    }
  }

  button:not(.remove-item) {
    box-shadow: none !important;
    color: $primary_text !important;
  }
}

.ant-table-filter-dropdown {
  .ant-btn-link {
    color: $success !important;
  }
}