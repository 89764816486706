@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .product-details-x-panels {
    margin: auto;
    display: flex;
    list-style: none;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding: 1rem;
    gap: 2rem;
    overflow-y: auto;

    .product-details-x-panel {

      scroll-snap-align: start;
      width: 20rem;


      .panel-img {
        width: 100%;
        filter: grayscale(33%);

      }

      .panel-title {
        font-size: $h4;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 0px;
      }

      .panel-description {
        padding-top: $h5;
        font-size: $h4;
        line-height: $h4_line_height;
        color: rgba(0, 0, 0, 0.6);
      }

      .panel-img-container {
        background: $secondary_bg;
        padding: 10px 0px 5px;
        border-radius: 10px;
      }

    }

  }
}

@media (max-width: $breakpoint_desktop) {
  .product-detail-xpanels-section-container {
    padding-right: 0px !important;

    .product-detail-xpanel:last-child {
      margin-right: 15px;
    }
  }

}


@media screen and (max-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

  .product-details-x-panels {
    gap: 1rem; // Reduce gap on smaller screens
  }

  .product-details-x-panel {
    max-width: 25rem;
    min-width: 15rem; // Adjust width for smaller screens
  }
}

@media screen and (min-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}