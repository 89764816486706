@import '../../colors.scss';
@import '../../global.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-image-gallery/styles/css/image-gallery.css";

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
) {
    .product-detail {
        font-size: $h4;
        line-height: $h4_line_height;

        .gray-bg {
            background: #f1f1f1
        }

        .footer-buffer {
            height: 60px;
        }


        .product-detail-section-title {
            margin: 0rem 0px 3.5rem;

            span {
                display: block;
                font-weight: normal;
                font-size: 1.25rem;
                margin-top: 1.5rem;
                line-height: 2rem;

            }
        }


    }
}

@media (max-width: $breakpoint_desktop) {
    .product-detail {

        .section.product-detail-hero {
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .section-hero {
            padding-bottom: 0;
            padding-top: 1rem;
        }

    }
}

@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
    );
}