@import '../../../../colors.scss';
@import '../../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .member-intro {
    position: relative;

    .intro-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .member-intro-body {
      background: white;
      max-width: 700px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      padding: $vertical_padding $horizontal_padding;
    }

    .member-pricing-container {
      text-align: center;
      margin-bottom: calc($vertical_padding/5);
    }

    .member-pricing {
      color: $success;
      background: lighten($success, 55);
      font-size: $h5;
      font-weight: bold;
      padding: 5px 15px;
      display: inline-block;
      border-radius: 3px;
    }

    .member-title {
      text-align: center;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: calc($vertical_padding/3);
      font-size: $h2;
      line-height: $h2_line_height;
    }

    .member-subtitle {
      text-align: center;
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: calc($vertical_padding/3);
      font-size: $h4;
      line-height: $h4_line_height;
    }

    .member-description {
      text-align: center;
      font-size: $h4;
    }

    .member-description a {
      text-decoration: underline;
      color: $success;
    }

    .close-icon {
      color: rgba(0, 0, 0, 0.3);
      margin: calc($vertical_padding/3) 0 0;
    }

    .question-link-container {
      margin: calc($vertical_padding/4) 0;
      text-align: center;
    }

    .question-link {
      color: rgba(0, 0, 0, 0.5);
      display: inline-block;
    }

    .membership-points {
      .point-list {
        margin-bottom: 15px;
        padding: 30px 30px 10px 30px;
        text-align: left;
      }

      .point-item {
        margin-bottom: 25px;
        width: 100%;
      }

      .point-item strike {
        color: #bbb;
      }

      .point-icon {
        margin-top: 4px;
        color: $success;
      }

      .point-svg {
        svg {
          height: 60px;
          width: auto;
        }
      }

      .point-title {
        font-weight: 500;
        font-size: $h5;
        line-height: $h5_line_height;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .point-description {
        font-size: $h5;
        line-height: $h5_line_height;
        color: $secondary_text;
        margin-bottom: 0;
      }

      .point-col {
        width: 30px;
      }

      .text-col {
        width: calc(100% - 30px);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}