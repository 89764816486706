@import '../../colors.scss';

.forgot-password-modal {
  .directions-text {
    color: $secondary_text;
  }

  .email-item {
    margin-top: 20px;
    display: block;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }
}