@import '../../global.scss';

.notes {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .page-header {
        padding: 10px 15px;
    }

    .noteText p {
        margin: 0
    }

    .primary-table .ant-table-tbody>tr>td:last-child {
        padding-right: 5px;
    }


}