@import '../../colors.scss';

.note-drawer {
  .note-text {
    font-size: 13px;
    background: $secondary_bg;
    padding: 5px 15px;
    border-radius: 5px;
  }

  .note-footer {
    font-size: 11px;
    color: $secondary_text;
    margin-bottom: 20px;
  }

  .remove-note-link {
    color: $error;
  }

  .edit-note-link {
    color: $secondary_text;
  }

  .text-item {
    margin-bottom: 10px;
  }
}
