@import '../../global.scss';
@import '../../colors.scss';

.signup-page {
    // min-height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // background: $success_bg;
    // padding-right: 1.5rem;
    // padding-left: 1.5rem;
    // margin-top: -5vh;


    .signup-form {

        h1 {
            text-align: center;
            margin-bottom: 1.5rem;
            font-size: 1.5rem;
            font-weight: 600;
            color: #1a1a1a;
            word-wrap: break-word;
            overflow-wrap: break-word;

            @media (min-width: 768px) {
                font-size: 1.75rem;
            }
        }

        .ant-form-item-label {
            font-weight: 500;
        }

        // .ant-input,
        // .ant-input-password {
        //   height: 42px;
        //   border-radius: 6px;
        // }

        .error-message {
            color: $error;
            background: $error_bg;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 12px;
            margin-top: 10px;
            display: inline-block;
        }

        .ant-btn {
            width: 100%;
            height: 42px;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 6px;
        }
    }

    .login-links {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-top: 16px;
        width: 100%;

        a {
            cursor: pointer;
            color: $success;
            transition: color 0.3s;
            font-size: 0.95rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }

}