@import '../../global.scss';
@import '../../colors.scss';

.admin-results {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .incomplete-past-due .ant-table-cell {

    background: $error_bg !important;

  }
}