.express-checkout {
    text-align: center;

    .express-checkout-container {
        width: 100%;
    }

    .express-checkout-container-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 48px;
        text-align: center;
        position: relative;

        .spinner-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }
    }

    .confirm-btn,
    .skip-btn {
        width: 100%;
        margin-top: 1rem;
        font-weight: 600;
        padding: 0 15px;
        height: 40px;
        font-size: 16px;
    }

    .error-message {
        margin-top: 1rem;
    }



}