@import '../../colors.scss';

.text-05 {
  font-size: 38px;
  line-height: 44px;
}

.text-04 {
  font-size: 24px;
  line-height: 30px;
}

.text-03 {
  font-size: 16px;
  line-height: 23px;
}

.text-02 {
  font-size: 14px;
}

.text-01 {
  font-size: 12px;
  line-height: 16px;
}

.tasso-popover {
  padding: 20px;

  .tasso-img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .tasso-title {
    @extend .text-04;
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .tasso-description {
    @extend .text-03;
    color: #666666;
    margin: 0;
  }
}