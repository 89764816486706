@import '../../colors.scss';
@import '../../global.scss';

.admin-products-table {

  font-size: 14px !important;

  a.tracking-link {
    color: $success;
  }

  .tracking-col {
    text-align: left;
    max-width: 200px;

    hr {
      width: 180px;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }
  }

  .product-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 700;
  }

  .pending-status {
    color: $secondary_text;
    background: $secondary_bg;
  }

  .collected-status {
    background: $warning_bg;
    color: $warning;
  }

  .complete-status,
  .delivered-status {
    background: $success_bg;
    color: $success;
  }

  .canceled-status,
  .deactivated-status {
    color: $error;
    background: $error_bg;
  }

  .confirmed-status,
  .scheduled-status,
  .delivered-status,
  .shipped-status,
  .fulfilled-status,
  .fulfilling-status {
    color: $primary_text;
    background: $secondary_bg;
  }
}