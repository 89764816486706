@import '../../colors.scss';

.card-form {
  // .pay-item {
  //   margin-top: 40px;
  // }

  .secure-payment-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 12px;
    color: #666;
    font-size: 14px;

    .lock-icon {
      font-size: 16px;
    }
  }

  .ant-form-item-control-input-content {
    background: $secondary_bg;
    border-radius: 5px;
  }

  .StripeElement {
    padding: 16px 15px;
    border: 1px solid $border;
    border-radius: 5px;

    &:hover:not(.StripeElement--invalid) {
      background: $green_bg;
      border: 1px solid $success_light;
    }
  }

  .StripeElement--focus:not(.StripeElement--invalid) {
    border: 1px solid $success !important;
    background: $green_bg;
  }

  .StripeElement--invalid {
    border: 1px solid $error !important;
    background: $error_bg;
  }

  .saved-card-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    position: relative;
    background: $secondary_bg;
    border-radius: 10px;
    // margin-top: 25px;

    .saved-card-logo {
      padding-right: 15px;

      svg {
        width: 35px;
      }
    }

    .saved-card-content {
      flex: 1;
      padding-top: 5px;
    }

    .saved-card-label {
      display: block;
      color: $secondary_text;
      margin-bottom: 2px;
      margin-top: -7px;
      font-size: 13px;
    }

    .saved-card-description {
      color: $primary_text;
      font-weight: 600;
      font-size: 13px;
      margin-bottom: 0;
    }

    .remove-btn {
      background: white;
      width: 30px;
      height: 30px;
      border: 1px solid $border;
      padding: 0;

      .anticon {
        font-size: 11px;
      }

      &:hover {
        border: 1px solid $error;
        color: $error;
      }

      &:active,
      &:focus {
        border: 1px solid darken($error, 10);
        color: darken($error, 10);
      }
    }
  }

  .card-form-disclaimer {
    margin-top: 1rem;
    font-size: 12px;
    color: $secondary_text;
  }

  .confirm-btn,
  .skip-btn {
    width: 100%;
    margin-top: 1rem;
    font-weight: 600;
    padding: 0 15px;
    height: 48px;
    font-size: 16px;
  }

  .card-input-fields {
    .card-row {
      display: flex;
      gap: 16px;

      .expiry {
        flex: 1;
      }

      .cvc {
        flex: 1;
      }

      .postal {
        flex: 1;
      }
    }
  }

  .stripe-element {
    padding: 8px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    &--focus {
      border-color: $success;
      // box-shadow: 0 0 0 2px $success_light;
    }

    &--invalid {
      border-color: $error;
    }
  }

  .appointment-note {
    margin-bottom: 20px;
    border-radius: 8px;

    // Match the form's styling
    font-size: 14px;
    line-height: 1.5;
  }

  // Mobile-specific improvements
  @media screen and (max-width: 768px) {
    .ant-form-item {
      margin-bottom: 24px; // Increased spacing between form fields on mobile
    }

    .pay-fields {
      padding-bottom: 120px;
    }

    .payment-form-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: #f6f6f6;
      padding: 12px 16px;
      box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
      z-index: 100;

      .confirm-btn {
        width: 100%;
        margin-bottom: 8px;
      }

      .secure-payment-indicator {
        margin-top: 6px;
        margin-bottom: env(safe-area-inset-bottom); // Support for devices with bottom safe area
      }
    }

    // Add padding to the bottom of the form to prevent content from being hidden behind sticky footer
    .card-form {
      padding-bottom: calc(120px + env(safe-area-inset-bottom));
    }
  }
}


.ElementsApp .Icon-fill {
  fill: $secondary_bg !important;
}