@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height) {
  .heart {
    .accepted-insurance {
      padding: 6px 0px;
      font-size: 13px;
      margin-top: 15px;

      svg {
        margin-right: 2px;
      }
    }

    .join-btn,
    .scroll-join-btn,
    .intro-btn {
      margin: 0 5px;
      width: 225px;
      font-weight: 600;
      height: 50px;
      font-size: $h4;

      .btn-icon {
        font-size: 12px;
      }

    }


    .desktop-cta-btn {
      padding: 0 20px;
      font-weight: 600;
      height: 50px;
      font-size: $h4;
      margin-top: $h4_line_height;
      display: block;

      .btn-icon {
        font-size: 12px;
      }
    }

    .heart-title {
      font-size: $h2;
      line-height: $h2_line_height;
      font-weight: 600;
      margin-bottom: 0 !important;
      text-align: center;
  
    }
  
    .heart-title-base {
      width: 100px;
      height: calc($vertical_padding/10);
      background: $success;
      margin-top: 10px;
      margin-bottom: calc($vertical_padding/2);
      margin-right: auto;
      margin-left: auto;
    }
  
    .heart-link {
      color: $secondary_text !important;
      text-decoration: underline !important;
      text-decoration-style: dotted !important;
  
    }
  
  
    .heart-intro {
      padding: $vertical_padding $horizontal_padding;
      // margin-bottom: calc($vertical_padding/2);
      text-align: center;
      background-color: $success_bg;
  
  
  
      .intro-title {
        font-weight: 600;
        font-size: $h1;
        line-height: $h1_line_height;
        margin: 0 auto calc($vertical_padding/2);
        max-width: 500px;
        font-family: 'Cooper LT BT';
        letter-spacing: 1px;
      }
  
      .intro-description {
        font-size: $h4;
        line-height: $h4_line_height;
        margin: 0 auto calc($vertical_padding/2);
        max-width: 450px;
  
      }
  
  
    }
  
    .heart-intro.no-bg {
      background-color: white;
    }
  
    .heart-how {
      padding: $vertical_padding $horizontal_padding 0 $horizontal_padding;
  
      .how-step {
        margin-bottom: calc($vertical_padding);
        // border-left: 3px solid $success;
        // padding-left: 20px;
      }
  
      .how-step-number {
        font-size: $h1;
        color: $success;
        font-weight: 600;
        padding-top: 20px;
      }
  
      .how-step-title {
        font-size: $h3;
        font-weight: 600;
      }
  
      .how-step-description {
        font-size: $h4;
        line-height: $h4_line_height;
        margin-bottom: 25px;
        padding-bottom: $vertical_padding;
      }
  
      .how-step-img {
        width: 100%;
        border-radius: 5px;
      }
    }
  
    .heart-services {
      padding: $vertical_padding $horizontal_padding $vertical_padding $horizontal_padding;
      background: $secondary_bg;
  
      .service-link {
        color: $success !important;
      }
  
      .service-img {
        width: 100%;
        border-radius: 5px;
      }
  
      .service-title {
        font-size: $h3;
        line-height: $h3_line_height;
        font-weight: 600;
        margin-top: calc($vertical_padding/2);
        margin-bottom: 10px;
      }
  
      .service-description {
        font-size: $h4;
        line-height: $h4_line_height;
      }
    }
  
    .heart-testimonials {
      padding: $vertical_padding $horizontal_padding 0 $horizontal_padding;
  
      .testimonial-img {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 20px;
      }
  
      .testimonial-quote {
        font-size: $h4;
        line-height: $h4_line_height;
      }
  
      .testimonial-attribute {
        font-size: $h4;
        line-height: $h4_line_height;
        font-weight: 600;
      }
    }
  
    .heart-faq {
      padding: $vertical_padding $horizontal_padding;
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height );



  .heart {

    .join-btn,
    .intro-btn {
      position: static;
      display: block;
      margin: 15px auto;
      width: 75%;
      /* Adjust width as needed */
      text-align: center;
    }

    .scroll-join-btn {
      bottom: 0;
      margin: auto;
      width: 90%;
      left: 20px;
    }

    .desktop-cta-btn {
      display: none;
    }
  }

  .service-description {
    margin-bottom: 25px !important;
  }

  .how-step-img-container {
    order: 2; // Ensure images appear after the text on mobile
    margin-bottom: 35px;
  }

  .how-step-container {
    order: 1; // Ensure text appears before the image on mobile
    margin-bottom: 35px !important;
  }

  .testimonial-attribute {
    margin-bottom: 35px !important;
  }

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height );
}