@import '../../colors.scss';
@import '../../global.scss';

.medical-info {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .primary-table {
    width: 100% !important;
  }



  .page-header {
    padding: 10px 15px;
  }

  .step-label-text,
  .not-available-text {
    color: $secondary_text;
    font-size: 14px;
  }

  .label-col {
    vertical-align: top;
    min-width: 70px;
  }

  .ant-table-tbody .ant-table-row:first-child .ant-table-cell {
    border-top: 0 !important;
  }

  .ant-table-cell {
    vertical-align: top !important;
  }

  ul {
    margin: 0;
    padding: 0 0 0 15px;

    li {
      line-height: 25px;
    }
  }

  .pointer-row {
    cursor: pointer;
  }

  .default-row {
    cursor: default;
  }

  .staff-only-box {
    padding: 10px;
    background: $primary_bg;
    border: 1px solid rgba(108, 199, 211, 0.2);
    border-radius: 5px;
    color: $primary_text;

    .staff-only-box-header {
      padding: 10px 0;
      font-weight: 600;
      font-size: 12px;
      color: $secondary_text;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(108, 199, 211, 0.2);
    }

  }


  .history-tooltip-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    padding: 8px 0;
  }

  .history-container .history-tooltip-row:last-child {
    border-bottom: 0;
  }

  .history-tooltip-col {
    font-size: 13px;

    ol,
    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    .primary-table {
      width: 100% !important; // Ensure the table takes full width on mobile
    }

    .primary-table colgroup {
      display: none; // Hide the colgroup to avoid width conflicts
    }

    .primary-table .ant-table-tbody {
      display: block; // Make tbody behave like a block container on mobile
      width: 100% !important;
    }

    .primary-table .ant-table-tbody>tr {
      display: block; // Ensure rows take full width and behave like block elements
      width: 100% !important;
      // margin-bottom: 15px; // Add spacing between rows
      border-bottom: 1px solid #e0e0e0; // Add a light border for separation
      border-top: 0px;
      padding: 15px 0px;
    }

    // .ant-table-wrapper .ant-table:not(.ant-table-bordered) .ant-table-tbody > tr > td

    .primary-table .ant-table-tbody>tr>td {
      display: block; // Ensure cells take full width
      width: 100% !important;
      word-wrap: break-word; // Ensure long text wraps properly
      border-top: 0px !important;
    }

    .primary-table .ant-table-tbody>tr>td:first-child {
      font-weight: 700;
      padding: 10px 0 0px;
      border: 0; // margin-bottom: 5px; // Add space between label and value
    }
  }
}