$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

:export {
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
  xxl: $xxl;
}