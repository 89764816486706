.notifications {
    .notification-content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .notification-title {
        font-weight: 600;
        min-width: 200px;
    }

    .notification-description {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;


    }

    .notification-row {
        display: flex;
        align-items: center;
        gap: 24px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
        }
    }

    .switch-column {
        vertical-align: middle;
        display: flex;
        align-items: center;
        height: 100%;
    }
}