@import '../../colors.scss';

.exposure-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  height: auto;
  background-color: #ffffff;
}

.exposure-title {
  color: #000000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
  text-align: center;
}

.chart-container {
  width: 100%;
  height: 400px;
}

.stats-row {
  padding: 20px 0 20px 60px;
  justify-content: space-between;
  margin-right: -20px;
}


.slider-container {
  padding: 20px 0px 20px 0px;
} 

.count-suffix {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 4px;
}

.stats-col {
  justify-content: center;

  // Add to exposureDistribution.scss
  .ant-statistic {
    &:hover {
      opacity: 0.8;
      transition: opacity 0.2s;
    }
  }

  .ant-statistic-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-statistic-title,
  .ant-statistic-content,
  .ant-statistic-content-value {
    text-align: center;
    display: block !important;
  }

  .ant-statistic-content-suffix {
    display: block !important;
    margin-top: 0px !important;
  }
}

.exposure-patient-link {
  color: $primary_text !important;
  border-bottom: 1px dotted $primary_text;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
}

.ct-scan-table {
  background: transparent !important;

  .ant-table {
    background: transparent !important;
  }

  .ant-table-cell {
    border-color: rgba(255,255,255,0.1) !important;
    color: white !important;
    background: transparent !important;
  }
}

.sync-icon {
  color: #ccc;
  cursor: pointer;
  transition: transform 0.5s ease;
  font-size: 16px;
  margin-left: 4px;

  &:active {
    animation: clickPulse 0.5s ease;
    transform: scale(1.25);
  }
}