@import '../../../../colors.scss';

.gift-details {
    .multiple-input-form {
        width: 100%;
    }

    .field-container {
        position: relative;
    }

    .multiple-input {
        &-item {
            margin-bottom: 10px;
        }

        &-field {
            background: #f8f8f8;
            font-size: 18px;
            height: 52px;

            &:hover {
                background: $green_bg;
                border: 1px solid $success_light;
            }

            &:focus {
                background: $green_bg;
                border: 1px solid $success;
            }
        }
    }

    .field-suffix {
        position: absolute;
        top: 11px;
        right: 10px;
        font-size: 18px;
        height: 100%;
        color: $secondary_text;
    }

    .suffix-item {
        position: absolute;
        right: 4px;
        top: 34px;
        width: 100px;
    }

    .ant-picker {
        width: 100%;

        .ant-picker-input>input {
            font-size: 16px !important;
        }

        .ant-picker-input>input::placeholder {
            font-size: 16px !important;
        }
    }

    .field-group {
        margin-bottom: 24px;

        .group-label {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 16px;

            .anticon {
                color: black;
                font-size: 9px;
                margin-right: 4px;
                margin-left: -16px;
            }
        }
    }

    .product-select-label {
        .option-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }


    .price-button-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;

        .option-price {
            font-weight: 600;
            font-size: 16px;
        }

        .select-gift-button {
            min-width: 80px;
            height: 32px;
            // padding: 4px 15px;
        }
    }


    .option-title-container {

        .option-title,
        .option-description {
            text-align: left;
        }

        height: 8em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        // -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @media screen and (max-width: 767px) {
            // sm breakpoint
            height: unset;
        }
    }

    .ant-radio-wrapper {
        width: 100%;
        margin-right: 0;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s, transform 0.3s;
        cursor: pointer;
        padding: 0px 20px;

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            transform: translateY(-5px);
        }


        .ant-radio {
            position: absolute;
            top: 20px;
            right: 20px;
            display: none;
        }

    }

}