@import '../../global.scss';
@import '../../colors.scss';

.admin-memberships-table {
  .status-label {
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
  }

  td {
    vertical-align: middle;
  }

  a {
    color: $success;
  }

  .Active-status {
    background: $success_bg;
    color: $success;
  }

  .Overdue-status {
    background: $error_bg;
    color: $error;
  }

  .Canceled-status,
  .Deactivated-status,
  .pending-status {
    color: $secondary_text;
    background: $secondary_bg;
    font-size: 13px;
  }

  .status-btn {
    font-size: 12px;
    height: 30px;
  }

  .ant-btn-default {
    &:hover {
      border-color: lighten($success, 15);
      color: lighten($success, 15);
    }

    &:active {
      border-color: darken($success, 15);
      color: darken($success, 15);
    }
  }

  .action-cell {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}