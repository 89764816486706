@import '../../../colors.scss';
@import '../../../global.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "react-image-gallery/styles/css/image-gallery.css";

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
) {
    .product-detail-hero-container {
        display: flex;
        flex-direction: row;
        margin: auto;
        font-size: $h4;
        line-height: $h4_line_height;

        .product-price.crossed-out {
            text-decoration: line-through;
        }

        .product-photos-desktop {
            flex: 1;
            padding-right: $horizontal_padding;
            // padding-top: $h5;
        }

        .product-description {
            flex: 1;
        }

        .product-photos-mobile {
            background: rgba(13, 163, 128, 0.2);
            padding: 0;
        }


        .product-title {
            font-size: $h2;
            font-weight: 600;
            line-height: $h2_line_height;
            margin-bottom: $h5;
            // font-family: 'Cooper LT BT';
            // letter-spacing: 1px;
        }

        .product-pricing {
            display: flex;
            align-items: center;
            font-size: calc(1.2*$h3);
            line-height: $h3_line_height;
            font-weight: 600;
            margin-bottom: 15px;




            .product-member-price {
                color: #ff3b30;

                font-weight: 600;
                margin-left: 5px;
            }

            .member-tag {
                margin-left: 10px;
                color: #ff3b30;
                border: 1px solid #ff3b30;
                background-color: transparent;
            }
        }

        .bnpl-option {
            font-size: $h4;
            // margin-left: 5px;
            font-weight: 400;
            display: block;
            transition: color 0.2s ease-in-out;
            margin-bottom: 15px;

            .bnpl-link {
                text-decoration: underline;
                color: #4CAF50;

                &:hover {
                    color: #388E3C;
                }
            }

            div {
                min-height: 24px;
            }

        }


        .fsa-hsa-badge-container {
            width: 100%;

            .fsa-hsa-badge {
                width: 100%;
                background-color: rgba(245, 245, 245, 0.65);
                color: #445973;
                margin-top: 0.5rem;

                svg {
                    color: #445973;
                }
            }
        }


        .product-note {
            background-color: #f9f9f9;
            padding: 20px;
            border-left: 4px solid #28a745;
            margin-bottom: 20px;
            font-size: $h5;
            line-height: $h5_line_height;

        }

        .product-photos-solo {
            width: 100%;
        }

        .product-points {
            padding: 10px 0px;
        }

        .product-point {
            margin-bottom: 12px;
        }

        .point-icon {
            vertical-align: middle;
            display: inline-block;
            position: relative;
            margin-top: -2px;
            flex-shrink: 0;
            transform: rotate(0deg);
            transition: var(--interactions-duration-fast) transform var(--interactions-easing-expressive-open);
            margin-right: $h5;
        }


        .product-cta {
            margin: 5px 0px 20px;
        }

        .product-cta-button,
        .product-gift-button {
            width: 100%;
            font-weight: 600;
            height: $h1;
            font-size: $h4;
            margin-top: 0.5rem;
        }

        .product-top-faq {
            padding: 1rem 0;

            .ant-typography {
                margin-bottom: 0;
            }

            .ant-collapse .ant-collapse-item {
                border-bottom: 1px solid darken(#E6E6E6, 5%);
            }

            .product-detail-questions {
                padding: 0;
                margin: 0;


                .ant-collapse-header-text {
                    font-weight: 500 !important;
                }



                .ant-collapse-item>.ant-collapse-header {
                    padding: 10px 0px !important;
                }

                .answer {
                    background-color: $success_bg;
                    padding: 15px;
                    border-radius: 8px;
                    font-size: 1rem;
                    color: #555;
                    line-height: 1.6;

                    .checkmark-list {
                        list-style-type: none;
                        padding-left: 0;
                        margin-top: 10px;

                        li {
                            display: flex;
                            align-items: flex-start;
                            margin-bottom: 5px;
                            position: relative;
                            margin-left: 0;
                            position: relative;
                        }

                        li svg {
                            color: $success;
                            margin-right: 8px;
                            flex-shrink: 0;
                            font-size: 0.9rem;
                            position: absolute;
                            top: 0.25rem;
                        }


                        li strong {
                            font-weight: 600;
                            color: #333;
                            /* Adjust color for emphasis */
                        }

                        li p {
                            margin: 0;
                            margin-left: 20px;
                        }
                    }

                }

                .answer a {
                    color: $success;
                    font-weight: 600;
                    text-decoration: underline;
                    transition: color 0.3s ease;
                }

            }


        }


        .image-gallery-thumbnails-wrapper {
            display: flex;
            flex-wrap: wrap; // Allow thumbnails to wrap to a new row
        }

        .image-gallery-thumbnail {
            width: calc(20% - 4px); // Set width to 20% minus the margin (5 items per row)
            margin-right: 4px; // Space between thumbnails

            &:nth-child(5n) {
                margin-right: 0; // Remove right margin from the last item in each row
            }
        }

        .image-gallery-thumbnails {
            margin-top: 15px;
        }

        .image-gallery-thumbnail {
            // transition: none;
            border: 1px solid lightgray;
            margin-left: 10px;
        }

        .image-gallery-thumbnail:first-child {
            margin-left: 0;
        }

        .image-gallery-thumbnail.active,
        .image-gallery-thumbnail:focus {
            outline: none;
            border: 1px solid gray;
        }

        .image-gallery-thumbnail:hover {
            border: 1px solid lightgray
        }

    }

    @media (max-width: $breakpoint_desktop) {

        .product-detail-hero-container {
            flex-direction: column-reverse;
            padding-bottom: 0px;

            .product-photos-desktop {
                display: none;
            }

            .product-photos-mobile {
                display: block;
                margin-left: -15px;
                margin-right: -15px;
            }


            .product-description {
                padding: 0;
            }

            .product-cta-button,
            .product-gift-button {
                width: 100%;
                height: calc(1.25*$h1);
            }

            .fixed-cta {
                position: fixed;
                bottom: 20px;
                z-index: 999;
                right: 0;
                left: 0;
                width: 95%;
                margin: auto;
                height: calc(1.25*$h1);
            }


            // .fixed-cta {
            //     position: fixed;
            //     bottom: 0;
            //     left: 0;
            //     right: 0;
            //     padding: 20px 15px;
            //     background-color: #F8F8F8;
            //     z-index: 10;
            //     box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
            //     border-top: 1px solid rgb(230, 230, 230);
            //     margin: 0px !important;
            // }

            .product-top-faq {

                .product-detail-questions {
                    padding: 0;
                    margin: 0;

                    .ant-collapse .ant-collapse-header-text {
                        font-size: $h4 !important;
                        line-height: $h4_line_height !important;

                    }

                }
            }

        }

    }

    @media (min-width: $breakpoint_desktop) {

        .product-detail-hero-container {

            .product-photos-mobile {
                display: none;
            }

            .product-photos-desktop {
                display: block;
            }

            .fixed-cta {
                position: fixed;
                right: 20px;
                bottom: 20px;
                z-index: 1000;
                width: 25% !important;


            }

        }


    }
}

@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
    );
}