@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .dashboard {

    margin-left: auto;
    margin-right: auto;
    width: 100%;
    background: white;
    padding: 24px;

    .secondary-link {
      color: $secondary-text;
      text-decoration: underline;
    }

    .dashboard-main {
      padding: 30px 0px 0px 0px;
      border-right: 1px solid #F0F0F0;
    }

    .page-header {
      border: 0px;
    }

    .page-header .page-header-title {
      font-size: $h3;
      line-height: $h3_line_height;
    }

    .test-catalog-container {

      padding: 0px 15px;

    }

    .section-card {
      background: linear-gradient(to right, #f8f9fa, #ffffff);

      .ant-card-head {
        padding: $h6 24px;
      }

      .ant-card-head-title {
        font-size: $h6;
        text-transform: uppercase;
      }

      .ant-card-actions {
        background: linear-gradient(to right, #f8f9fa, #ffffff);

      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 1px solid #F0F0F0;

        h3 {
          margin: 0;
          font-size: $h6;
        }
      }
    }

    .research-card {
      .research-title {
        font-size: $h4;
        line-height: $h4_line_height;
        font-weight: 600;
        color: #262626;
      }

      .research-description {
        font-size: $h5;
        line-height: $h5_line_height;
        color: #595959;
        margin-bottom: $h1;
      }

      .research-meta {
        font-size: calc($h5 * 0.8);
        color: $secondary-text;
      }
    }



    .empty-appointments {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .ant-typography {
        margin-bottom: 12px;
        color: rgba(0, 0, 0, 0.45);
      }

      .ant-empty-image {
        height: 40px;
        margin-bottom: 8px;
      }
    }

    .dot-separator {
      margin: 0 8px;
    }

    // Add some spacing between cards if they're stacked
    .ant-col {

      &:last-child {
        margin-bottom: 0;
      }
    }

    .section-card {
      .ant-empty {
        margin: 16px 0;

        .ant-empty-description {
          margin-bottom: 0;
        }

        h3 {
          margin: 0;
        }
      }
    }

    .gray-link {
      color: $secondary_text;
      text-decoration: none;

      &:hover {
        color: darken($secondary_text, 15%) !important;
      }
    }


    .balance-card {

      .balance-amount {
        transition: all 0.3s ease;
        display: block;
        margin-bottom: 8px;
        font-size: 32px;
        font-weight: 600;

        &.balance-updated {
          color: $success;
          transform: scale(1.05);
        }
      }
    }

    .balance-content {
      padding: 16px 0 0px;

      .balance-amount {
        font-size: $h3;
        font-weight: 600;
        color: #262626;
        transition: color 0.3s ease;

        &.balance-updated {
          animation: balanceUpdate 1s ease;
          color: $success;
        }
      }

    }


    .longevity-score-card {
      background: #ffffff;
      overflow: hidden;

      // Risk-based variations
      &.low-risk,
      &.optimal {
        border-left: 4px solid $success;

        .ant-statistic-content {
          color: $success;
        }
      }

      &.moderate-risk,
      &.moderate,
      &.borderline {
        border-left: 4px solid#d48806;

        .ant-statistic-content {
          color: #d48806;
        }
      }

      &.high-risk,
      &.at-risk,
      &.high {
        border-left: 4px solid #cf1322;

        .ant-statistic-content {
          color: #cf1322;
        }
      }

      .score-container {
        padding: 24px;
        cursor: pointer;
        transition: all 0.2s ease;
        text-align: center;



        .ant-statistic {
          .ant-statistic-title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            margin-bottom: 16px;
          }

          .ant-statistic-content-value {
            font-size: $h1_line_height;
            font-weight: 600;
            line-height: 1;
          }

          .ant-statistic-content-suffix {
            font-size: $h3;
            opacity: 0.7;
            font-weight: 400;
          }

        }


      }

      @media (max-width: 768px) {
        .score-container {
          padding: 16px 0px;
          text-align: left;

          .ant-statistic {
            .ant-statistic-content {
              font-size: 36px;
            }
          }
        }
      }


    }

    .stat-card {
      height: 100%;
      transition: all 0.3s ease;


      &.optimal {
        background: linear-gradient(145deg, rgba(82, 196, 26, 0.05) 0%, rgba(82, 196, 26, 0.1) 100%);

        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: $success;
        }

        &:hover {
          background: rgba(82, 196, 26, 0.15);
        }
      }

      &.borderline {
        background: linear-gradient(145deg, rgba(250, 173, 20, 0.05) 0%, rgba(250, 173, 20, 0.1) 100%);

        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: #d48806;
        }


        &:hover {
          background: rgba(250, 173, 20, 0.15);
        }
      }

      &.at-risk {
        background: linear-gradient(145deg, rgba(255, 77, 79, 0.05) 0%, rgba(255, 77, 79, 0.1) 100%);


        .ant-statistic-content-value,
        .ant-statistic-content-suffix {
          color: #cf1322;
        }

        &:hover {
          background: rgba(255, 77, 79, 0.15);
        }
      }
    }


    .membership-ad {
      width: 100%;

      .section-header {
        font-size: $h3;
        font-weight: 600;
        color: #262626;
        display: flex;
        align-items: center;
        justify-content: left;
        padding: 0;
        margin: 0;

        .anticon {
          margin-right: 8px;
          color: #F0B90B;
        }
      }

      .close-button {
        display: none;
      }

      .membership-content {
        padding: 8px 0;

        .membership-benefits {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 12px 0;
            font-size: $h5;
            border-bottom: 1px solid #f0f0f0;

            &:last-child {
              border-bottom: none;
            }

            .benefit-icon {
              color: $success;
              margin-right: 8px;
            }

            // // Checkmark styling
            // &::before {
            //   content: '✓';
            //   color: $success;
            //   font-weight: bold;
            //   margin-right: 12px;
            // }
          }
        }

        .ant-btn {
          background: linear-gradient(45deg, #ffd700, #ffeb3b);
          border: none;
          color: #000;
          font-weight: 600;
          height: 48px;
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            background: linear-gradient(45deg, #ffeb3b, #ffd700);
          }

          .anticon {
            color: #d4af37; // Darker gold for the crown icon
          }
        }
      }

      // Card header styling
      .section-header {
        .ant-typography {
          display: flex;
          align-items: center;

          .anticon {
            font-size: 24px;
          }
        }
      }
    }

    .credit-card {
      height: 100%;

      .credit-card-content {
        .credit-title {
          font-size: $h5;
          font-weight: 600;
          color: #262626;
          margin-bottom: 8px;
        }

        // Add spacing between text and button
        .ant-row {
          gap: 16px;
        }
      }

      // Responsive adjustments
      @media (max-width: 576px) {
        .credit-card-content {
          .ant-row {
            flex-direction: column;
            row-gap: 8px;

            .ant-btn {
              align-items: baseline;
              justify-content: flex-start;
              display: inline-flex;
              margin-left: 0;
            }
          }
        }
      }
    }

  }


  @media only screen and (min-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border-right: 1px solid #F0F0F0;
      min-height: 1200px;
    }
  }

  @media only screen and (max-width: $breakpoint_desktop) {
    .dashboard .dashboard-main {
      border: 0;
      padding: calc($vertical_padding/2) 0 0 0;
      // margin-top: -25px;
    }


  }

  @media only screen and (max-width: $breakpoint_mobile) {

    // .dashboard {
    //   padding-top: $h1;
    // }

    .dashboard .dashboard-alert {
      padding: 10px 15px;
    }
  }
}




@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}

@keyframes balanceUpdate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}