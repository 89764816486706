@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h4,
  $h5,
) {
  .athlete {

    .athlete-section {
      margin: $vertical_padding auto;
      padding: 0 $horizontal_padding;
      max-width: $max_width;
    }

    .content-container {
      align-items: center;
      display: flex;
      height: 100%;
      padding: calc($vertical_padding/2) 0;
    }

    .section-header {
      font-size: $h5;
      font-weight: 800;
      color: $success;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .section-title {
      font-family: 'EB Garamond';
      font-weight: 700;
      font-size: $h2;
      line-height: $h2_line_height;
      margin-bottom: 20px;
    }

    .section-description {
      font-size: $h4;

      a {
        color: $success;
      }
    }

    .section-img {
      width: 100%;
    }

    .point-list {
      margin-top: 30px;
      margin-bottom: 40px;
    }

    .point-item {
      font-size: $h4;
      margin-bottom: 15px;

      a {
        color: $success;
      }

      .anticon {
        color: $success;
        margin-right: 10px;
      }
    }

    .section-btn {
      height: 50px;
      padding: 0 40px;
      font-size: 18px;
      font-weight: 600;
    }

    .faq {
      background: $secondary_bg;
      padding-bottom: $vertical_padding
    }

    .faq-header {
      margin: 0 auto;
      padding: $vertical_padding $horizontal_padding 0 $horizontal_padding;
      max-width: $max_width;
    }

    .athlete-footer {
      position: relative;
      padding: $vertical_padding 0 calc($vertical_padding*2) 0;
    }

    .footer-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
    }

    .footer-content {
      z-index: 1;
      position: relative;
      margin: 0 auto;
      padding: 0 $horizontal_padding;
      max-width: $max_width;

      .section-title,
      .section-description {
        color: white;
      }
    }

    .schedule-btn {
      height: 50px;
      padding: 0 40px;
      font-size: 18px;
      font-weight: 600;
      margin-top: 30px;

      &:hover,
      &:focus {
        color: $success !important;
        border-color: $success !important;
      }

      &:active {
        color: darken($success, 5) !important;
        border-color: darken($success, 5) !important;
      }
    }

    .athlete-footer-links {
      z-index: 1;
      position: absolute;
      width: 100%;
      bottom: 20px;
      left: 0;
      text-align: center;
    }

    .athlete-footer-links span {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      padding-right: calc($horizontal_padding/2);
      margin-right: calc($horizontal_padding/2);
      margin-top: 10px;
      display: inline-block;
      color: white;

      a {
        color: white;
      }
    }

    .athlete-footer-links span:last-child {
      border-right: 0;
      padding-right: 0;
      margin-right: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h4: $mobile_h4,
    $h5: $mobile_h5,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h4: $desktop_h4,
    $h5: $desktop_h5,
  );
}