.membership-ad {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
    // padding: 16px;
    margin: 16px 16px 16px 0;

    .section-header {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
    }


    .membership-benefits {
        list-style: none;
        padding: 0;
        margin: 0 0 16px 0;
    }

    .membership-benefits li {
        margin: 8px 0;
        font-size: 12px;
        color: #666;
    }

    .benefit-icon {
        font-size: 10px;
        // color: #52c41a;
        margin-right: 4px;
    }

    .close-button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: none;
        border: none;
        font-size: 16px;
        color: lighten(#000, 50%);

        cursor: pointer;
        /* Adjust color as needed */
    }

    .close-button:hover {
        color: #000;
        /* Optional: Change color on hover */
    }
}

@media (max-width: 768px) {
    .membership-ad {
        margin-bottom: 300px;
    }
}