@import '../../../../colors.scss';
@import '../../../../global.scss';
@import '../../../../marketing.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height) {
  .pay {
    .benefit-item {
      margin: 5px 0;
    }

    .benefit-col-1 {
      width: 25px;
    }

    .benefit-col-2 {
      width: calc(100% - 25px);
    }

    .benefit-item .anticon {
      color: $success;
      margin-right: 10px;
      font-size: 14px;
    }

    .benefit-item-text {
      font-size: 14px;
    }

    .benefit-item-text a {
      color: $success;
      text-decoration: underline;
    }

    // .pay-item {
    //   margin-top: 40px;
    // }

    .ant-form-item-control-input-content {
      background: $secondary_bg;
      border-radius: 5px;
    }

    .StripeElement {
      // padding: 16px 15px;
      // border: 1px solid $border;
      // border-radius: 5px;

      a {
        cursor: pointer !important;
      }



    }

    .StripeElement--focus:not(.StripeElement--invalid) {
      border: 1px solid $success !important;
      background: $green_bg;
    }

    .StripeElement--invalid {
      border: 1px solid $error !important;
      background: $error_bg;
    }

    .paid-status {
      background: $green_bg;
      padding: 15px;
      border-radius: 5px;
      margin-top: 25px;
      color: $success;
      font-size: 16px;
      font-weight: 600;

      .anticon {
        margin-right: 5px;
        opacity: 0.6;
      }
    }

    // .saved-card-details {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   padding: 25px 20px;
    //   position: relative;
    //   background: $secondary_bg;
    //   border-radius: 10px;
    //   margin-top: 25px;

    //   .saved-card-logo {
    //     padding-right: 15px;

    //     svg {
    //       width: 35px;
    //     }
    //   }

    //   .saved-card-content {
    //     flex: 1;
    //     padding-top: 5px;
    //   }

    //   .saved-card-label {
    //     display: block;
    //     color: $secondary_text;
    //     margin-bottom: 2px;
    //     margin-top: -7px;
    //     font-size: 13px;
    //   }

    //   .saved-card-description {
    //     color: $primary_text;
    //     font-weight: 700;
    //     font-size: 13px;
    //     margin-bottom: 0;
    //   }

    //   .remove-btn {
    //     background: white;
    //     width: 30px;
    //     height: 30px;
    //     border: 1px solid $border;
    //     padding: 0;

    //     .anticon {
    //       font-size: 11px;
    //     }

    //     &:hover {
    //       border: 1px solid $error;
    //       color: $error;
    //     }

    //     &:active,
    //     &:focus {
    //       border: 1px solid darken($error, 10);
    //       color: darken($error, 10);
    //     }
    //   }
    // }

    .instruction-list {
      background: $secondary_bg;
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .instruction-item {
      padding: 12px 0 5px 0;
    }

    .instruction-link {
      color: $success;
      text-decoration: underline;

      &:hover {
        color: lighten($success, 15);
        text-decoration: underline;
      }

      &:active {
        color: darken($success, 10);
        text-decoration: underline;
      }
    }

    .instruction-index {
      color: $success;
      background: $green_bg;
      font-size: 13px;
      padding: 5px 0;
      width: 60px;
      display: block;
      text-align: center;
      border-radius: 3px;
      margin-top: 5px;
    }

    .ant-col .section-item:not(:first-child) {
      margin-top: 20px;
    }

    .section-title {
      font-weight: 600;
      font-size: 15px;
    }

    .section-description {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .default-checkbox {
      //background: $secondary_bg;
      background: #fcf5e7;

    }

    .active-checkbox {
      background: $green_bg;
    }

    .add-membership {
      position: relative;
    }

    .add-membership-checkbox {
      width: 100%;
      padding: 25px 25px 40px 25px;
      border-radius: 5px;

      .ant-checkbox {
        align-self: start;
        margin-right: 10px;
        margin-top: 6px;
      }

      .add-membership-title {
        font-weight: 600;
        font-size: $h5;
        line-height: $h5_line_height;
        margin-bottom: 0;
      }

      .add-membership-title span {
        color: $success;
      }

      .add-membership-description {
        font-size: 14px;
        color: $secondary_text;
        margin-top: 5px;
      }

      &:hover .ant-checkbox-inner {
        border-color: $success !important;
      }

      .ant-checkbox-checked:after {
        border-color: $success !important;
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background: $success !important;
        border-color: $success !important;
      }
    }

    .view-membership-details {
      position: absolute;
      bottom: 20px;
      left: 60px;
      text-decoration: none !important;
      z-index: 2;
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}