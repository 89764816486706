@import '../../colors.scss';
@import '../../global.scss';

.privacy {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 10px;

  .privacy-header {
    color: $primary_text;
    font-size: 38px;
    font-weight: 700;
    display: block;
    font-family: 'EB Garamond';
  }

  .privacy-subheader {
    font-size: 14px;
    color: $secondary_text;
    display: block;
    margin-top: 3px;
    margin-bottom: 50px;
  }

  .privacy-title { 
    color: $primary_text;
    font-weight: 700;
    font-size: 14px;
    line-height: 27px;
    display: block;
    margin-bottom: 5px;
    text-decoration: underline;
  }

  .privacy-paragraph {
    color: $primary_text;
    font-size: 14px;
    line-height: 27px;
  }

  .privacy-section-title {
    color: $primary_text;
    font-weight: 700;
    font-size: 14px;
    line-height: 27px;
    display: block;
    margin-bottom: 5px;
  }
  
  ol,
  ul {
    padding-left: 25px;
  }

  li {
    color: $primary_text;
    font-size: 14px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  a {
    color: $success;

    &:hover {
      color: lighten($success,15);
    }

    &:active {
      color: darken($success,15);
    }
  }
}