@import '../../colors.scss';
@import '../../global.scss';
@import '../../mixins.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {

    .dexa-recommender-container,
    .mobile-results-drawer {

        .desktop-only {
            display: block;
        }

        .mobile-only {
            display: none;
        }



        .results-section,
        .recommendations-section,
        .overview-section {
            margin-bottom: 2rem;

            .section-title {
                font-size: 1.25rem;
                font-weight: 600;
                color: #2c3e50;
                padding-bottom: 0.75rem;

                margin-bottom: 20px;
                font-weight: 600;

                &:after {
                    content: '';
                    display: block;
                    width: 40px;
                    height: 3px;
                    background: $success;
                    margin-top: 8px;
                }
            }
        }

        .results-section {
            margin-bottom: 3rem;
        }

        .recommendations-section,
        .overview-section {
            .section-title {
                margin-bottom: 0;
            }
        }


        .recommendation-card {
            border: none;
            box-shadow: none;

            .ant-card-body {
                padding: 0;
            }

            .recommendation-content {
                .recommendation-section {
                    margin-bottom: 24px;
                    padding-bottom: 24px;
                    border-bottom: 1px solid #edf2f7;

                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        border-bottom: none;
                    }
                }

                .recommendation-heading {
                    color: #2c3e50;
                    font-size: 1.2rem;
                    margin-bottom: 16px;
                    font-weight: 600;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 12px;
                        line-height: 1.5;
                        color: #4a5568;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 8px;
                            width: 8px;
                            height: 8px;
                            background: $success;
                            border-radius: 50%;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #edf2f7;
                    padding-bottom: 24px;
                }
            }

            transition: transform 0.2s ease;

            &:hover {
                transform: translateY(-2px);
            }


        }

        .metrics-grid {
            display: flex;
            gap: 24px; // spacing between cards
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 0.5rem;
        }

        .metric-card {
            flex: 1;
            background: white;
            border-radius: 8px;
            padding: 16px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

            &.needs-attention {
                border-left: 4px solid $error;
            }

            &.borderline {
                border-left: 4px solid $warning;
            }

            &.optimal {
                border-left: 4px solid $success;
            }

            .metric-header {
                margin-bottom: 12px;
            }

            .metric-title {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 4px;
                color: #2c3e50;
            }

            .metric-details {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }

            .metric-subtitle {
                font-size: 14px;
                color: #64748b;

                display: flex;
                align-items: center;
                gap: 8px;

                a {
                    color: #666;
                    border-bottom: 1px dotted #666;
                    text-decoration: none;

                    &:hover {
                        color: #444;
                        border-bottom-style: solid;
                    }
                }

                .metric-info-icon {
                    color: #8c8c8c;
                    font-size: 16px;
                    cursor: pointer;
                    transition: color 0.2s ease;

                    &:hover {
                        color: #1890ff;
                    }
                }

                a {
                    color: #666;
                    border-bottom: 1px dotted #666;
                    text-decoration: none;
                    display: inline-flex;
                    align-items: center;
                    gap: 4px;

                    .link-icon {
                        font-size: 10px;
                        opacity: 0.7;
                        border: 0px !important;
                    }

                    &:hover {
                        color: #444;
                        border-bottom-style: solid;

                        .link-icon {
                            opacity: 1;
                        }
                    }
                }
            }

            .metric-value-container {
                display: flex;
                align-items: baseline;
                gap: 4px;

                .value {
                    font-size: 24px;
                    font-weight: 600;
                    color: #1a1a1a;
                }

                .unit {
                    font-size: 14px;
                    color: #64748b;
                }
            }

            .metric-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 8px;
            }



            .percentile-wrapper {
                text-align: right;
                margin-left: 16px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .percentile-value {
                    font-weight: 500;
                    font-size: 14px;
                }

                .percentile-label {
                    font-size: 12px;
                    color: #64748b;
                }
            }

            .status-wrapper {
                flex: 1;

                display: flex;
                align-items: center;
                gap: 8px;

                .status-indicator {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }

                .status-text {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            &.needs-attention {
                .status-indicator {
                    background-color: #ef4444;
                }

                .status-text {
                    color: #ef4444;
                }
            }

            &.borderline {
                .status-indicator {
                    background-color: #f59e0b;
                }

                .status-text {
                    color: #f59e0b;
                }
            }

            &.optimal {
                .status-indicator {
                    background-color: $success;
                }

                .status-text {
                    color: $success;
                }
            }
        }


        .result-card {
            border-radius: 8px;
            padding: 16px;

            &.needs-attention {
                background-color: #ffd6d6;
            }

            &.borderline {
                background-color: #fff3d6;
            }

            &.optimal {
                background-color: #d6ffd6;
            }

            .value {
                font-size: 24px;
                font-weight: bold;
            }

            .level {
                margin-top: 8px;
                font-weight: 500;
            }

            .percentile {
                margin-top: 4px;
                font-size: 0.9em;
                color: #666;
            }
        }

        .recommendations-content {
            margin-top: 24px;

            h4 {
                margin: 16px 0 8px;
            }

            .description {
                font-weight: 500;
                margin-bottom: 16px;
            }
        }

        .intro-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }


        .empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 200px;
            color: #718096;
            text-align: center;
            background: #f8fafc;
            border-radius: 5px;
            padding: 2rem;


            p {
                max-width: 400px;
                margin: 1rem auto;
                font-size: $h5;
            }

            .arrow-up {
                display: none;
                font-size: $h2;
                text-align: center;
                margin-top: 10px;
                animation: bounce-up 1s infinite;
            }

            .arrow-left {
                display: block;
                font-size: $h2;
                line-height: 1;
                color: #666;
                margin: 0px auto;
                animation: bounce-left 1s infinite;

            }

            @media (max-width: 768px) {
                .arrow-left {
                    display: none;
                }

                .arrow-up {
                    display: block;
                }

                padding: 0rem 2rem 2rem;
            }



        }


        .panels-container {

            max-width: 1200px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            margin: 2rem auto;
            padding: 0 2rem;
            min-height: 600px;



            @media (max-width: 768px) {
                margin: 0rem auto;
            }
        }

        .results-wrapper {
            order: 1; //
        }

        .questions-panel,
        .results-panel,
        .upsell-panel {
            background: white;
            border-radius: 5px;
            padding: 2.5rem;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);

            h2 {
                color: #2c3e50;
                margin-bottom: 2rem;
                font-size: $h3;

                .results-count {

                    &.ant-badge .ant-badge-count {
                        font-weight: 600;
                        background-color: $success;
                    }

                    &.animateBadge {
                        animation: pulsateBadge 1.5s ease-in-out infinite;
                    }
                }
            }
        }

        .questions-panel {
            background-color: #f8fafc;

            h2 {
                margin-bottom: 0;
            }

            .description {
                margin-bottom: 2rem;
            }

            .input-with-unit {
                display: flex;
                gap: 8px;
                align-items: center;


                .unit-select {
                    width: auto;
                    padding: 0.75em;
                    border: 1px solid #e2e8f0;
                    border-radius: 5px;
                    background-color: #fff;
                    font-size: 1rem;
                    color: #2c3e50;


                    &:focus {
                        outline: none;
                        border-color: #40a9ff;
                        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                    }
                }
            }
        }

        .upsell-panel {
            margin-top: 24px;
            padding: 20px;

            .upsell-content {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 16px;
            }

            .card-title {
                display: flex;
                align-items: center;
                // justify-content: space-between;
                gap: 12px;
                width: 100%;
                margin-bottom: 0;

                .title-text {

                    h4 {
                        margin: 0;
                        font-size: $h4;
                        font-weight: 600;
                    }

                    min-width: 150px;
                }


            }

            .schedule-btn {


                .anticon {
                    font-size: 14px;
                    transition: transform 0.2s ease;
                }

                &:hover .anticon {
                    transform: translateX(4px);
                }
            }
        }

        // Responsive adjustments
        @media (max-width: 768px) {
            .upsell-panel {
                .upsell-content {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                }

                .schedule-btn {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        .loading-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 200px;
        }


        .form-group {
            margin-bottom: 24px;
            padding: 20px;
            background-color: #fff;
            border: 1px solid #e4e9f0;
            border-radius: 5px;
            transition: box-shadow 0.2s ease;

            .ant-form-item {
                margin-bottom: 0;
            }

            .ant-form-item-explain,
            .ant-form-item-extra {
                margin-top: 6px;
                font-size: calc($h6 * 0.9);
                line-height: calc($h6_line_height * 0.9);
                margin-left: 2px;
            }

            &:hover {
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
            }

            .ant-input-group-wrapper,
            .ant-input-number-group-wrapper {
                border: none;
                padding: 0;
                // width: auto;

                .ant-input,
                .ant-input-number {
                    padding: 0.75rem;
                    border: 1px solid #e2e8f0;
                    font-size: 1rem;
                    transition: border-color 0.2s ease;
                }

                .ant-input-number-input {
                    padding: 0 !important;
                    font-size: $h5;
                }

                .vat-unit-select {
                    font-size: 1rem;
                    color: #2c3e50;

                    .ant-select-selector {
                        border: 0 !important;
                        min-width: 75px !important;
                        background-color: transparent !important;

                        .ant-select-selection-item {}
                    }
                }

            }

            .ant-input-number-input-wrap {
                .ant-input-number-input {
                    padding: 0 !important;
                    font-size: $h5 !important;
                }
            }
        }

        .form-group label {
            font-weight: 600;
            display: block;
            margin-bottom: 9px;
            font-size: $h5;
        }

        .form-input {
            padding: 0.75rem;
            border: 1px solid #e2e8f0;
            border-radius: 5px;
            font-size: $h5;
            transition: border-color 0.2s ease;
            width: auto;

            &.invalid {
                border-color: $error;
                background-color: #fff2f0;

                &:focus {
                    border-color: #ff7875;
                    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
                    outline: none;
                }
            }
        }

        .form-input:focus {
            border-color: #73b8ff;
            outline: none;
        }


        .radio-cards-group,
        .checkbox-cards-group {
            display: grid;
            gap: 8px;
            margin-top: 8px;

            @media (min-width: 768px) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }

            label {
                display: flex;
                font-size: $h6;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .radio-card,
        .checkbox-card {
            padding: 12px 16px;
            border: 1px solid #e2e8f0;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.2s;
            display: flex;
            align-items: center;

            &:hover {
                border-color: #73b8ff;

                .ant-checkbox-inner,
                .ant-radio-inner {
                    border-color: #1890ff;
                }

            }

            &.selected {
                border-color: #73b8ff;
                background-color: rgba(24, 144, 255, 0.1);
            }

            input {
                // accent-color: $success;
                margin-right: 5px;
            }



        }

        .description {
            font-size: 0.9rem;
            color: #666;
            margin-top: 5px;
        }

        .results-panel {
            max-height: calc(100vh - 48px);
            display: flex;
            flex-direction: column;
            position: relative;

            .results-subtitle {
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 16px;
                font-size: 14px;
            }
        }

        .results-scroll-container {
            flex: 1;
            overflow-y: auto;
            padding-right: 8px;

            &.has-overflow {
                mask-image: linear-gradient(to bottom, black calc(100% - 40px), transparent 100%);
                -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 40px), transparent 100%);
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 4px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }

            &:not(:hover)::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
            }

            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        }


    }



    // Responsive layout for mobile
    @media (max-width: 768px) {
        .metrics-grid {
            grid-template-columns: 1fr;
            gap: 12px;

            &.metrics-grid-2 {
                grid-template-columns: 1fr;
            }
        }
    }


}



@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

    .dexa-recommender-container,
    .mobile-results-drawer {

        .desktop-only {
            display: none;
        }

        .mobile-only {
            display: block;
        }

        .results-panel {
            padding: 0;
            box-shadow: none;
        }

    }

    .mobile-results-drawer {
        .test-card {
            .test-card-content {
                gap: 8px !important;
            }
        }
    }

    .dexa-recommender-container {
        background: #f8fafc;
        padding-bottom: 60px;


        .panels-container {
            grid-template-columns: 1fr;
            padding: 0 1rem;


        }

        .questions-panel {
            order: -1; // Questions first on mobile
            padding: 1rem;

            h2 {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .results-wrapper {
            order: 1; // Results second on mobile

        }


        .questions-panel {
            grid-template-columns: 1fr;
            padding-top: 1rem;
            background: none;
            box-shadow: none;
        }
    }


}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );

    .desktop-only {
        display: block;
    }

    .mobile-only {
        display: none;
    }
}