@import '../../colors.scss';

.prescription-modal {
  table {
    background: $secondary_bg;
    border-radius: 5px;
    margin-top: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-table-cell {
    border: 0 !important;
    padding: 5px 10px !important;
    vertical-align: top;
  }

  tr td:first-child {
    font-weight: 600;
    width: 220px;
  }

  .gov-id-img {
    width: 100%;
  }
}