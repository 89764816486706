@import '../../../colors.scss';

.metabolic-advice {

  .test-item-list,
  .test-chart,
  .advice-section {
    margin: 30px 0px;
  }

  .video-container {
    display: block;
    padding-top: 80%;
    position: relative;
    width: 100%;
    height: auto;
    max-width: 200px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
  }

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .play-icon-container:active {
    background: rgba(0, 0, 0, 0.3);

    .play-icon {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  .play-icon {
    font-size: 50px;
    color: white;
  }

  .secondary-tests-description {
    margin-top: 30px;
  }
}