@import '../../colors.scss';

.accept-invite {
    text-align: center;
    max-width: 320px;
    margin: 0 auto;

    .accept-invite-header {
        font-size: 30px;
        font-weight: 600;
        margin-top: 60px;
        margin-bottom: 10px;

        span {
            color: $success;
        }
    }

    .accept-invite-subtitle {
        font-size: 14px;
        color: $secondary_text;

        a {
            color: $success;
        }
    }

    form {
        margin: 0 auto;
        margin-top: 40px;
    }

    form button {
        width: 100%;
        font-weight: 600;
        height: 40px;
        margin-top: 10px;
    }


    .ant-input-status-error {
        background: $error_bg !important;
    }

    .ant-form-item-explain-error {
        width: 100%;
        float: left;
        background: $error_bg;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
        margin-top: 10px;
    }
}