@import '../../colors.scss';

.loading {
  text-align: center;
  padding: 100px 0;

  .loading-text {
    color: $secondary_text;
  }
}

.results-table {
  margin-top: -1px;

  .collected-at {
    position: relative;
  }

  .percent-complete-container {
    position: absolute;
    top: -35px;
    left: -62%; //-50px;
    width: calc(100% + 80px);
    text-align: center;
  }

  .percent-complete-position {
    position: relative;
  }

  .percent-complete {
    padding: 3px 7px;
    border-radius: 3px;
    color: $secondary_text;
    border: 1px solid darken($secondary_bg, 5);
    font-size: 10px;
    background: rgba(255, 255, 255, 0.6);
  }

  .percent-complete-icon {
    margin-right: 5px;
  }

  .all-complete-icon {
    color: $success;
  }

  .centered-col {
    text-align: center;
  }

  .current-risk-col {
    text-align: right;
    padding-right: 1px !important;
  }

  .absolute-category-row,
  .score-header-row {
    margin-bottom: 20px !important;
  }

  .absolute-category-row .ant-table-cell,
  .score-header-row .ant-table-cell {
    color: $secondary_text;
    background: $secondary_bg !important;
    padding-top: 50px !important;
    font-weight: 500;
  }

  .test-row,
  .score-value-row {
    cursor: pointer;
  }

  .aggregate-apoe {
    position: absolute;
    top: 13px;
    left: 15px;
    text-align: center;
    z-index: 1;
  }

  .risk-tag {
    padding: 0 5px;
    min-width: 60px;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
  }

  .optimal-risk {
    color: $success;
    background: $success_bg;
    box-shadow: 0 0 1px $success;
  }

  .abnormal-risk,
  .high-risk {
    color: $error;
    background: $error_bg;
    box-shadow: 0 0 1px $error;
  }

  .moderate-risk {
    color: $warning;
    background: $warning_bg;
    box-shadow: 0 0 1px $warning;
  }

  .unknown-risk {
    color: $secondary_text;
    background: rgba(178, 176, 176, 0.05);
    box-shadow: 0 0 1px $secondary_text;
  }

  .pending-risk {
    color: $secondary_text;
    background: rgba(255, 255, 255, 0.05);
  }

  .outdated-risk {
    color: $secondary_text;
    background: rgba(255, 255, 255, 0.05);
  }



}