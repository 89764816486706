@import '../../colors.scss';

.payment-link {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  padding: 0 10px;

  .payment-link-title {
    font-size: 24px;
    font-weight: 600;
    color: $primary_text;
    margin-bottom: 0;
  }

  .payment-link-description {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 30px;
    color: $secondary_text;
  }

  .message-link {
    color: $success;

    &:hover {
      color: $success;
    }

    &:active {
      color: darken($success, 10);
    }
  }

  table {
    border: 1px solid $border;
    border-radius: 5px;
    overflow: hidden;
  }

  th.ant-table-cell,
  .total-row .ant-table-cell {
    background: $secondary_bg !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    border-top: 0 !important;
  }

  .true-price {
    color: $success;
  }

  .original-price {
    color: #ccc;
    text-decoration: line-through;
    margin-left: 10px;
  }

  .total-label {
    font-weight: 600;
  }

  .thank-you-message {
    text-align: center;

    .check-icon {
      font-size: 25px;
      display: block;
      float: none;
      margin: 0;
    }
  }

  .product-table {
    margin-bottom: 20px;
  }
}