@import '../../colors.scss';
@import '../../global.scss';

.admin-gifts {
  // max-width: $width;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  a {
    color: $success;
  }

  .success {
    color: $success;
  }

  .error {
    color: $error;
  }
}