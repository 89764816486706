@import '../../colors.scss';

.tasso-modal {
  .tasso-title {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0;
  }

  .tasso-description {
    font-size: 14px;
    text-align: center;
  }

  .tasso-video {
    background: black;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 5px;
  }
}