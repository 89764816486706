@import '../../colors.scss';
@import '../../global.scss';

.admin-invoices {
  padding: 0 10px;
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .invoice-total {
    color: $success;
    font-size: 12px;
  }
  
  .deleted-text {
    color: $secondary_text;
    font-size: 12px;
  }
}