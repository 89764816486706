@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
) {
  .overview {
    padding: 0 $horizontal_padding;
    max-width: $max_width;
    margin: 0 auto;


    .overview-title {
      font-size: $h2;
      line-height: $h2_line_height;
      font-weight: 600;
      margin-bottom: 0;
      // font-family: 'Courier New';
      // font-family: 'Cooper LT BT';
      // letter-spacing: 1px;
    }

    .highlight {
      color: $success;
    }

    .overview-title-base {
      width: 100px;
      height: calc($vertical_padding/10);
      background: $success;
      margin-top: 1rem;
      margin-bottom: calc($vertical_padding/2);
    }

    .overview-description {
      font-size: $h3;
      margin-bottom: 0;
    }

    .overview-description a {
      color: $success;
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
  );


  .overview .overview-title {
    font-size: $mobile_h2;
    line-height: $mobile_h2_line_height;
  }

  .overview .overview-description {
    font-size: calc(0.9*$mobile_h3);
    line-height: calc(0.9*$mobile_h3_line_height);

  }
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
  );
}