@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .product-details-biomarker-panel {
    .category-item {
      padding: 15px 0px;
      border-top: 1px solid $border;
    }

    .category-title {
      font-size: $h4;
      line-height: $h4_line_height;
      vertical-align: middle;
      font-weight: 600;
      margin: 0;

    }

    .category-test {
      font-size: $h4;
      line-height: $h4_line_height;
      margin-right: 5px;
      cursor: pointer;
      color: $secondary_text;

    }
  }
}

@media (max-width: $breakpoint_desktop) {
  .product-details-biomarker-panel {

    .category-title {
      font-size: 16px;
    }

    .category-test {
      font-size: 14px;
    }
  }

}


@media screen and (max-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}