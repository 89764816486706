@import '../../../../colors.scss';

$width: 750px;

.recommended-blogs-header {
  margin: 0 auto;
  max-width: 100%;

  .ant-divider-inner-text {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.6;
    color: $primary_text;
  }
}

.recommended-blogs {
  max-width: $width;
  margin: 40px auto 0 auto;
  width: 100%;
  padding: 0 10px;
  .recommended-blog {
    margin-bottom: 50px;
  }

  .recommended-blog-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    border: 1px solid $border;
  }

  .recommended-blog-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 30px;
    margin-bottom: 10px;
    color: $primary_text;
    max-width: 100%;
  }

  .recommended-blog-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 0 0 25px 0;
    color: $primary_text;
    max-width: 100%;
  }
}

