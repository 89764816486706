@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .lock-container,
  .approve-container {
    padding: 10px;
  }

  .approve-container {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .approve-btn,
  .lock-btn,
  .update-btn {
    width: 100%;
    height: 40px;
    font-size: 14px;
  }

  .lock-btn {
    &:hover {
      color: $success !important;
      border: 1px solid $success !important;
    }

    &:active {
      color: darken($success, 10) !important;
      border: 1px solid darken($success, 10) !important;
    }
  }

  .update-btn,
  .lock-btn {
    margin-bottom: 15px;
  }

  .unlock-btn,
  .unapprove-btn {
    height: 25px;
    margin-left: 10px;
    padding: 0;

    .anticon {
      font-size: 13px !important;
    }

    &:hover {
      color: $error !important;
      border: 1px solid $error !important;
    }

    &:active {
      color: darken($error, 15) !important;
      border: 1px solid darken($error, 15) !important;
    }
  }

  .locked-status,
  .approved-status {
    color: $secondary_text;
    font-size: 13px;
  }

  .check-icon {
    color: $success;
    margin-top: 7px;
    margin-right: 5px;
    float: left;
  }

  .table-of-contents {
    margin-left: -1px;
    width: 100%;
    height: 100%;
    background: white;
    border-right: 1px solid $border;

    .toc-title {
      font-weight: 600;
      padding: 10px 10px;
      margin-bottom: 10px;
      display: block;
      border-bottom: 1px solid $border;
      font-size: $h5;
      line-height: $h5_line_height;
    }

    .ant-anchor {
      background: white;
      height: 100%;
      font-size: $h5;
      line-height: $h5_line_height;
    }

    .ant-affix,
    .ant-anchor-wrapper {
      height: 100% !important;
    }

    .ant-anchor-link {
      padding: 0 !important;
    }

    .ant-anchor-link a {
      padding: 4px 10px;
    }

    .ant-anchor-link:hover {
      background: $secondary_bg;
    }

    .ant-anchor-link-active>.ant-anchor-link-title {
      color: $success !important;
    }

    .ant-anchor-ink-visible,
    .ant-anchor-ink-ball-visible {
      background: $success !important;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}