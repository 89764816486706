@import '../../colors.scss';

.latest-posts {
  .posts-header {
    border-top: 1px solid $border;
    text-align: center;
    margin-top: 100px;
  }

  .posts-header-text {
    font-size: 19px;
    font-weight: 600;
    background: white;
    margin-top: -17px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    width: 200px;
  }

  .post-item {
    cursor: pointer;
    position: relative;
    margin-top: 40px;
  }

  .post-image-container {
    position: relative;
    height: 200px;
  }

  .post-image {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    object-fit: cover;
    overflow: hidden;
    z-index: 1;
    position: relative;
  }

  .image-skeleton .ant-skeleton-avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .post-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .post-content {
    font-size: 14px;
    color: $secondary_text;
  }
}