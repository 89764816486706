@import '../../colors.scss';

.prescription-form {
  .prescription-contents {
    padding: 20px 20px 1px 20px;
    margin-bottom: 20px;
    background: $secondary_bg;
    border-radius: 10px;
  }

  button {
    width: 100%;

    .continue-button-text {
      border-right: 0px;
      padding-right: 0;
      margin-right: 0;
    }
  }
}