@import '../../global.scss';
@import '../../colors.scss';


.admin-events {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    .page-header {
        padding: 10px 15px;
    }

    .appointment-status {
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 700;
    }

    .pending-status {
        color: $secondary_text;
        background: $secondary_bg;
        font-size: 13px;
    }

    .collected-status {
        background: $warning_bg;
        color: $warning;
    }

    .complete-status {
        background: $success_bg;
        color: $success;
    }

    .canceled-status,
    .deactivated-status {
        color: $error;
        background: $error_bg;
    }

    .confirmed-status,
    .scheduled-status {
        color: $primary_text;
        background: $secondary_bg;
    }
}