.annotation-list-button {
  height: 34px;
}

.annotation-list-popover {
  max-width: 500px !important;
  width: 100% !important;

  .ant-popover-inner-content {
    padding: 0;
  }

  .annotation-list {
    max-height: 500px;
    overflow-y: auto;
    width: 100%;
    
    .ant-list-item {
      padding: 12px 16px;
      
      &:hover {
        background-color: #fafafa;
      }

      .ant-list-item-meta {
        margin-right: 16px;
      }

      .ant-list-item-meta-title {
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 600;
      }

      .ant-list-item-meta-description {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.65);
        white-space: pre-wrap;
      }
    }
  }
} 