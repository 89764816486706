@import '../../../colors.scss';

.note-section {
  background: $secondary_bg;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 50px 40px;

  .note-title {
    color: $primary_text;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .note-description {
    color: $secondary_text;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }

  .note-content {
    color: $secondary_text;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0;
  }

  .note-list {
    padding-left: 17px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  a {
    color: $success;

    &:hover {
      color: $success;
    }

    &:active {
      color: darken($success, 15);
    }
  }

  .note-list li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .note-item-title {
    color: $primary_text;
    font-size: 16px;
    line-height: 30px;
    font-weight: 700;
    padding-left: 5px;
    margin-bottom: 10px;
  }

  .note-item-description {
    color: $secondary_text;
    font-size: 16px;
    line-height: 30px;
    padding-left: 5px;
  }

  .note-buy-btn {
    padding: 0px 30px;
    height: 44px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 5px;
    display: block;
  }
}