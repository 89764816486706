@import "../../colors.scss";

.login-modal {
    .ant-modal-content {
        border-radius: 12px;
        overflow: hidden;
    }

    .ant-modal-body {
        padding: 0;
    }

    .login-page {
        min-height: unset;
        background: transparent;
        z-index: 0;
        margin-top: unset;

        &.modal-login {

            .login-form {
                box-shadow: none;
                padding: 0;
                margin: 0;
                max-width: none;
            }
        }
    }
}