.payment-methods-modal {
    .payment-methods-container {
        padding: 0px;

        .saved-card {
            transition: transform 0.2s ease-in-out;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                cursor: pointer;
            }
        }
    }
}