@import '../../global.scss';;
@import '../../colors.scss';;

.reset-password {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px 40px 15px;
  width: 100%;

  .reset-password-form {
    max-width: 420px;
    margin: 50px auto;
  }

  .ant-form-item-control {
    margin-bottom: 10px;
  }

  button {
    width: 100%;
    font-weight: 700;
    margin-top: 10px;
    height: 44px;
  }
}