@import '../../colors.scss';

.schedule-menu {


    .menu-credit-count {
        background-color: $success;
        color: white;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        font-size: 12px;
        font-weight: 600;
        margin-left: 3px;
    }

    .menu-item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu-item-title {
        font-weight: 500;
        margin-right: auto;
        /* This ensures the title stays on the left */
    }

}