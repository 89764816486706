@import '../../colors.scss';
@import '../../global.scss';

.consults {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .credit-count {
    background: rgba(255, 255, 255, 0.9);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    color: $success;
    font-size: 12px;
    font-weight: 600;
    margin-left: 3px;
  }

  .start-time-col {
    padding-left: 0px !important;
  }

  .consult-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
  }

  .pending-status {
    color: $secondary_text;
    background: $secondary_bg;
    font-size: 13px;
  }

  .collected-status {
    background: $warning_bg;
    color: $warning;
  }

  .complete-status {
    background: $success_bg;
    color: $success;
  }

  .canceled-status,
  .deactivated-status {
    color: $error;
    background: $error_bg;
  }

  .confirmed-status {
    color: $primary_text;
    background: $secondary_bg;
  }

  .consults-container {
    .desktop-only {
      display: block;
    }

    .mobile-only {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .consults-container {
      .desktop-only {
        display: none;
      }

      .mobile-only {
        display: block;
        padding: 16px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    .consults-container {
      .desktop-only {
        display: block;
      }

      .mobile-only {
        display: none;
      }
    }
  }

  .consult-card {
    margin-bottom: 16px;

    .ant-card-body {
      padding: 16px;
    }

    .consult-card-content {
      .consult-card-row {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.provider {
          font-weight: 600;
          font-size: 16px;
        }

        &.time,
        &.meeting {

          color: rgba(0, 0, 0, 0.65);

          .anticon {
            margin-right: 8px;
          }
        }
      }
    }
  }
}