@import '../../colors.scss';

.heart-health-intake-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .ant-descriptions-view {
    border-radius: 0;
    border: 0 !important;
  }

  .ant-descriptions-item-content.files-item {
    padding: 0 !important;

    .ant-table-cell {
      border: 0 !important;
      padding: 5px 20px !important;
    }
  }

  .file-table {
    margin: 10px 0;
  }

  .file-table tr {
    cursor: pointer;

    &:active td {
      background: darken($secondary_bg, 1) !important;
    }
  }

  .intake-list {
    margin: 0;
    padding: 0 0 0 15px;
  }

  .gov-id-photo {
    width: 200px;
  }
}