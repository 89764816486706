@import '../../../colors.scss';

.test-table {
    width: 100%;
}

.primary-table td {
    vertical-align: top;
}

.test-details {
    display: block;
    margin-top: 5px;
}