@import '../../../../colors.scss';

.multiple-input {
  .multiple-input-form {
    width: 100%;
  }

  .field-container {
    position: relative;
  }

  .multiple-input-item {
    margin-bottom: 10px;
  }

  .field-suffix {
    position: absolute;
    top: 11px;
    right: 10px;
    font-size: 18px;
    height: 100%;
    color: $secondary_text;
  }

  .multiple-input-field {
    background: #f8f8f8;
    font-size: 18px;
    height: 52px;

    &:hover {
      background: $green_bg;
      border: 1px solid $success_light;
    }

    &:focus {
      background: $green_bg;
      border: 1px solid $success;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .suffix-item {
    position: absolute;
    right: 4px;
    top: 34px;
    width: 100px;
  }

  .multiple-select-field .ant-select-selector{
    box-shadow: none !important;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }
}