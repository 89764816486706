.general-exercise-advice {
  .video-container {
    display: block;
    padding-top: 180%;
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 40px;
  }

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-icon {
    font-size: 80px;
    color: white;

    &:active {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .advice-section {
    margin-top: 30px;
  }
}