@import '../../colors.scss';
@import '../../global.scss';

.members {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .impersonate-btn {
    &:hover {
      color: $success !important;
      border-color: $success !important;
    }
  
    &:active {
      color: darken($success, 10) !important;
      border-color: darken($success, 10) !important;
    }
  }
}


.downloading-text {
  color: #888;
}