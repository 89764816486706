@import '../../../colors.scss';

.test-chart {
  width: 100%;

  .scatter-chart {
    position: relative;
    z-index: 2;
  }

  .chart-position {
    width: calc(100%);
    position: relative;
  }

  .test-name-container {
    position: absolute;
    left: -230px;
    top: calc(50% - 42px);
    width: 200px;
  }

  .test-name-label {
    text-align: center;
    font-weight: 600;
    color: $primary_text;
    font-size: 13px;
    float: right;

    span {
      color: $secondary_text;
      display: block;
      font-weight: 400;
    }
  }

  .read-more-btn {
    position: absolute;
    top: 15px;
    right: 13px;
    font-size: 10px;
    z-index: 2;
  }
}