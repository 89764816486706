@import '../../colors.scss';

.loading-flow {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $secondary_bg;
  border-radius: 5px;
  position: fixed;
  height: 100%;
  width: 100%;

  .loading-flow-text {
    color: $secondary_text;
    padding-left: 10px;
  }
}

.flow-split {
  min-height: 200%;

}

.flow-split+.footer-section {
  display: none;
}