@import '../../colors.scss';

.admin-reminder-form {
  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }

  .submit-btn {
    width: 100%;
    height: 43px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
  }
}

.admin-reminder-modal {
  .ant-modal-content {
    padding-bottom: 10px;
  }
}