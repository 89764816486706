@import '../../colors.scss';

.membership-points-modal {
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }

  .membership-header {
    text-align: center;
    font-weight: 600;
    margin-top: 30px;
    font-size: 22px;
    margin-bottom: 0px;
  }

  .membership-pricing {
    text-align: center;
    color: $secondary_text;
    font-size: 14px;
    margin-bottom: 30px;
  }

  .membership-points-container {
    border-top: 1px solid $border;
  }
}