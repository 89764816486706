@import '../../colors.scss';

.card-modal {
  .submit-btn {
    width: 100%;
    height: 44px;
    font-size: 14px;
    font-weight: 600;
  }

  .overdue {
    color: $secondary_text;
    margin-top: -15px;
    margin-bottom: 40px;
  }

  .overdue-amount {
    color: $success;
  }

  .ant-form-item-control-input-content {
    background: $secondary_bg;
    border-radius: 5px;
  }

  .StripeElement {
    padding: 16px 15px;
    border: 1px solid $border;
    border-radius: 5px;

    &:hover:not(.StripeElement--invalid) {
      background: $green_bg;
      border: 1px solid $success_light;
    }
  }

  .StripeElement--focus:not(.StripeElement--invalid) {
    border: 1px solid $success !important;
    background: $green_bg;
  }

  .StripeElement--invalid {
    border: 1px solid $error !important;
    background: $error_bg;
  }
}