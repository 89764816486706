.included-tests-modal {
  .ant-select-selector {
    overflow-y: auto;
  }

  .ant-select-selection-overflow-item {
    margin-top: 5px;
    margin-left: 3px;
  }



  .ant-select-selection-overflow {
    padding-bottom: 5px;
  }

  .ant-select-selection-overflow-item-suffix,
  .ant-select .ant-select-selector .ant-select-selection-search-input {
    height: 32px !important;
    margin-left: 0;
  }

  .ant-select-selection-search {
    inset-inline-start: 0;
    inset-inline-end: 0;
  }
}