.pregnant-nutrients-advice {

    .test-chart,
    .test-item-list {
        margin-top: 30px;
    }

    .advice-section {
        margin-top: 40px;
    }
}