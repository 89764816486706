.payment-methods-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 600px;
    padding: 16px;


    .add-card {
        background-color: #f0f7ff;
        cursor: pointer;
    }


    .card-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
    }

    .card-info {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .card-brand svg {
        width: 40px;
        height: 25px;
    }

    .card-details {
        display: flex;
        flex-direction: column;
    }

    .card-actions {
        display: flex;
        gap: 8px;

        .ant-btn-link {
            color: #8C8C8C;

            .anticon {
                color: #8C8C8C;
            }

            &:hover {
                color: #000;

                .anticon {
                    color: #000;
                }
            }
        }
    }


}