@import "../../colors.scss";
@import "../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .join {
    padding: 30px;
    max-width: 600px;
    margin: $vertical_padding auto;

    .join-title {
      margin: 0 0 10px 0;
      font-size: $h3;
      line-height: $h3_line_height;
      font-weight: 600;
    }

    .join-description {
      font-size: $h5;
      line-height: $h5_line_height;
      color: $secondary_text;
      margin-bottom: 30px;
    }

    .login-button,
    .register-button,
    .join-form-button,
    .login-form-button,
    .existing-user-btn {
      width: 100%;
      height: 44px;
      font-weight: 600;
      margin: 5px 0;
    }

    .login-link,
    .message-link {
      color: $success !important;
    }

    .ant-form-item-explain-error {
      float: left;
      background: $error_bg;
      padding: 5px 10px;
      border-radius: 3px;
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  
    .error-link {
      color: $error !important;
      font-weight: 600;
    }
  
    .submit-btn {
      width: 100%;
      height: 43px;
      font-size: 14px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}