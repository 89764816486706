@import '../../colors.scss';

.test-results-modal {
  .category-item {
    margin-top: 20px;
  }

  .category-name {
    color: $primary_text;
    font-size: 12px;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }

  .test-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;

    &:hover {
      background: $secondary_bg;
    }
  }

  .test-name {
    color: $primary_text;
    font-size: 12px;
    width: 190px;
  }

  .test-unit {
    color: $secondary_text;
    font-size: 12px;
    width: 100px;
    text-align: right;
  }

  .ant-form-item {
    margin: 0;
  }

  .field-container {
    flex: 1;
  }


  .ant-radio-wrapper {
    padding: 0;
    border: 0;
    font-size: 12px;
  }

  .ant-radio-group .ant-radio-checked .ant-radio-inner {
    border-color: $success;

    &:after {
      background: $success;
    }
  }

  .ant-radio-group .ant-radio-wrapper-checked {
    background: rgba(0, 0, 0, 0);
  }

  .ant-select-arrow {
    margin-top: -10px;
  }
}