@import '../../../colors.scss';
@import '../../../global.scss';


@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
) {
    .health-score {
        position: relative;
        z-index: 1;

        .health-score-dashboard {
            position: relative;
            text-align: center;
            padding-top: 80px;
            margin: 0 auto;
        }

        .dotted-border-container {
            overflow: hidden;
            position: absolute;
            top: 30px;
            left: 30px;
        }

        .dotted-border {
            background-color: rgba(0, 0, 0, 0);
            border-radius: 50%;
            border: 3px dotted $border;
            transform: rotate(180deg);
        }

        .health-score-value {
            color: $primary_text;
            display: block;
            font-size: 65px;
            font-weight: 600;
        }

        .progress-container {
            position: absolute;
            top: 0;
            left: 0;
        }

        .health-score-footer {
            color: $secondary_text;
            font-size: 14px;
            display: block;
            margin-top: 10px;
        }

        .health-score-title {
            font-size: $h5;
            line-height: $h5_line_height;
            color: $primary_text;
            max-width: 320px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }

        .health-score-content {
            text-align: center;
            margin-top: 80px;
            border-radius: 10px;
        }

        .health-score-btn {
            margin-top: 10px;
            font-size: calc(0.9*$h5);
            line-height: $h5_line_height;
        }


        .score-link {
            color: $success;
            cursor: pointer !important;
            pointer-events: auto !important;
            position: relative;
            z-index: 10; // Ensure it's above any other elements

            &:hover {
                color: $success;
            }
        }

    }


}

@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,

    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,

    );
}