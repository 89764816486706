@import '../../colors.scss';

$width: 750px;

.blog-list-component {
  max-width: $width;
  width: 100%;
  margin: 50px auto 0 auto;

  .blog-list-header {
    margin-bottom: 40px;
    padding: 0 10px;
  }

  .blog-list-title {
    font-size: 45px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .blog-list-description {
    font-size: 24px;
    line-height: 1.6;
    margin: 0px auto 30px auto;
    color: $primary_text;
    max-width: 100%;
  }

  .subscribe-btn {
    font-weight: 600;
    font-size: 18px;
    height: 50px;
    width: 100%;
    margin-bottom: 30px;
  }

  .blog-timeline {
    padding: 0 10px 0 10px;
  }

  .timeline-dot {
    width: 20px;
    margin-top: 10px;
  }

  .timeline-item {
    background: $secondary_bg;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    position: relative;


    &:active {
      background: darken($secondary_bg, 2);
    }
  }

  .timeline-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 15px;
    color: $primary_text;
  }

  .timeline-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 20px 0 25px 0;
    color: $primary_text;
  }

  .timeline-published-at {
    color: $secondary_text;
  }

  .timeline-read-time {
    color: $secondary_text;
  }

  .timeline-arrow-icon {
    position: absolute;
    right: 15px;
    bottom: 20px;
    color: $success;
    font-size: 20px;
  }
}

.dashboard-research {
  padding: 24px;

  .research-header {
    background: linear-gradient(to right, #f8f9fa, #ffffff);

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
        text-align: center;
      }
    }
  }

  .research-card {
    height: 100%;
    transition: transform 0.2s;

    &:hover {
      transform: translateY(-4px);
    }

    .card-description {
      color: #666;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .card-footer {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 16px;
      color: #888;

      .arrow-icon {
        margin-left: auto;
      }
    }
  }

  .appointment-section {
    height: 100%;

    .empty-appointments {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      padding: 24px 0;
    }
  }

  .dot-separator {
    margin: 0 8px;
  }
}