@import '../../../colors.scss';

.pro-draw-flow {
  .panel-link {
    color: $success;
  }
}

.time-remaining {
  font-weight: bold;
  color: $warning; // Red color for urgency
  background: $warning_bg; // Light red background
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid $warning;
}

.timer-warning {
  background: $warning_bg;
  padding: 12px;
  border-radius: 4px;

  .timer-warning-content {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .timer-warning-content {
      padding: 0 10px;

    }
  }

  span {
    font-weight: 600;
  }

  .time-remaining {
    font-weight: 600;
    color: $warning;
  }
}