@import '../../colors.scss';

.admin-heart-health {
  .ant-table-cell {
    padding-right: 0 !important;
  }

  .add-btn {
    font-size: 13px;
    padding: 0px;
    min-width: 107px;
    height: 30px;
    margin-top: -10px;
    margin-bottom: 2px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .tag-container {
    display: inline-block;
    border: 0 !important;
    padding: 0 !important;
    min-width: 110px;
    text-align: center;

    .ant-spin-dot-item {
      background-color: rgba(255,255,255,0.8) !important;
    }
  }

  .tag {
    display: block;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 13px; 
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 0;
  }

  .reminder-assignee-photo {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 2px;
    margin-left: 2px;
    float: left;
    vertical-align: middle;
    margin-top: 2px;
  }

  .next-test-due-tag,
  .empty-tag,
  .outdated-tag,
  .overdue-reminder-tag {
    background: $error;
    color: white;
  }

  .result-tag,
  .sent-tag,
  .previous-tag,
  .delivered-tag,
  .past-consult-tag,
  .past-reminder-tag,
  .complete-reminder-tag {
    background: $success;
    color: white;
  }

  .next-test-default-tag,
  .next-test-future-tag,
  .paused-tag,
  .pending-tag,
  .unresponsive-kit-tag {
    background: #eee;
    color: $secondary_text;
  }

  .next-test-appointment-tag,
  .next-test-kit-tag,
  .pending-kit-tag,
  .appointment-tag,
  .future-consult-tag,
  .future-reminder-tag {
    background: $warning;
    color: white;
  }

  .tag-spin {
    margin-right: 5px;
    transform: scale(0.8,0.8);
  }

  .no-value {
    color: #aaa;
  }
}

.consult-title,
.reminder-title {
  font-weight: 600;
  font-size: 14px;
  color: white;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  text-decoration: underline;
}

.consult-reason,
.reminder-text {
  font-size: 14px;
  color: white;
  margin: 0 0 5px 0 !important;
}

.question-title {
  font-size: 14px;
  color: white;
  font-weight: 600;
  margin: 10px 0 0 0 !important;
}

.question-answer {
  font-size: 14px;
  color: white;
  margin: 5px 0 5px 0 !important;
}

.view-more-button {
  font-size: 13px;
  color: $secondary_text !important;
  padding: 0;
  text-align: center;
  display: block;
  width: 100%;

  .anticon {
    margin-right: 5px;
    float: left;
    margin-top: 5px;
    margin-right: -8px;
    padding-left: 10px;
    font-size: 12px;
  }
}