@import '../../colors.scss';
@import '../../global.scss';

.appointments {

  width: 100%;

  .credit-count {
    background: rgba(255, 255, 255, 0.9);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    color: $success;
    font-size: 12px;
    font-weight: 600;
    margin-left: 3px;
  }

  .appointments-container {
    padding: 16px;

    .ant-col {
      width: 100%;
    }
  }

  // Show mobile cards ONLY on small screens
  @media screen and (max-width: 768px) {
    .mobile-view {
      display: block;
      padding: 16px;
    }

    .desktop-view {
      display: none;
    }
  }

  // Show table ONLY on larger screens
  @media screen and (min-width: 769px) {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }



  .time-column {
    white-space: nowrap;
  }

  .pending-message {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 0.85em;
    color: #6c757d;
    line-height: 1.4;
  }

  // .appointment-status {
  //   text-transform: capitalize;
  //   font-weight: 500;
  //   font-size: 12px;
  // }


  .ant-tag {
    border: none;

  }



  .location-column {
    .facility-name {
      font-weight: 600;
      margin-bottom: 2px;
    }

    .address-line {
      color: #666;
      line-height: 1.4;
    }
  }
}