@import '../../../../colors.scss';

.invite {
  .invite-form {
    width: 100%;
  }

  .invite-log {
    background: #f8f8f8;
    font-size: 18px;
    height: 52px;
    border-radius: 6px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    border: 1px solid $border;
  }

  .invite-log-icon {
    margin-right: 10px;
    color: $success;
  }

  .invite-log-btn {
    height: 35px;

    &:hover,
    &:focus {
      color: $error !important;
      border-color: $error !important;
    }

    &:active {
      color: darken($error, 10) !important;
      border-color: darken($error, 10) !important;
    }
  }

  .invite-item {
    margin-bottom: 20px;
  }


  .invite-options {
    margin: 40px 0 0;
  }

  .invite-section {
    font-size: 14px;
    font-weight: 600;
    padding: 12px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid $border;
    background: transparent;
    border-radius: 0;
  }


  .invite-field {
    background: #f8f8f8;
    font-size: 18px;
    height: 52px;

    &:hover {
      background: $green_bg;
      border: 1px solid $success_light;
    }

    &:focus {
      background: $green_bg;
      border: 1px solid $success;
    }
  }

  .remove-option-btn {
    height: 52px;
  }

  .ant-select {


    .ant-select-arrow {
      margin-top: -2px;
    }

    .ant-select-selector {
      background: #f8f8f8 !important;
      font-size: 18px;
      min-height: 52px;
      padding: 10px 15px !important;


      // This ensures the content is vertically centered
      .ant-select-selection-search,
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        line-height: 52px !important;
        font-size: 18px !important;
        padding: 12px 0px !important;
      }
    }
  }

}