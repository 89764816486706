@import '../../colors.scss';
@import '../../global.scss';

.testkits {
  width: 100%;

  .testkit-status {
    display: inline-block;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    text-transform: capitalize;


    &.complete-status,
    &.completed-status {
      background: $success_bg;
      color: $success;
    }

    &.canceled-status,
    &.failed-status,
    &.cancel-status {
      color: $error;
      background: $error_bg;
    }

    &.confirmed-status,
    &.delivered-status,
    &.shipped-status {
      color: $primary_text;
      background: $secondary_bg;
    }

    &.fulfilling-status {
      color: #f59e0b;
      background: $warning_bg;
    }


  }

  // Show mobile cards ONLY on small screens
  @media screen and (max-width: 768px) {
    .mobile-view {
      display: block;
      padding: 16px;

      .testkit-status {
        text-transform: uppercase;
      }
    }

    .desktop-view {
      display: none;
    }
  }

  // Show table ONLY on larger screens
  @media screen and (min-width: 769px) {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }

  // Card styles for mobile view
  .testkit-card {
    margin-bottom: 16px;

    .ant-card-body {
      padding: 16px;
    }

    .status-container {
      text-transform: uppercase;
      font-size: 12px;
    }

    .testkit-card-content {
      .testkit-card-row {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }

        &.test-name {
          font-weight: 600;
          font-size: 16px;
        }

        &.ordered,
        &.tracking {
          color: rgba(0, 0, 0, 0.65);
        }

        .tracking-link {

          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

}



// Add other status colors as needed, following the same pattern