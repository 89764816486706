.test-chart-drawer {
  position: relative;

  .ant-drawer-body {
    padding: 0;
    position: relative;
  }

  .test-details {
    padding-bottom: 50px;
  }
}