@import '../../colors.scss';

.book-appointment-nav {
  .prev-btn {
    margin-right: 5px;
  }

  .previous-link {
    color: $secondary_text;
    margin-top: 20px;
    margin-right: 20px;
    display: inline-block;

    svg {
      margin-right: 3px;
    }
  }
}