@import '../../colors.scss';
@import '../../global.scss';

.view-patient {
  .patient-header-container {
    background: $secondary_bg;

    .patient-header {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $border;
      align-items: center;

      .user-name {
        color: $primary_text;
        font-weight: 500;
      }

      .user-demographics {
        color: $secondary_text;
        font-weight: 500;
        margin-left: 2px;
      }

      .patient-header-select {
        width: 200px;
        height: unset;
      }

      .action-items-btn {
        height: 44px;
        margin-right: 10px;
      }

    }
  }

  .tabs-container {
    background: $secondary_bg;

    .ant-tabs-content-holder {
      background: white;
    }
  }

  .view-patient-skeleton {
    width: 100%;
    padding: 0 15px;
  }

  .ant-skeleton-paragraph li,
  .ant-skeleton-title {
    height: 30px !important;
  }
}