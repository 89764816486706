@import '../../../../colors.scss';

.heart-health-test-approval {
  .question-btn {
    font-weight: 600;
    padding: 0 35px;
    height: 40px;
    font-size: 16px;
  
    &:hover {
      border: 1px solid lighten($success, 15);
      color: lighten($success, 15);
    }
  
    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  .instruction-list {
    background: $secondary_bg;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }

  .instruction-item {
    padding: 12px 0 5px 0;
  }

  .instruction-link {
    color: $success;
    text-decoration: underline;

    &:hover {
      color: lighten($success, 15);
      text-decoration: underline;
    }

    &:active {
      color: darken($success, 10);
      text-decoration: underline;
    }
  }

  .instruction-index {
    color: $success;
    background: $green_bg;
    font-size: 13px;
    padding: 5px 0;
    width: 60px;
    display: block;
    text-align: center;
    border-radius: 3px;
    margin-top: 5px;
  }

  .ant-col .section-item:not(:first-child) {
    margin-top: 20px;
  }

  .section-title {
    font-weight: 600;
    font-size: 15px;
  }

  .section-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
}