@import '../../colors.scss';
@import '../../global.scss';

.admin-reports-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .locked-status {
    color: $primary_text;
    font-size: 14px;

    .anticon {
      color: $success;
      margin-right: 2px;
    }
  }

  ol,
  ul {
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 5px;
    }

    li:last-child {
      margin-bottom: 0;
    }
  }

  .ant-table-cell {
    vertical-align: top;
  }
}