@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .product-details-small-biomarker-panel-container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    &:has(.controls) {
      padding-bottom: 2rem;
    }
  }

  .product-details-small-biomarker-panel {
    display: flex;
    overflow-x: auto;
    overflow-y: visible;
    gap: 25px; // Add space between biomarkers
    scroll-behavior: smooth; // Ensure smooth scrolling

    .biomarker {
      background-color: #fafafa; // Lighter, more subtle background
      border: 1px solid rgba(0, 0, 0, 0.08); // More subtle border
      // border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04); // More subtle shadow
      padding: 30px 25px;
      min-width: 240px; // Slightly wider for better proportions
      flex: 1;
      transition: all 0.2s ease-out;

    }

    .biomarker-name {
      font-weight: 500;
      margin-bottom: $h4_line_height;
      font-size: $h3;
      letter-spacing: -0.01em;
    }

    .biomarker-description {
      color: rgba(0, 0, 0, 0.6); // More sophisticated text color
      font-size: $h4;
      line-height: $h4_line_height;
      font-weight: 400;
    }
  }

  .controls {
    position: absolute;
    bottom: -35px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;


    .scroll-button {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: $secondary_text;
      padding: 0.5rem;
    }

    .see-more-link {
      color: $secondary_text; // Use secondary text color for the link
      text-decoration: none;
      font-size: $h5;
      line-height: $h5_line_height;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}