@import "../../colors.scss";

.sidebar-layout {
  padding-left: 20px;
  padding-top: 15px;
  background: $secondary_bg;

  .sidebar-skeleton {
    width: calc(100% - 30px) !important;
    margin-left: 4px;
  }

  .ant-layout-sider {
    background: $secondary_bg;
    width: 100% !important;
    max-width: 100% !important;
  }

  .ant-menu {
    background: $secondary_bg;
    border-right: 0 !important;
  }

  .ant-menu-item-divider {
    margin: 10px 0;
    border-top: 0;
    border-bottom: 1px solid $border;
  }

  .ant-menu-submenu-arrow {
    color: $secondary_text !important;
  }

  .ant-menu-submenu .ant-menu-item {
    padding-left: 25px !important;
    margin: 1px 0 !important;
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 34px !important;
    padding: 0 40px 0 12px !important;
    margin: 1px 0 !important;
    border-radius: 5px;
    width: calc(100% - 20px);
    position: relative;

    .ant-menu-title-content,
    .ant-menu-item-icon {
      color: $primary_text;
      width: 14px;
    }
  }

  .ant-menu-submenu-title {
    &:focus {
      background: $secondary_bg;
    }

    &.ant-menu-item-selected,
    &:active {
      background: darken($secondary_bg, 10) !important;
    }
  }

  .ant-menu-item {

    &.ant-menu-item-selected,
    &:active,
    &:focus {
      background: $success_bg !important;

      .ant-menu-title-content,
      .ant-menu-item-icon {
        color: $success !important;
      }

      .ant-menu-item-icon {

        svg,
        path {
          stroke: $success;
        }
      }
    }
  }

  .menu-item-count {
    position: absolute;
    top: -3px;
    right: 15px;
    color: $secondary_text;
  }

  .ant-menu-title-content {
    font-size: 14px;
    font-weight: 400;
    margin-left: 7px !important;
  }

  .active-membership {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 3px;
    background: $secondary_bg;
    color: $primary_text;
    font-size: 12px;
    padding: 5px 10px 5px 8px;
    line-height: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border;
    background: white;

    .active-icon {
      color: #ffd600;
      margin-right: 4px;
    }
  }
}