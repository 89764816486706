@import '../../colors.scss';

.annotation-form-wrap {
  z-index: 9000 !important;
}

.annotation-form {
  .ant-modal-content {
    padding-bottom: 1px;
  }

  .annotation-btn {
    width: 100%;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-select-multiple {
    height: auto;

    .ant-select-selection-placeholder {
      inset-inline-start: 15px;
    }
    .ant-select-selector {
      padding-left: 0;
      padding-inline-start: 0px !important;
      height: auto !important;
    }

    .ant-select-selection-overflow-item {
      margin: 0;
      padding-right: 0;
      padding-left: 5px;
    }

    .ant-select-selection-search {
      inset-inline-start: 7px;
    }

    .ant-select-selection-item {
      height: 35px !important;
      margin-right: 0;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .ant-select-selection-item-content {
      padding-top: 2px;
      padding-left: 2px;
    }

    .ant-select-selection-overflow-item-suffix {
      padding-left: 0;
    }
  }  
}