@import '../../../../colors.scss';

.location {
  .location-form {
    width: 100%;
  }

  .location-item {
    margin-bottom: 20px;
  }

  .street-address-skeleton {
    width: 100%;
  }

  .street-address-skeleton .ant-skeleton-avatar {
    width: 100%;
    height: 51px;
    border-radius: 6px;
    border: 1px solid $border;
  }

  .location-field {
    background: #f8f8f8;
    font-size: 18px;
    height: 52px;

    &:hover {
      background: $green_bg;
      border: 1px solid $success_light;
    }

    &:focus {
      background: $green_bg;
      border: 1px solid $success;
    }
  }

  .location-select {
    .ant-select-selector {
      background: #f8f8f8 !important;
      font-size: 18px;
      height: 52px !important;
      box-shadow: none !important;
    }

    .ant-select-selection-search-input {
      height: 52px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      padding-top: 9px;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}