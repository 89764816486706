.profile {
  .page-header {
    padding-right: 15px;
    padding-left: 15px;
  }

  .profile-section {
    margin-top: 20px;
  }

  .ant-table-tbody .ant-table-row:first-child td {
    border-top: 0 !important;
  }

  .ant-table-cell {
    position: relative;
  }

  .edit-btn {
    position: absolute;
    right: 10px;
    top: 6px;
  }
}