@import '../../global.scss';

.providers {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .photo-col {
    padding-top: 8px !important;
    padding-bottom: 0px !important;
  }

  .user-photo {
    width: 26px;
    height: auto;
  }
}