@import '../../colors.scss';

.confirm-appointment {
    .detail-list {
        background: $secondary_bg;
        border-radius: 4px;
        padding: 5px 10px;
    }

    .detail-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 30px;
    }

    .detail-label {
        font-weight: 600;
        font-size: 13px;
        vertical-align: top;
    }

    .detail-value {
        font-size: 13px;
    }
}