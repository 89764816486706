@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .referral-box {

    background: $primary_bg;
    border: 1px solid rgba(108, 199, 211, 0.2);
    border-radius: 5px;
    color: $primary_text;

    .product-box-header {
      padding: 10px 15px;
      font-weight: 600;
      font-size: calc(0.8*$h5);
      color: $secondary_text;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(108, 199, 211, 0.2);
      background: #dcf5e7;
    }

    .product-box-main {
      padding: 20px;
      color: $secondary_text;
    }

    .product-title {
      font-weight: 600;
      font-size: $h4;
      line-height: $h4_line_height;
    }

    .product-directions {
      margin: 10px 0px;
      font-weight: 400;
      font-size: $h5;
      line-height: calc(0.9*$h4_line_height);
    }

    .product-buy {
      margin: 0;

    }

    .advice-buy-btn {
      padding: 0px 30px;
      font-size: $h5;
      line-height: $h5_line_height;
      height: $h3_line_height;
      font-weight: 500;
      margin-top: 20px;
      margin-bottom: 5px;
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}