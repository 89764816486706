@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
) {
    .summary-next-steps {

        margin-top: 10px;
        padding: 10px;

        .summary-item:last-child {
            margin-bottom: 0;
        }

        .summary-item {
            margin: calc($vertical_padding/3) 0px;

            .summary-item-description {
                display: flex;
                align-items: center;

                .advice-item-description {
                    margin: 0 !important;
                    color: $secondary_text;
                    font-size: $h5 !important;
                    line-height: $h5_line_height !important;
                }

            }


            .summary-item-button {
                display: flex;
                align-items: center;
                // margin: 0 1em 0 0 !important
            }
        }

        .next-steps-section {
            background: $secondary_bg;
            padding: 40px;
            border: 0 !important;

        }

        .advice-title {
            text-align: center;
            font-size: $h4;
            line-height: $h4_line_height;
        }

        a {
            color: $secondary_text !important; //$success !important;
            text-decoration: underline dotted !important; //underline;

            &:hover {
                color: darken($secondary_text, 10%); // Adjust color on hover if needed
            }
        }
    }
}


@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,

    );

    .summary-next-steps {
        .next-steps-section {
            padding: calc($mobile_vertical_padding) calc(1.5*$mobile_horizontal_padding);
        }

    }
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,

    );
}