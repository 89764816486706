@import "../../colors.scss";
@import "../../global.scss";

.patient-layout {
  position: relative;
  min-height: calc(100vh - 68px);


  .patient-row {
    width: 100%;
  }

  .affix-sidebar {
    min-height: 100%;
    background: $secondary_bg;
  }

  .sidebar-column {
    border-right: 1px solid $border !important;
    z-index: 1;
  }
}

@media screen and (max-width: 992px) {
  .patient-layout {
    margin-top: 0px;
  }
}