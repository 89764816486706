@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
  $h6,
  $h6_line_height,
) {
  .loading {
    text-align: center;
    padding: 100px 0;
    margin: auto;

    .loading-text {
      color: $secondary_text;
    }
  }

  .gameplan {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    // background-color: $secondary_bg;
    // padding: 10px;

    a {
      color: $success;

      &:hover {
        text-decoration: underline;
        color: $success;
      }

    }

    .ant-collapse .ant-collapse-header {
      padding: 15px 0px !important;
    }

    .ant-card-type-inner .ant-card-body {
      padding: 15px 24px;
    }

    .gameplan-main {
      min-height: 1500px;
      padding: 20px;
    }


    p:first-child {
      margin-top: 0;
    }

    &-main {
      padding: 0 40px;
      // max-width: 900px;
    }

    &-section {
      margin-bottom: 48px;

      .section-title {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #262626;
        padding-bottom: 8px;
        border-bottom: 1px solid #f0f0f0;
      }
    }


    .recommendation {
      &-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &-item {
        border: 1px solid #f0f0f0;
        border-radius: 8px;
      }

      &-header {
        display: flex;
        align-items: center;
        padding: 16px;
        cursor: pointer;
        gap: 12px;

        &:hover {
          background: #fafafa;
        }
      }

      &-dot {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $success;
        display: flex;
        align-items: center;
        justify-content: center;

        .expand-icon {
          color: white;
          font-size: 12px;
          transition: transform 0.2s ease;

          &.expanded {
            transform: rotate(90deg);
          }
        }
      }

      &-title {
        margin: 0;
        font-size: $h4;
        line-height: $h4_line_height;
        color: #262626;
        font-weight: 500;
      }

      &-content {
        padding: 24px 16px 16px 52px; // 52px to align with title after dot
        color: #595959;
        border-top: 1px solid #f0f0f0;
        font-size: $h4;
        line-height: $h4_line_height;
      }

      &-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &-header-content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-start-date {
        margin-left: 20px;
        white-space: nowrap;
      }
    }


    .video-container {
      margin-top: 20px;
    }

    .video-container {
      display: block;
      padding-top: 80%;
      position: relative;
      width: 100%;
      height: auto;
      max-width: 350px;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 30px;
    }

    .video-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .play-icon-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .play-icon-container:active {
      background: rgba(0, 0, 0, 0.3);

      .play-icon {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }

    .play-icon {
      font-size: 50px;
      color: white;
    }



    .secondary-link {
      color: $secondary_text;
      text-decoration: underline;
    }

    .advice-item-description {
      color: $secondary_text;

    }

    .page-header {
      border: 0px;
    }

    .contact-concierge {
      margin: 15px 0px;
      padding: 10px;
      font-size: $h5;
      line-height: $h5_line_height;
      border-radius: 5px;
      border: 1px solid lightgray;
    }

    .product-box {
      margin-bottom: 10px;
    }

    .table-of-contents {
      margin-left: -1px;
      width: 100%;
      min-height: 1500px;

      border-right: 1px solid $border;
      position: sticky;

      .toc-title {
        font-weight: 600;
        padding: 10px 10px;
        margin-bottom: 10px;
        display: block;
        border-bottom: 1px solid $border;
        font-size: $h5;
        line-height: $h5_line_height;
      }

      .ant-anchor {
        height: 100%;
        font-size: $h5;
        line-height: $h5_line_height;
        background: none;
      }

      .ant-affix,
      .ant-anchor-wrapper {
        height: 100% !important;
      }

      .ant-anchor-link {
        padding: 0 !important;
      }

      .ant-anchor-link a {
        padding: 4px 10px;

        &:hover {
          text-decoration: none;
        }
      }

      .ant-anchor-link:hover {
        background: $secondary_bg;
      }

      .ant-anchor-link-active>.ant-anchor-link-title {
        color: $success !important;


      }

      .ant-anchor-ink-visible,
      .ant-anchor-ink-ball-visible {
        background: $success !important;
      }
    }


    @media only screen and (min-width: $breakpoint_tablet) {
      .product-box {
        width: 66%;
      }


    }


  }

  .mobile-toc-drawer {
    .ant-drawer-body {
      padding: 0;

      .ant-anchor {
        padding: 16px;

        .ant-anchor-ink {
          background: none;
        }

        .ant-anchor-link-active {
          background: $secondary_bg;

          .ant-anchor-link-title {
            color: $success !important;

          }
        }

        .ant-anchor-link-title {

          font-size: $h5;
          line-height: $h5_line_height;
          padding: 6px 0;

          &:hover,
          &:focus {
            color: $success;
          }


        }


      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
    $h6: $mobile_h6,
    $h6_line_height: $mobile_h6_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
    $h6: $desktop_h6,
    $h6_line_height: $desktop_h6_line_height,
  );
}


// Responsive adjustments
@media (max-width: 768px) {
  .gameplan-main {
    padding: 0 16px;
  }

}

.mobile-toc-button {
  position: fixed;
  bottom: 80px;
  right: 24px;
  z-index: 9999;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 56px !important;
  height: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00B388;
  border: none;
  border-radius: 50% !important;
  padding: 0 !important;

  .anticon {
    font-size: 24px;
  }

  &:hover,
  &:focus {
    background: #009974;
  }
}

// Show only on mobile
@media (max-width: 767px) {
  .mobile-only {
    display: block;
  }
}

// Hide on desktop
@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}