@import '../../colors.scss';

.panel-modal {

  .ant-modal-content,
  .ant-modal-header {
    background: white;
  }

  .ant-modal-title {
    text-align: center;
    text-decoration: none;
  }

  .test-details {
    color: #7B7E86;
  }

  .test-details strong {
    color: #161718;
  }

  .ant-modal-close-icon {
    color: #7B7E86;
  }

  .category-title {
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    text-transform: uppercase;
    text-decoration: underline;
  }

  .category-test-list .category-test-item:first-child {
    border-radius: 5px 5px 0 0;
  }

  .category-test-list .category-test-item:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom: 0;
  }

  .category-test-item {
    display: flex;
    justify-content: space-between;
    background: $secondary_bg;
    border-bottom: 1px solid $border;
  }

  .test-name {
    // font-size: 13px;
    width: 180px;
    font-weight: 600;
    padding: 7px 15px;
    line-height: 22px;
  }

  .test-tagline {
    flex: 1;
    padding: 7px 15px;
    background: white;
  }

  @media only screen and (max-width: 640px) {

    /* For mobile phones: */
    .test-name {
      padding: 5px 15px 0px;
      display: block;
    }

    .test-tagline {
      display: block;
      padding: 0px 15px 5px;
      border: 0px;
    }

    .category-test-item {
      display: block;
      //border: 1px solid #f0f0f0;
    }


  }
}