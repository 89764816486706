@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height) {



  .redeem-gift-page {
    width: 100%;
    min-height: 100vh;
    background-color: $success_bg;
    display: flex;
    align-items: center;
    margin-top: -1vh;

    .gift-container {
      max-width: 1000px;
      height: 100%;
      margin: 0 auto;
      padding: 0 16px;

      @media screen and (max-width: 768px) {
        padding: 0 36px;
        width: 100%;
        max-width: 100%;

        .gift-content {
          margin: 24px 0px;
          padding: 24px 0px;
        }

      }

      .gift-card-container {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;

      }


      .gift-card {
        transform-style: preserve-3d;

        .card-back {
          padding: 0px24px;
        }
      }
    }

    .gift-content {
      background-color: white;
      border-radius: 12px;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
      padding: 48px;
      overflow: hidden;
    }

    .gift-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 100%;

      .gift-text-content {
        width: 100%;

      }

      h1 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 16px;
        color: #222222;
      }

      .gift-subtitle {
        display: block;
        font-size: 18px;
        color: #717171;
        margin-bottom: 24px;
      }

      .gift-code-input {
        background: #f8f8f8;
        font-size: 18px;
        height: 52px;

        border-radius: 8px;

        &:hover {
          background: $green_bg;
          border: 1px solid $success_light;
        }

        &:focus {
          background: $green_bg;
          border: 1px solid $success;
        }

        &.ant-input-status-error {
          border-color: $error;
        }
      }

      .error-message {
        color: $error;
        background: $error_bg;
        padding: 5px 10px;
        border-radius: 3px;
        font-size: 12px;
        margin-top: 10px;
        display: inline-block;
      }


    }

    .gift-image {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 24px 0px;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 12px;
      }
    }

    .redeem-button,
    .get-started-button {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      margin-top: 24px;
    }

    .get-started-button {
      margin-top: 0px !important;
    }

    .gift-box {
      background: white;
      padding: 2.5rem;
      border-radius: 16px;
      box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
      position: relative;
      overflow: hidden;
      text-align: center;

      // Subtle gradient background
      background: linear-gradient(to bottom right, #ffffff, #f8f9fa);
    }

    .gift-ribbon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 60px;
      background: #ff4d4f;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 80px;
        height: 40px;
        background: #ff4d4f;
        border-radius: 50%;
        top: -20px;
      }

      &:before {
        left: -60px;
      }

      &:after {
        right: -60px;
      }
    }

    .gift-title {
      margin-bottom: 2rem !important;
      font-size: 28px !important;
      font-weight: 600 !important;
      color: #2c3e50 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .title-icon {
        font-size: 32px;
      }
    }

    .product-name {
      font-size: $h3 !important;
    }

    .gift-message {
      font-size: 1.1em;
      display: block;
      margin: 1rem 0;
    }

    .gift-image-wrapper {

      img {
        max-width: 100%;
        border-radius: 8px;
      }
    }

    .get-started-button {
      background: linear-gradient(135deg, #00a870, #00956a);
      border: none;
      height: 52px;
      font-size: 18px;
      font-weight: 500;
      border-radius: 12px;
      padding: 0 40px;
      margin-top: 32px;
      transition: transform 0.2s, box-shadow 0.2s;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 168, 112, 0.2);
      }
    }

    .gift-note {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 12px;
      padding: 24px;
      border: none;
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.04);

      .gift-message {
        font-style: normal;
        color: #64748b;
      }

      .gift-sender {
        margin-top: 16px;
        color: #2c3e50;
        font-weight: 500;
      }
    }

    .product-items-container {
      max-height: 60vh;
      overflow-y: auto;
      padding-right: 16px;
      margin-right: -16px;



      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;

        &:hover {
          background: #555;
        }
      }

      mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
      -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);

      @media screen and (max-width: 768px) {
        max-height: unset;
        mask-image: none;
        -webkit-mask-image: none;
      }
    }

    .product-item {
      // padding: 16px 0;
      position: relative;


      .tagline {
        display: block;
        margin-bottom: 16px;
        color: rgba(0, 0, 0, 0.65);
      }

      .gift-image-wrapper {
        margin: 16px 0;
        text-align: center;

        img {
          max-width: 100%;
          height: auto;
          // border-radius: 8px;
          // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .product-divider {
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      margin: 16px 0;
    }

    .gift-note {
      background: rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      padding: 16px;
      margin-bottom: 24px;
      border: 1px solid rgba(0, 0, 0, 0.06);

      .gift-message {
        display: block;
        font-style: italic;
        margin-bottom: 8px;
      }

      .gift-sender {
        display: block;
        font-weight: 500;
      }
    }

    .bundle-items {
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .bundle-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);

      &:last-child {
        margin-bottom: 16px;
      }

      .bundle-item-title {
        font-size: $h4;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        text-align: center;


      }

      .bundle-item-tagline {
        font-size: $h5;
        line-height: $h5_line_height;
        color: #64748b;
        line-height: 1.6;
        text-align: center;
        padding-left: 0;
      }

    }

  }


}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height );

}