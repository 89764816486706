@import '../../colors.scss';
@import '../../global.scss';

.package-confirmed {

    .next-steps-container {
        .next-steps-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 24px;
        }

        .step-card {
            display: flex;
            gap: 20px;
            padding: 24px;
            background: #f8f8f8;
            border-radius: 8px;
            margin-bottom: 20px;

            .step-number {
                width: 32px;
                height: 32px;
                background: $success;
                color: #fff;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
            }

            .step-content {
                flex: 1;

                h4 {
                    margin: 0 0 8px;
                    font-size: 18px;
                    font-weight: 600;
                }

                p {
                    margin: 0;
                    color: #666;
                }
            }
        }
    }


    .schedule-button {
        margin-top: 12px;
    }
}