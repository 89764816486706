@import '../../../../colors.scss';

.comprehensive-test-intro {
  background: linear-gradient(to bottom right, rgba(13, 163, 128, 0.4), rgba(13, 163, 128, 0.1));
  height: 100%;
  position: relative;

  .test-intro-body {
    background: white;
    max-width: 600px;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }
}