@import '../../colors.scss';

.task-modal {
  .ant-modal-title,
  .task-description {
    margin: 0;

    p, span {
      margin: 0;
    }
  }
  
  .complete-checkbox-container {
    padding-right: 10px;
    float: left; 
    margin-top: -2px;
  }

  .complete-checkbox.adm-checkbox-checked .adm-checkbox-icon {
    background: $success;
    border-color: $success;
  }

  .task-description {
    color: $secondary_text;

    a {
      color: $success;
    }

    display: block;
    margin-left: 33px;
  } 

  .subtask-list {
    margin-left: 10px;
    margin-bottom: 30px;
    margin-top: 0px;
    color: $secondary_text;
  }

  .subtask-item {
    font-size: 14px;
  }

  .task-modal-body {
    margin-top: 20px;
  }

  .patient-value-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-descriptions-item-content {
    padding: 10px !important;
  }

  .copy-btn {
    font-size: 13px !important;
  }

  .no-value {
    color: $secondary_text;
  }

  .teammate-select {
    font-size: 14px;
    margin-top: -15px;

    .ant-select-selection-search {
      margin-inline-start: 0px;
      inset-inline-start: 0px;
      inset-inline-end: 0px;
    }

    .ant-select-selection-placeholder {
      padding-left: 5px;
    }
  }

  .tag-link {
    font-size: 13px;
    color: $success;
    margin-top: -15px;
    margin-bottom: 20px;
    display: block;
    float: left;
  }

  .submit-btn {
    height: 44px;
    width: 100%;
  }

  .ant-input {
    font-size: 13px;
  }

  .ant-empty {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .note-item {
    background: $secondary_bg;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 15px;
  }

  .note-user {
    color: $secondary_text;
    font-size: 13px;
  }

  .note-created-at {
    color: $secondary_text;
    font-size: 13px;
  }

  .note-bullet {
    color: $secondary_text;
    padding: 0 10px; 
  }

  .remove-link {
    float: right;
    font-size: 13px;
    color: $error;
  }

  .ant-descriptions-row {
    height: 40px;
    width: 100%;
  }

  .ant-descriptions-item-label {
    width: 110px;
    padding: 0 10px !important;
  }

  .ant-descriptions-item-content {
    padding: 3px 10px !important;
    overflow: hidden;
  }

  .no-notes {
    background: $secondary_bg;
    color: $secondary_text;
    font-size: 14px;
    text-align: center;
    padding: 100px 0;
    border-radius: 4px;
  }

  .ant-descriptions {
    margin-bottom: 20px;
  }

  .ant-col .ant-descriptions:last-child {
    margin-bottom: 0;
  }

  .prescription-table {
    margin: -3px -10px 0 -10px !important;
    width: calc(100% + 20px) !important;

    table {
      width: calc(100% + 20px) !important;
    }
  }

  .appointment-table,
  .product-table,
  .membership-table {
    margin: -3px -10px !important;
    width: calc(100% + 20px) !important;

    table {
      width: calc(100% + 20px) !important;
    }
  }

  .result-table-container {
    margin: -3px -10px 0 -10px;
    width: calc(100% + 20px);
    margin-top: -4px;
  }

  .appointment-table,
  .product-table {
    .appointment-status {
      padding: 3px 8px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
    }
  
    .pending-appointment-status {
      color: $secondary_text;
      background: $secondary_bg;
    }
  
    .collected-appointment-status {
      background: $warning_bg;
      color: $warning;
    }
  
    .complete-appointment-status {
      background: $success_bg;
      color: $success;
    }
  
    .canceled-appointment-status,
    .deactivated-appointment-status {
      color: $error;
      background: $error_bg;
    }
  
    .confirmed-appointment-status,
    .scheduled-appointment-status {
      color: $primary_text;
      background: $secondary_bg;
    }
  }

  .membership-table {
    .status-text {
      padding: 3px 8px;
      border-radius: 5px;
      font-weight: 600;
      font-size: 14px;
    }
  
    .membership-Active-status {
      background: $success_bg;
      color: $success;
    }
  
    .membership-pending-status {
      color: $secondary_text;
      background: $secondary_bg;
    }

    .membership-Canceled-status,
    .membership-Overdue-status {
      color: $error;
      background: $error_bg;
    }
  }

  .edit-btn {
    height: 30px;
    width: 30px;
    padding: 0;
    margin: -4px 0;

    .anticon {
      font-size: 13px;
    }
  }

  .prescription-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
  }

  .pending-prescription-status {
    color: $secondary_text;
    background: $secondary_bg;
  }

  .paused-prescription-status {
    color: $error;
    background: $error_bg;
  }

  .sent-prescription-status {
    color: $success;
    background: $success_bg;
  }

  .add-btn {
    display: block;
    border: 0;
    border-radius: 0;
    background: $secondary_bg;
    color: $secondary_text;
    width: calc(100% + 20px);
    margin: -3px -10px -3px -10px;
    height: 40px;

    &:hover {
      background: lighten($secondary_bg, 1) !important;
      color: $secondary_text !important;
    }

    &:active {
      background: darken($secondary_bg, 2) !important;
      color: $secondary_text !important;
    }
  }
} 