@import './breakpoints.scss';

@media (min-width: $sm) {
  .visible-sm      { display: block !important; }
  .row.visible-sm  { display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex!important; }
  table.visible-sm { display: table !important; }
  tr.visible-sm    { display: table-row !important; }
  th.visible-sm,
  td.visible-sm    { display: table-cell !important; }
  .flex-column-sm   {flex-direction: column; }
}
@media (min-width: $md) {
  .visible-md      { display: block !important; }
  .row.visible-md  { display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex!important; }
  table.visible-md { display: table !important; }
  tr.visible-md    { display: table-row !important; }
  th.visible-md,
  td.visible-md    { display: table-cell !important; }
  .flex-column-md   {flex-direction: column; }
}
@media (min-width: $lg){
  .visible-lg      { display: block !important; }
  .row.visible-lg  { display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex!important; }
  table.visible-lg { display: table !important; }
  tr.visible-lg    { display: table-row !important; }
  th.visible-lg,
  td.visible-lg    { display: table-cell !important; }
  .flex-column-lg   {flex-direction: column; }
}
@media (min-width: $xl) {
  .visible-xl      { display: block !important; }
  .row.visible-xl  { display: flex !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: -webkit-box !important;
    display: flex!important; }
  table.visible-xl { display: table !important; }
  tr.visible-xl    { display: table-row !important; }
  th.visible-xl,
  td.visible-xl    { display: table-cell !important; }
  .flex-column-xl   {flex-direction: column; }
}

@media (min-width: $md) { .hidden-md { display: none !important; } }
@media (min-width: $lg) { .hidden-lg { display: none !important; } }
@media (min-width: $xl) { .hidden-xl { display: none !important; } }