@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .support-container {

    text-align: center;
    width: 100%;
    margin: 0 auto;
    background-color: $success_bg;
    background: url('../../assets/bg/green.jpg') no-repeat center center;
    background-size: cover;



    .max-width {
      max-width: 700px;
      background: white;
      height: 100vh;
      padding: 30px;
      padding-top: $vertical_padding;

    }

    .team-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      h1 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 600;
      }

      p {
        font-size: 16px;
        color: #888;
        font-weight: 400;
      }
    }

    .action-list {
      margin: 20px 0;

      .ant-list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .ant-list-item-meta {
        text-align: left;
      }

      .ant-list-item-meta-title {
        font-size: 18px;
        font-weight: 500;
      }

      .ant-list-item-action {}

      .start-chat {
        margin-top: 30px;

        button {
          width: 100%;
          font-size: 16px;
        }
      }

      .cta-link {
        width: 100px;
      }
    }

    .support-list-item {
      @media screen and (max-width: 768px) {
        .ant-list-item-action {
          margin-left: 0 !important;
          display: flex;
          flex-direction: column;
          align-items: center;

          li {
            margin: 3px 0 !important;
            padding: 0 !important;


          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}