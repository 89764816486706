@import '../../global.scss';
@import '../../colors.scss';

.admin-facilities {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

}

.facility-modal {
  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }
}