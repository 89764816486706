.flow-step-footer {
  margin-top: 30px;

  .flow-step-submit {
    font-weight: 600;
    padding: 0 15px;
    height: 40px;
    font-size: 16px;

    .anticon {
      margin-left: 10px;
      margin-right: -5px;
    }
  }

  .enter-prompt {
    display: inline-flex;
    align-items: center;
  }

  .enter-prompt-text {
    padding-right: 5px;
    font-size: 12px;
    margin-left: 20px;
  }

  .enter-prompt-icon {
    font-size: 12px;
    margin-top: -4px;
  }

  .skip-btn {
    margin-left: 10px;
    font-weight: 600;
    padding: 0 15px;
    height: 40px;
    font-size: 16px;

    .anticon {
      margin-left: 10px;
      margin-right: -5px;
    }
  }
}