@import "../../colors.scss";
@import "../../global.scss";

.about-page {

    // Common section styles
    .section {
        padding: 64px 0;

        .max-width {
            max-width: 1200px;
            margin: 0 auto;
            padding: 0 24px;
        }

        .section-header {
            text-align: center;
            margin-bottom: 48px;

            h2 {
                font-size: 32px;
                margin-bottom: 16px;
            }

            .section-subheader {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }

    // Mission section specific styles
    .mission-section {
        background-color: $success_bg;

        .manifesto {
            max-width: 900px;
            margin: 0 auto;

            .manifesto-statement {
                font-size: 18px;
                line-height: 1.6;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                strong {
                    font-weight: 600;
                }

                em {
                    font-style: italic;
                }
            }
        }

        .section-cta {
            .main-cta-btn {}
        }
    }

    // FAQ section specific styles
    .faq-section {

        .chat-link {
            color: $success;
            cursor: pointer;
            text-decoration: underline;

            &:hover {
                opacity: 0.8;
            }
        }

        .faq-grid {
            max-width: 900px;
            margin: 0 auto 40px;

            // Override default Ant Design Collapse styles
            .ant-collapse {
                background: transparent;

                .ant-collapse-item {
                    margin-bottom: 16px;
                    border-radius: 8px;
                    overflow: hidden;
                }

                .ant-collapse-header {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 16px 24px;
                }

                .ant-collapse-content {
                    .faq-answer {
                        // padding: 16px 24px;
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }
            }
        }

        // Add custom styling for the concierge service cards
        .ant-card {
            height: 100%;
            transition: all 0.3s ease;
            border: 1px solid #eaeaea;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
            cursor: default;

            &:hover {
                transform: translateY(-4px);
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
            }

            .ant-card-head {
                border-bottom: 1px solid #f0f0f0;
                padding: 16px 24px;
                min-height: auto;

                .ant-card-head-title {
                    font-size: 18px;
                    font-weight: 600;
                    color: #2d3748;
                    padding: 8px 0;
                    line-height: 1.4;
                    height: 100%;
                }
            }

            .ant-card-body {
                padding: 20px;

                ul {
                    margin: 0;
                    padding-left: 20px;

                    li {
                        color: #4a5568;
                        font-size: 15px;
                        line-height: 1.6;
                        margin-bottom: 12px;
                        position: relative;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &::marker {
                            color: $success;
                        }
                    }
                }
            }
        }

        // Responsive adjustments for mobile
        @media screen and (max-width: 768px) {
            .ant-card {
                .ant-card-head {
                    padding: 12px 16px;

                    .ant-card-head-title {
                        font-size: 16px;
                    }
                }

                .ant-card-body {
                    padding: 16px;

                    ul li {
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        .section-cta {
            text-align: center;

            .main-cta-btn {
                min-width: 200px;
                height: 48px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }
}