@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .membership-points {
    .point-list {
      margin-bottom: 15px;
      padding: 30px 30px 10px 30px;
    }

    .point-item {
      margin-bottom: 25px;
      width: 100%;
    }

    .point-item strike {
      color: #bbb;
    }

    .point-icon {
      margin-top: 4px;
      color: $success;
    }

    .point-svg {
      svg {
        height: 60px;
        width: auto;
      }
    }

    .point-title {
      font-weight: 500;
      font-size: $h5;
      line-height: $h5_line_height;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .point-description {
      font-size: $h5;
      line-height: $h5_line_height;
      color: $secondary_text;
      margin-bottom: 0;
    }

    .point-col {
      width: 30px;
    }

    .text-col {
      width: calc(100% - 30px);
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}