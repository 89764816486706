@import '../../../../colors.scss';

.image-upload {
  .image-upload-btn {
    font-weight: 600;
    padding: 0 35px;
    height: 40px;
    font-size: 16px;
  
    &:hover {
      border: 1px solid $success_light;
      color: $success_light;
    }
  
    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  // Hacky fix for widget initializing multiple times
  .picker-container > .fsp-drop-pane__container ~ .fsp-drop-pane__container { 
    display: none; 
  }

  .fsp-drop-pane__container {
    background: $secondary_bg;
    border: 1px dashed $success_light;
    padding: 60px 0 50px 0;
    cursor: pointer;
  }

  .fsp-drop-pane__icon {
    opacity: 0.5;
  }

  .fsp-drop-pane__upload-progress {
    background: $success;
  }

  .fsp-drop-pane__text {
    margin: 10px 0;
  }

  .uploaded-img-container {
    position: relative;
    background: $secondary_bg;
    border-radius: 5px;
    text-align: center;
    padding: 15px;
    min-height: 210px;
  }

  .uploaded-img-container img {
    width: 100%;
    max-width: 200px;
  }

  .remove-image-btn {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 13px;
    border-radius: 4px;

    &:hover {
      border: 1px solid lighten($error,12);
      color: lighten($error,12);
    }

    &:active {
      border: 1px solid $error;
      color: $error;
    }
  }

  .ant-form-item-control-input {
    display: none;
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .picker-container {
    position: relative;
    height: 210px;
  }

  .loading-skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .loading-skeleton .ant-skeleton-avatar {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid $border;
  }
}