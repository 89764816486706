@import '../../../../colors.scss';

.single-input {
  .single-input-form {
    width: 100%;
  }

  .single-input-item {
    margin-bottom: 0px;
  }

  .single-input-field {
    background: #f8f8f8;
    font-size: 18px;
    height: 52px;

    &:hover {
      background: $green_bg;
      border: 1px solid $success_light;
    }

    &:focus {
      background: $green_bg;
      border: 1px solid $success;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
  }

  .error-link {
    color: $error !important;
    font-weight: 600;
  }
}