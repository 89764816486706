@import '../../colors.scss';

.bold {
  font-weight: 600;
}

.secondary-text {
  color: #777777;
}

.text-05 {
  font-size: 38px;
  line-height: 44px;
}

.text-04 {
  font-size: 21px;
  line-height: 32px;
}

.text-03 {
  font-size: 16px;
  line-height: 25px;
}

.text-02 {
  font-size: 14px;
}

.text-01 {
  font-size: 12px;
  line-height: 16px;
}

.heart-health-v3 {
  // Shared

  .grey-container {
    background: #F7F7F7;
  }

  .heart-section {
    max-width: 1100px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .heart-label {
    @extend .text-01;
    font-weight: 600;
    margin: 0;
  }

  .heart-title {
    @extend .text-05;
    font-weight: 600;
    margin: 0;
  }

  .heart-title-underline {
    width: 150px;
    height: 6px;
    margin-top: 5px;
    background: $success;
  }

  .heart-subtitle {
    @extend .text-04;
    margin: 0;
  }

  .heart-description {
    @extend .text-03;
    color: #666666;
    margin: 0;
  }

  .heart-description-link {
    color: $success !important;
    text-decoration: underline !important;
    text-decoration-style: dotted !important;
    text-decoration-color: $success;
  }

  .heart-btn {
    @extend .text-03;
    font-weight: 600;
    padding: 0;
    height: 50px;
    width: 260px;
  }

  .highlight {
    color: $success;
  }

  // Header

  .heart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    height: 80px;
    position: relative;
    z-index: 3;
    width: 100%;
  }

  .logo-img {
    width: 100px;
  }

  .header-link {
    @extend .text-02;
    color: $secondary_text;
    margin-right: 20px;
  }

  .header-btn {
    @extend .text-02;
    font-weight: 600;
    padding: 0;
    height: 40px;
    width: 120px;
  }

  // Intro

  .intro {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  .intro-col {
    align-items: center;
    display: flex;
  }

  .intro-content {
    padding: 40px 0;
  }

  .intro-title,
  .intro-description,
  .intro-label {
    max-width: 550px;
  }

  .intro-label {
    margin-bottom: 15px;
  }

  .intro-title {
    margin-bottom: 25px;
  }

  .intro-description {
    margin-bottom: 30px;
  }

  .intro-btn {}

  .intro-img {
    width: 100%;
  }

  // Offerings

  .offerings-section {
    margin-bottom: 40px;
  }

  .offerings-list {
    background: #F7F7F7;
    border-radius: 30px;
    padding: 50px 30px 5px 30px;
  }

  .offering-item {
    margin-bottom: 40px;
  }

  .offering-img {
    width: 80px;
    margin-bottom: 10px;
  }

  .offering-title {
    color: $primary_text;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .price-container {
    background: $success;
    background: linear-gradient(135deg, #0ca37f 0%, #0ca085 50%, #0b8d79 100%);
    border-radius: 30px;
    padding: 30px;
  }

  .price-value {
    color: white;
  }

  .price-time {
    color: rgba(255, 255, 255, 0.8);
    margin-left: 3px;
  }

  .price-description {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 20px;
  }

  .price-btn {
    width: 100%;
    margin-top: 20px;
  }

  // Timeline

  .timeline-section {
    padding-top: 40px;
    padding-bottom: 30px;
  }

  .heart-timeline {
    margin-top: 60px;
  }

  .timeline-item {
    padding-left: 20px;
    margin-bottom: 30px;
  }

  .timeline-dot {
    width: 18px;
  }

  .timeline-label {
    margin-bottom: 10px;
  }

  .timeline-title {
    margin-bottom: 10px;
  }

  .timeline-description {
    margin-bottom: 30px;
  }

  .timeline-img {
    width: 100%;
  }

  .timeline-btn {
    margin-top: 30px;
  }

  // Results

  .results-section {
    padding-top: 70px;
    padding-bottom: 50px;
  }

  .results-description {
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .result-item {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 0 20px;
    margin-bottom: 25px;
    background: white;
    border-radius: 10px;
  }

  .result-text-col {
    flex: 1;
  }

  .result-reduction-col {}

  .result-title {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .result-description {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .reduction {
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    max-width: 160px;
    margin: 0 auto 20px auto;
  }

  .reduction-label {
    padding: 3px 0 5px 0;
  }

  .reduction-details {
    border-radius: 5px;
    background: white;
    border: 10px;
    padding: 15px 0 5px 0;
  }

  .reduction-value {
    @extend .text-05;
    font-weight: 600;
  }

  .reduction-unit {
    @extend .text-01;
    margin-left: 5px;
  }

  .reduction-demographics {
    @extend .text-01;
    margin-top: 10px;
    color: #666666;
  }

  // Testimonials

  .testimonials-section {
    padding-top: 60px;
    padding-bottom: 0px;
  }

  .testimonials-description {
    margin-top: 5px;
    margin-bottom: 50px;
  }

  .testimonial-item {
    margin-bottom: 50px;
  }

  .testimonial-img {
    width: 100%;
    margin-bottom: 10px;
  }

  .testimonial-name {
    font-weight: 600;
    color: $primary_text;
  }

  .testimonial-role {}

  .testimonial-quote {
    margin-top: 25px;
  }

  // FAQ

  .faq-section {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .faq-description {
    margin-top: 5px;
    margin-bottom: 40px;
  }

  .questions {
    padding: 0 !important;
  }
}