@import '../../colors.scss';
@import '../../global.scss';

.prescriptions {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .has-order-col {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .order-status {
    font-size: 12px;
    padding-top: 7px;
  }

  .check-icon {
    color: $success;
    margin-right: 5px;
  }

  .send-btn {
    font-size: 12px;
    padding: 0 10px;
    font-weight: 600;

    &:hover {
      border: 1px solid lighten($success, 15);
      color: lighten($success, 15);
    }

    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  .tracking-link {
    color: $success;
  }

  &-container {
    .desktop-view {
      display: block;
    }

    .mobile-view {
      display: none;
    }
  }
}

.tracking-list {
  margin-left: 0;
  padding-left: 20px;
  padding-right: 10px;

  li {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.prescription-card {
  margin-bottom: 16px;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &-row {
    display: flex;
    align-items: center;

    .medication-name {
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// Mobile styles
@media screen and (max-width: 768px) {
  .prescriptions-container {
    .desktop-view {
      display: none;
    }

    .mobile-view {
      display: block;
      padding: 16px;
    }
  }

  .prescription-card {
    .ant-card-body {
      padding: 12px;
    }
  }
}