@import '../../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
) {
    .product-detail-directions {
        margin: auto;
        display: flex;
        list-style: none;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-padding: 2rem;
        gap: 3rem;
        overflow-y: auto;


        .product-detail-direction {
            scroll-snap-align: start;
            max-width: 20rem;
            min-width: 20rem;

            .product-detail-direction-photo img {
                width: 100%;
                filter: grayscale(33%);
                // height: 100%;
            }

            .product-detail-direction-index {
                font-size: $h3;
                line-height: $h3_line_height;
                font-weight: 600;
                margin-bottom: 1.25rem;
            }

            .product-detail-direction-title {
                display: block;
                font-weight: 600;
                margin: 1rem 0;
                font-size: $h3;
                line-height: calc($h3_line_height*0.8);


            }

            .product-detail-direction-description {
                font-weight: 400;
                display: block;
                margin: 1rem 0;
                font-size: $h4;
                line-height: $h4_line_height;
            }


        }

    }
}


@media (max-width: $breakpoint_desktop) {

    .product-detail-directions-section-container {
        padding-right: 0px !important;

        .product-detail-direction:last-child {
            margin-right: 15px;
        }

    }

}


@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
    );
}