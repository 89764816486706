@import "../../colors.scss";

.total-exposure {
  padding: 20px;

  .slider-wrapper {
    // Ensures the tooltip works with Slider
    display: inline-block;
    width: 100%;
  }

  .ant-timeline-item-content {
    margin-inline-start: 0px;
  }
}

.stat-card {
  height: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);

  .stat-title {
    color: rgba(0,0,0,0.85);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .stat-description {
    color: rgba(0,0,0,0.45);
    font-size: 12px;
  }
  
  .anticon {
    font-size: 16px;
    margin-right: 4px;
  }
  
  .risk-table :global(.ant-table-tbody > tr:hover > td) {
    background: inherit !important; // Disable hover effect
  }
  
  .risk-table :global(.ant-table-tbody > tr > td) {
    transition: none !important; // Remove transition effect
  }
  
  // Ensure text remains readable against colored backgrounds
  .risk-table :global(.ant-table-tbody > tr) {
    color: rgba(0, 0, 0, 0.85); // Darker text for better contrast
  }
}

.sidebar-patient-select {
  width: 100%;

  .ant-select,
  .ant-select-selector {
    width: 100%;
    border-radius: 0;
    border-left: 0;
  }
}

.medication-tree {
  // Style for locked checkboxes
  .ant-tree-checkbox-disabled.ant-tree-checkbox-checked {
    // Match Ant Design's primary color
    .ant-tree-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
      
      // Make the checkmark white and visible
      &::after {
        border-color: #fff !important;
      }
    }
  }

  // Style for locked item text
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-disabled {
    color: rgba(0, 0, 0, 0.85) !important; // Make text more visible
  }

  .ant-tree-checkbox {
    margin-left: -20px;
  }
}

.projection-patient-option {
  .ant-select-item-option-content {
    padding-left: 15px !important;
  }
}

.loading-dots::after {
  content: '.';
  animation: dots 1s steps(2, end) infinite;
  display: inline-block;
  width: 0.5em;
}

@keyframes dots {
  0%, 50% { content: '.'; }
  51%, 100% { content: '..'; }
}

.research-citation {
  background: linear-gradient(to right, #f8f9fa, #ffffff);
  border: 1px solid #e8e8e8;
  height: 100%;
  padding: 12px;
  position: relative;

  .research-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .research-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #1890ff;
    margin-bottom: 4px;

    .ant-tag {
      margin-left: auto;
    }
  }

  .research-description {
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    margin-bottom: 20px;
  }
}

.card-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #161718;
  display: flex;
  align-items: center;
  gap: 8px;
}

.study-highlight {
  display: flex;
  gap: 12px;
  align-items: flex-start;

  .highlight-icon {
    margin-top: 4px;
    
    &.purple { color: #722ed1; }
    &.blue { color: #1890ff; }
    &.green { color: $success; }
    &.yellow { color: $warning; }
  }

  .highlight-title {
    font-size: 14px;  
    font-weight: 500;
  }

  .highlight-text {
    font-size: 12px;
    color: $secondary_text;
    margin-bottom: 4px;
  }
}

.benefit-item {
  display: flex;
  align-items: center;
  gap: '8px';
  margin-bottom: 12px;
  font-size: 14px;
}

.citation-tag {
  margin-left: auto;
}

.benefit-item .anticon {
  margin-right: 8px;
}

.join-program-btn,
.join-program-btn.ant-btn-primary:not([disabled]) {
  background: #1890ff !important;
  border-color: #1890ff !important;
  transition: all 0.3s;

  &:hover,
  &.ant-btn-primary:not([disabled]):hover {
    background: #40a9ff !important;
    border-color: #40a9ff !important;
  }

  &:active,
  &.ant-btn-primary:not([disabled]):active {
    background: #096dd9 !important;
    border-color: #096dd9 !important;
  }
}

.join-program-card {
  position: relative;
  overflow: hidden;
}

// Add responsive styles
@media (max-width: 768px) {
  .total-exposure {
    padding: 0;  // Remove padding on mobile
    
    .stat-card {
      .stat-value {
        font-size: 20px;
      }
      .stat-description {
        font-size: 12px;
      }
    }

    .research-citation {
      .study-highlight {
        margin-bottom: 16px;
      }
    }

    .risk-table {
      .ant-table {
        font-size: 12px;
      }
    }
  }

  // Adjust sidebar for mobile
  .sidebar-patient-select {
    width: 100%;
    margin-bottom: 12px;
  }

  // Make sure chart controls don't overlap
  .ldl-chart {
    .ant-btn {
      padding: 0 8px;
    }
  }
}

// Tablet-specific adjustments
@media (min-width: 769px) and (max-width: 1024px) {
  .total-exposure {
    .stat-card {
      .stat-value {
        font-size: 22px;
      }
    }
  }
}

.risk-timeline {
  padding: 30px 0 5px 0;

  .ant-timeline-item {
    padding-bottom: 24px;

    &:last-child { padding-bottom: 0; }

    .ant-timeline-item-tail {
      border-left: 2px solid rgba(237, 110, 127, 0.2);
      left: 20px;
    }

    .ant-timeline-item-head {
      background: transparent;
      padding: 0;
      border: none;
    }
  }

  .risk-badge {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    font-size: 14px;
    background: #ed6e7f;
    margin-left: 30px;
    margin-top: 20px;
  }

  .risk-card {
    margin-left: 40px;
    background: #fff;
    border-radius: 8px;

    .risk-zone {
      font-weight: 600;
      font-size: 14px;
      
      .risk-percentage {
        color: rgba(0,0,0,0.45);
        font-size: 12px;
        font-weight: normal;
        margin-top: 2px;
      }
    }

    .risk-impact {
      margin: 8px 0;
      color: #0ca37f;
      font-weight: 500;
      font-size: 13px;
    }
  }
}

@media (max-width: 480px) {
  .risk-timeline {
    padding: 16px 0 0px 0px;

    .risk-badge {
      width: 32px;
      height: 32px;
      font-size: 14px;
    }

    .risk-card {
      margin-left: 40px;
      padding: 3px 0px 12px 12px;

      .risk-zone {
        font-size: 18px;
      }
    }
  }
}

// Add to your existing styles
.ant-statistic {
  .ant-statistic-title {
    margin-bottom: 4px;
  }
  
  .ant-statistic-content {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .anticon {
      font-size: 20px;
      opacity: 0.7;
    }
  }
}