@import '../../../colors.scss';

.homocysteine-advice {
  .advice-section {
    margin-top: 30px;
  }  

  .test-chart {
    margin-top: 30px;
  }
}