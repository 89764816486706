@import '../../../../colors.scss';

.multiple-select {
  .adm-space-item {
    display: block;
    width: 100%;
    padding-bottom: 10px !important;
  }

  .adm-selector-item {
    padding: 10px 10px;
    width: 100%;
    max-width: 450px;
    margin-bottom: 2px;
    box-shadow: inset 0 0 1px $success_light, inset 0 0 1px $success_light, inset 0 0 1px $success_light;
    border-radius: 3px;
  }

  .multiple-select-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .multiple-select-abbr   {
    display: inline-block;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 2px;
    margin-right: 12px;

    color: $success;
    background: white;
    border: 1px solid $success_light;
  }

  .multiple-select-text {
    flex-grow: 1;
    text-align: left;
    font-size: 15px;
  }

  .adm-selector-check-mark-wrapper {
    display: none;
  }

  .multiple-select-check {
    color: $success;
    display: none;
  }

  .adm-selector-item-multiple-active {
    background: $green_bg;
    box-shadow: inset 0 0 1px $success, inset 0 0 1px $success, inset 0 0 1px $success;

    .multiple-select-abbr {
      color: white;
      background: $success;
      border: 1px solid $success;
    }

    .multiple-select-check {
      display: block;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
  }
}