@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height) {
  .product-details-x-grid {
    .grid-title {
      font-size: $h3;
      line-height: calc(0.9*$h3_line_height);
      font-weight: 500;
      margin-bottom: 10px;
    }

    .grid-description {
      font-size: $h5;
      line-height: $h5_line_height;
      color: $secondary_text;
    }

    .x-grid-item {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      align-items: center;
    }
  }

  .product-details-x-grid .x-grid-item-content {
    display: flex;
    border-radius: 5px;
    padding: 25px;
    background: white; //$secondary_bg; //#f9f7f7;
    transition: box-shadow 0.2s ease, transform 0.2s ease;
    border: 1px #eee solid;
    box-shadow: 0 1px 0px #ddd;

    &:hover {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12);
      transform: translateY(-1px);
    }
  }

  .product-details-x-grid .x-grid-item-text {
    width: 70%;
    padding-right: 20px;
  }

  .product-details-x-grid .x-grid-item-image {
    width: 30%;
    margin: 0;
    padding: 0;
    line-height: 0
  }

  .product-details-x-grid .x-grid-item-image img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  .product-details-x-grid.grid-60-40 {

    .x-grid-item-text {
      width: 60%
    }

    .x-grid-item-image {
      width: 40%
    }

  }

}

@media (max-width: $breakpoint_desktop) {}

@media screen and (max-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height );

  .product-details-x-grid .x-grid-item-content {
    flex-direction: column;
    text-align: center;
  }

  .product-details-x-grid .x-grid-item-text {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .product-details-x-grid .x-grid-item-image {
    width: 75%;
    margin: auto;
  }

  .product-details-x-grid.grid-60-40 {

    .x-grid-item-text {
      width: 100%
    }

    .x-grid-item-image {
      width: 75%
    }

  }
}

@media screen and (min-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height );
}