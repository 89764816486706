@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .memberships {
    .mail-link {
      color: $success;
    }

    .membership-title {
      text-align: center;
      padding: 25px 0px 0px;
    }

    .membership-plans-container {
      padding: 25px;
      display: flex;
      justify-content: center;
    }



    .membership-plan {
      margin-bottom: 14px;

      .membership-plan-container {
        background-color: #fff;
        padding: $h3_line_height $h3;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 1px solid lighten($border, 5);
      }

      .membership-plan-container.active {
        border: 2px solid #4caf50;
        background-color: #f0fff0;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      }


      .membership-plan-title {
        font-size: $h2;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .membership-plan-description {
        font-size: $h4;
        line-height: $h4_line_height;
        margin-bottom: 20px;
      }

      .membership-plan-price {
        font-size: $h2;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .membership-plan-price span {
        font-size: 16px;
        font-weight: normal;
      }

      .membership-plan-cta {
        text-align: center;
      }

      .membership-features-container {
        list-style-type: none;
        padding-top: $h3_line_height;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
      }

      .membership-features-container .feature-item {
        padding: 8px 0;
        font-size: $h5;
      }

      .membership-features-container .feature-item .title {
        cursor: pointer;
        font-size: $h5;
      }

      .membership-features-container .feature-item .checkmark {
        color: $success;
        font-size: $h5;
        margin-right: 5px;
        cursor: pointer;
      }

    }

    .join-btn,
    .cancel-btn,
    .restart-btn,
    .learn-more-btn,
    .unavailable-btn {
      font-weight: 600;
      font-size: $h4;
      height: 50px;
      outline: none;
      margin-top: 10px;
      margin-right: 20px;
      width: 100%;
      height: 50px;
    }

    /* Less conspicuous cancel button */
    // .cancel-btn {
    //   background-color: lighten(gray, 20%);
    //   color: darken(gray, 30%);
    //   border: 1px solid lighten(gray, 30%);

    //   &:hover {
    //     background-color: lighten(gray, 30%) !important;
    //     color: darken(gray, 40%) !important;
    //     border: 1px solid lighten(gray, 30%) !important;

    //   }
    // }



    .toggle-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 24px 0;
      gap: 12px;

      .toggle-label {
        font-size: 16px;
        color: #666;
      }

      .billing-toggle {
        min-width: 44px;
      }
    }

  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}