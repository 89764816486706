@import "../../colors.scss";
@import "../../global.scss";

.join-event {
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;

  .account-form {
    margin-top: 30px;
  }

  .join-title {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    line-height: 32px;
  }

  .join-description {
    color: $secondary_text;
    margin-bottom: 10px;

    a {
      color: $success;
    }
  }

  .join-btn {
    width: 100%;
    height: 40px;
    font-weight: 600;
    margin-top: 20px;

    .anticon-arrow-right {
      margin-left: 10px;
    }

    .ant-btn-loading-icon {
      margin-right: 5px;
    }
  }
}