@import '../../colors.scss';
@import '../../global.scss';
@import '../../marketing.scss';

@mixin styling(
  $horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h4,
  $h5,
) {
  .galleri {
    background: $secondary_bg;

    // intro

    .intro-section {
      display: flex;
      padding: 80px 25px;
      //max-width: $width;
      margin: 0 auto;
      background: $secondary_bg;

      .intro-row {
        width: 100%;
      }

      .intro-title {
        font-size: 50px;
        line-height: 55px;
      }

      .intro-text-container {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .highlight-text {
        color: $success;
        font-family: 'EB Garamond';
      }

      .schedule-btn {
        margin-bottom: 10px;
        margin-top: 30px;
      }

      .accepted-insurance {
        background: $success_bg;
        color: $success;
        border-radius: 3px;
        display: inline-block;
        padding: 6px 10px 6px 8px;
        border-radius: 5px;
        font-size: 13px;
        margin-top: 20px;

        svg {
          margin-right: 2px;
        }
      }


      .intro-image-container {
        position: relative;
      }

      .galleri-img {
        width: 150%;
        border-radius: 20px;
      }
    }


    // how it works

    .how-it-works-section {
      padding: 80px 0 80px 10px;
      background: $secondary_bg;

      .how-it-works-options {
        display: flex;
        max-width: 1100px;
        margin: 50px auto 0px;
        text-align: center;
      }

      .home-title {
        text-align: center;
      }

      .card-title {
        font-size: 20px;
        font-weight: bold;
      }

      .card-description {
        font-size: 16px;
        width: 90%;
        margin: 10px auto 0px;
      }

      .card-icon {
        height: auto;
        width: 10rem;
        max-height: 10rem;
      }
    }

    .faq {
      padding: $vertical_padding 0;
      background: white;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling(
    $horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h4: $mobile_h4,
    $h5: $mobile_h5,
  );
}
@media screen and (min-width: 769px) {
  @include styling(
    $horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h4: $desktop_h4,
    $h5: $desktop_h5,
  );
}