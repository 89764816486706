@import '../../colors.scss';

.impersonate {
  position: fixed;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 10px;
  background: $success_bg;
  border: 1px solid rgba(108, 199, 211, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot {
    color: $success;
  }

  .ant-spin-text {
    color: $success;
    margin-top: 10px;
  }
}