@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height) {

    .footer-section {
        background: #f8f9fa;
        padding: 64px 0 32px;
        margin-top: auto;
        border-top: 1px solid #e2e8f0;

        .footer-content {
            display: grid;
            grid-template-columns: 2fr repeat(3, 1fr);
            gap: 48px;
            margin-bottom: 48px;

            @media (max-width: 768px) {
                grid-template-columns: 1fr;
                gap: 32px;
            }

            .company-info {
                .footer-logo {
                    height: 40px;
                    margin-bottom: 16px;
                }

                p {
                    color: #4a5568;
                    margin-bottom: 24px;
                }

                .social-links {
                    display: flex;
                    gap: 16px;

                    a {
                        color: #4a5568;
                        font-size: 20px;
                        transition: color 0.2s ease;

                        &:hover {
                            color: #2d3748;
                        }
                    }
                }


            }

            .footer-column {
                h4 {
                    color: #2d3748;
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 16px;
                    margin-top: 0px;
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        margin-bottom: 12px;

                        a {
                            color: #4a5568;
                            text-decoration: none;
                            transition: color 0.2s ease;

                            &:hover {
                                color: #2d3748;
                            }
                        }
                    }
                }
            }
        }



        .logo-container {
            cursor: pointer;
        }

        .logo {
            width: 26px;
            vertical-align: middle;
            float: left;
            margin-right: 10px;
            margin-top: 5px;
        }

        .logo-name {
            font-size: $h3;
            line-height: $h3_line_height;
            display: inline-block;
            font-weight: 600;
        }

        .web-chat-link {
            color: inherit;
            text-decoration: none;
            padding: 0;
            font-size: inherit;
            background: none;
            border: none;
            cursor: pointer;
            transition: color 0.3s ease;
            margin-left: 3px;
            height: unset;

            &:hover {
                color: $success;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height );


}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height );


}