@import '../../../../colors.scss';

.calendly {
  .calendar-container {
    background: $secondary_bg;
    padding: 20px;
    border-radius: 5px;
  }

  .ant-form-item-control-input {
    display: none;
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}