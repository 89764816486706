@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .flow-progress-affix {

  }

  .flow-progress {
    width: 100%;
    z-index: 2;
    margin-bottom: 0;


    .ant-progress-inner {
      height: 100%;
      border-radius: 0;
      vertical-align: top;
      background-color: $secondary_bg;
      border-bottom: 1px solid $border;
      top: -7px;
    }

    .ant-progress-bg {
      border-radius: 0;
    }
  }

  .flow {
    position: relative;

    .flow-footer {
      position: fixed;
      bottom: 10px;
      right: 10px;
      z-index: 2;
    }

    .flow-nav {
      border-radius: 5px;
      display: inline-block;
    }

    .flow-nav-up,
    .flow-nav-down {
      width: 40px;
      height: 40px;
      border: 0;

      &:disabled,
      [disabled] {
        opacity: 0.5;
        background: $success;
        color: white;
      }
    }

    .flow-nav-up {
      border-radius: 5px 0 0 5px;
      margin-right: 1px;
    }

    .flow-nav-down {
      border-radius: 0 5px 5px 0;
    }

    .flow-contact {
      height: 40px;
      margin-left: 10px;
      font-weight: 600;
      padding: 0 20px;
    }

    .true-price {
      color: $success;
    }

    .original-price {
      color: #ccc;
      text-decoration: line-through;
    }

    .flow-step-dynamic {
      max-width: 600px;
      margin: 100px auto;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
    }

    .flow-step-header {
      position: relative;
      margin-bottom: 25px;
    }

    .flow-step-count {
      position: absolute;
      top: 4px;
      left: -45px;
    }

    .flow-step-index {
      margin-right: 5px;
      font-size: 12px;
      font-weight: 600;
    }

    .flow-step-arrow {
      margin-right: 10px;
      font-size: 9px;
    }

    .flow-step-title {
      margin: 0 0 10px 0;
      font-size: $h4; //19px;
      line-height: $h4_line_height; //26px;
      font-weight: 600;
    }

    .flow-step-description {
      font-size: $h5; //14px;
      line-height: $h5_line_height;
      color: $secondary_text;
    }

    .flow-step-description a {
      color: $success;
      text-decoration: underline;
    }

    .flow-step-body a {
      color: $success;
      text-decoration: underline;
    }
  }

  .flow-step-footer {
    margin-top: 30px;

    .flow-step-submit {
      font-weight: 600;
      padding: 0 15px;
      height: 40px;
      font-size: 16px;

      .anticon {
        margin-left: 10px;
        margin-right: -5px;
      }
    }

    .enter-prompt {
      display: inline-flex;
      align-items: center;
    }

    .enter-prompt-text {
      padding-right: 5px;
      font-size: 12px;
      margin-left: 20px;
    }

    .enter-prompt-icon {
      font-size: 12px;
      margin-top: -4px;
    }
  }

}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}