@import '../../colors.scss';

$width: 700px;

.launch-total-exposure {
  width: $width;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 20px 60px 20px;

  .launch-title {
    font-size: 43px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    width: 100%;
    margin: 0 auto;
    color: $primary_text;
    max-width: $width;
  }

  .launch-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 20px 0 25px 0;
    color: $primary_text;
    width: 100%;
    max-width: $width;
  }

  .dob-field {
    margin-bottom: 10px;
  }

  .chat-link {
    color: $success !important;
  }

  .risk-calculator-button {
    height: 45px;
    max-width: 300px;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    .risk-calculator-button {
      width: 100%;
      max-width: 100%;
    }
  }
}

