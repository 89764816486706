@import '../../colors.scss';

.text-05 {
  font-size: 38px;
  line-height: 44px;
}

.text-04 {
  font-size: 24px;
  line-height: 30px;
}

.text-03 {
  font-size: 16px;
  line-height: 23px;
}

.text-02 {
  font-size: 14px;
}

.text-01 {
  font-size: 12px;
  line-height: 16px;
}

.biomarker-layover {
  .ant-popover-inner {
    padding: 0;
  }
}

.biomarker-popover {
  padding: 0;
  
  .biomarker-title {
    @extend .text-04;
    font-weight: 600;
    padding: 20px 0 0 20px;
    margin-bottom: 5px;
  }
  
  .biomarker-subtitle {
    @extend .text-03;
    color: #666666;
    margin: 0 20px 20px 20px;
  }

  .ant-table-cell {
    padding: 3px 10px !important;
  }

  .biomarker-name {
    @extend .text-03;
    font-weight: 600;
    padding-top: 14px;
    padding-left: 10px;
  }

  .biomarker-description {
    @extend .text-02;
    margin-bottom: 0;
  }
}