@import '../../colors.scss';

.testosterone-projection {
  height: calc(100vh - 63px);
  position: relative;

  .testosterone-chart {
    min-height: 600px;
    height: calc(100vh - 63px);
    padding: 40px 20px 40px 15px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .analysis-col {
    background: $secondary_bg;
  }

  .ant-row {
    height: 100%;
  }

  .analysis-content {
    padding: 25px 30px;
    position: relative;
  }

  .projection-patient-select {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    z-index: 10;

    .ant-select-selector {
      border-radius: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .ant-select-selection-search {
      inset-inline-start: 30px;
      inset-inline-end: 30px;
    }

    .ant-select-selection-item {
      inset-inline-start: 15px;
      inset-inline-end: 15px;
    }

    .ant-select-selection-placeholder {
      padding-left: 15px;
    }
  }

  .projection-heading {
    font-size: 43px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    width: 100%;
    margin: 0 auto;
    color: $primary_text;
    max-width: 100%;
  }

  .projection-subheading {
    font-size: 24px;
    line-height: 1.6;
    margin: 20px auto 0 auto;
    color: $primary_text;
    max-width: 100%;
  }

  .projection-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-top: 50px;
    margin-bottom: 15px;
    color: $primary_text;
    max-width: 100%;
  }

  .projection-description {
    font-size: 18px;
    line-height: 1.6;
    margin: 20px 0 25px 0;
    color: $primary_text;
    max-width: 100%;
  }

  .info-icon {
    color: $primary_text;
    transform: scale(0.7);
    margin-left: 3px;
    cursor: pointer;
  }

  .ant-progress.ant-progress-status-success .ant-progress-bg {
    background: $success;
  }

  .ant-progress.ant-progress-status-warning .ant-progress-bg {
    background: $warning;
  }

  .ant-progress.ant-progress-status-exception .ant-progress-bg {
    background: $error;
  }

  .ant-progress-text-end {
    font-size: 13px;
  }

  .anticon-check-circle {
    color: $success;
  }
  .anticon-close-circle {
    color: $error;
  }

  .retest-kit-container {
    margin-top: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 2s ease, transform 2s ease;

    &:active {
      opacity: 0.9;
    }

    &.show {
      cursor: pointer;
      opacity: 1;
      transform: translateY(0);
    }
  }

  .retest-kit-img {
    width: 100%;
    border-radius: 5px;
  }

  .retest-cta-btn {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    height: 50px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .login-link {
    color: $success;
    font-size: 18px;
    cursor: pointer;

    &:active {
      opacity: 0.8;
    }
  }

  .login-suffix {
    font-size: 18px;
  }
}

@media screen and (max-width: 992px) {
  .testosterone-projection {
    height: calc(100vh - 52px);
  }

  .testosterone-chart {
    height: 400px !important;
    min-height: 400px !important;
  }
}

.projection-patient-option {
  .ant-select-item-option-content {
    padding-left: 15px !important;
  }
}