@import '../../../../colors.scss';

.facility-select {

  .load-more-container {
    display: flex;
    justify-content: left;
    margin-top: 16px;
  }

  .load-more-button {
    /* You can adjust this to your desired primary color */
    // color: white;
    border-radius: 4px;
    padding: 8px 16px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #40a9ff;
      /* Hover state */
    }
  }

  .adm-space-item {
    display: block;
    width: 100%;
    padding-bottom: 0px !important;
  }

  .adm-selector-item-disabled {
    opacity: 0.7;
  }

  .adm-selector-item {
    max-width: 470px;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: inset 0 0 1px $success_light, inset 0 0 1px $success_light, inset 0 0 1px $success_light;
    border-radius: 3px;
    overflow: visible;
  }

  .product-select-label {
    text-align: left;
    position: relative;
  }

  .col-1 {
    width: 45px;
  }

  .col-2 {
    width: calc(100% - 45px);
  }

  .product-select-abbr {
    display: inline-block;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 2px;
    color: $success;
    background: white;
    border: 1px solid $success_light;
  }

  .option-title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
    margin-right: 12px;
    padding-right: 35px;
    // line-height: 22px;
  }

  .option-description {
    color: $secondary_text;
    font-size: 14px;

  }

  .option-price {
    position: absolute;
    color: $success;
    font-size: 18px;
    font-weight: 600;
    right: 0;
    top: 0;
  }

  .option-tag {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    background: white;
    border: 1px solid $success_light;
    border-radius: 3px;
    color: $success;
    padding: 3px 12px;
    position: absolute;
    right: 0;
    bottom: -12px;
  }

  .adm-selector-check-mark-wrapper {
    display: none;
  }

  .product-select-check {
    color: $success;
    display: none;
    margin-left: 15px;
  }

  .adm-selector-item-active,
  .adm-selector-item-multiple-active {
    background: $green_bg;
    box-shadow: inset 0 0 1px $success, inset 0 0 1px $success, inset 0 0 1px $success;

    .product-select-abbr {
      color: white;
      background: $success;
      border: 1px solid $success;
    }

    .product-select-check {
      display: inline-block;
    }

    .option-tag {
      color: white;
      background: $success;
      border: 1px solid $success;
    }
  }

  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}