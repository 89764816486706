.incomplete-tests {
  .incomplete-tests-label {
    color: white;
    font-size: 13px;
    font-weight: 600;
  }
  
  ul {
    margin: 0;
    padding-left: 15px;
  }

  .incomplete-test-name {
    font-size: 13px;
  }
}