.note-form {
    .date-fields-container {
        background: #f5f5f5;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        margin-bottom: 16px;

        .ant-form-item {
            margin-bottom: 0;
        }
    }


    .date-fields-note {
        font-size: 12px;
        color: #666;
    }
}