@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .test-modal {
    .test-tagline {
      color: $secondary_text;
      font-size: $h5;
      line-height: $h5_line_height;
    }

    .ant-modal-content,
    .ant-modal-header {
      background: white;
    }

    .ant-modal-title {
      color: $primary_text;
      margin-bottom: 0;
    }

    .apo-e-tag {
      background: $secondary_bg;
      display: inline-block;
      padding: 8px 15px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 600;
      margin: 15px 0;
    }

    .optimal-apo-e {
      background: $success_bg;
      color: $success;
      box-shadow: 0 0 1px $success;
    }

    .moderate-apo-e {
      background: $warning_bg;
      color: $warning;
      box-shadow: 0 0 1px $warning;
    }

    .abnormal-apo-e,
    .high-apo-e {
      background: $error_bg;
      color: $error;
      box-shadow: 0 0 1px $error;
    }

    .test-details {
      color: $secondary_text;
      font-size: $h5;
      line-height: $h5_line_height;
    }

    .test-details strong,
    .test-details b {
      color: $primary_text;
      font-weight: 500;
    }

    .test-details table {
      border-spacing: 0;
    }

    .test-details td {
      border: 1px solid gray;
      padding: 0px 10px;
      max-width: 150px;
    }

    .ant-modal-close-icon {
      color: $secondary_text;
    }

    .test-chart-container {
      margin-top: 20px;
    }

    ol,
    ul {
      li {
        margin-bottom: 15px;
      }

      li:last-child {
        margin-bottom: 0;
      }
    }

    .test-references {
      border-top: 1px solid $border;
      margin-top: 25px;
      padding-top: 15px;

      .title {
        font-weight: 600;
        padding: 10px 0px;
      }

      ol {
        padding-left: 20px;
        margin: 0;
      }

      li {
        margin-bottom: 10px;
        margin-inline: 0;
        padding-inline: 0;
        font-size: 14px;
        color: $secondary_text;
      }

      a {
        color: $secondary_text
      }

      a:active {
        color: darken($secondary_text, 15);
      }
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}