@import '../../colors.scss';

.heart-health-events {
  .timeline-header {
    padding: 10px 20px;
    border-bottom: 1px solid $border;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .timeline-header-title {
      font-size: 16px;
      color: $primary_text;
      font-weight: 600;
    }

    .timeline-header-count {
      margin-left: 7px;
      font-size: 16px;
      color: $secondary_text;
      font-weight: 600;
    }

    .event-type-filter {
      width: 230px;
      margin-right: 10px;
    }

    .patient-filter {
      width: 150px;
    }
  }

  .timeline-container {
    padding: 40px 20px;

    .timeline {
      margin-top: 10px;
    }
  }

  .event-bubble {
    padding: 15px;
    background-color: $secondary_bg;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .author-avatar {
    margin-left: auto;
  }

  .event-footer {
    display: flex;
    align-items: center;
    flex-direction: row;

    .event-footer-bullet {
      margin: 0 10px;
    }
  
    .event-action,
    .patient-link,
    .tracking-link {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  
    .event-timestamp,
    .patient-link,
    .tracking-link,
    .event-action,
    .event-footer-bullet {
      font-size: 12px;
      color: $secondary_text;
      flex-shrink: 0;
      width: auto;
    }
  }

  .prescription-details {
    display: flex;
    align-items: center;
    background: $secondary_bg;

    .prescription-img {
      width: 30px;
      border-radius: 5px;
      margin-right: 15px;
    }
  }

  .kit-details {
    display: flex;
    align-items: center;
    background: $secondary_bg;

    .kit-img {
      width: 60px;
      border-radius: 5px;
      margin-right: 15px;
    }
  }

  .note {
    .event-bubble {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    ul {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .reminder {
    .reminder-checkbox {
      margin-right: 10px;
    }

    .event-bubble {
      display: flex;
      align-items: space-between;
      align-items: flex-start;
    }
  }

  .loading-events {
    font-size: 15px;
    margin-top: 20px;
    color: $secondary_text;

    .ant-spin {
      margin-right: 7px;
    }
  }
}