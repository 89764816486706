@import "../../colors.scss";
@import "../../global.scss";
@import "../../marketing.scss";

$max_width: 850px;

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .biomarker-panel {


    .subscribe-section {
      background: white;
    }

    .panel-header {
      padding: 50px 10px 70px 10px;
      text-align: center;
      max-width: $max_width;
      margin: 0 auto;
    }

    .panel-title {
      color: $primary_text;
      font-size: $h2;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .panel-subtitle {
      color: $primary_text;
      font-size: $h4;
      line-height: $h4_line_height;
      margin-bottom: 40px;
    }

    .category-list .category-item:nth-child(2n+1) {
      background: $secondary_bg;
    }

    .category-item {
      padding: 40px 10px;
      border-top: 1px solid $border;
    }

    .category-item-content {
      max-width: $max_width;
      margin: 0 auto;
    }

    .category-title {
      color: $success;
      font-size: 28px;
      font-family: 'EB Garamond';
      margin-bottom: 10px;
    }

    .category-test {
      font-size: 14px;
      color: $primary_text;
      margin-top: 10px;
      margin-bottom: 5px;
      display: inline-block;
      cursor: pointer;
    }
  }
}


@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}