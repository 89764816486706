@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .test-item-list {
    background: #FBFBFB;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    padding: 10px 0 0 10px;

    .test-item {
      background: white;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      margin-right: 10px;
      display: inline-block;
      margin-bottom: 10px;
      padding: 25px 15px 30px 15px;
      cursor: pointer;

      &:active {
        box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
      }

      .test-name {
        color: #161718;
        font-size: $h5;
        font-weight: 500;
      }

      .test-details {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: #7B7E86;
        font-size: $h5; //14px;
        line-height: $h5_line_height; //26px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .test-details p {
        margin: 0;
      }

      .risk-tag {
        text-transform: capitalize;
        border-radius: 5px;
        // font-weight: 600;
      }

      .abnormal-risk-tag,
      .high-risk-tag {
        color: $error;
        box-shadow: 0 0 1px $error;
        background: $error_bg;
      }

      .moderate-risk-tag {
        background: $warning_bg;
        color: $warning;
        box-shadow: 0 0 1px $warning;
      }

      .optimal-risk-tag {
        background: $success_bg;
        color: $success;
        box-shadow: 0 0 1px $success;
      }
    }

    .show-all-button {
      width: calc(100% - 10px);
      margin-bottom: 10px;
      font-weight: 700;
      font-size: $h5;
      line-height: $h5_line_height;
      height: 50px;
      background: $success;

      &:hover {
        background: $success;
        opacity: 0.85;
      }

      &:active {
        background: darken($success, 15);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}