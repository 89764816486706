@import '../../colors.scss';

.continue-button {
  position: relative;
  padding: 0 10px 0 25px;
  height: 44px;
  font-weight: 600;

  .continue-button-text {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: white;
    padding-right: 25px;
    padding-top: 10px;
    height: 100%;
    margin-right: 30px;
    font-size: 14px;
  }

  .arrow-icon,
  .loading-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 0;
    font-size: 12px;
    right: 14px;
  }

  .loading-icon {
    visibility: hidden;
  }

  &.ant-btn-loading {
    .arrow-icon {
      display: none;
    }

    .loading-icon {
      visibility: visible;
    }
  }

  .ant-btn-loading-icon {
    display: none;
  }
}