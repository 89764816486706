@import '../../../../colors.scss';

.upsell {
  .point-item {
    margin: 5px 0;
  }
  
  .point-col-1 {
    width: 25px;
  }
  
  .point-col-2 {
    width: calc(100% - 25px);
  }
  
  .point-item .anticon {
    color: $success;
    margin-right: 10px;
    font-size: 14px;
  }
  
  .point-item-text {
    font-size: 14px;
  }
  
  .point-item-text a {
    color: $success;
    text-decoration: underline;
  }

  .paid-status {
    background: $green_bg;
    padding: 15px;
    border-radius: 5px;
    margin-top: 25px;
    color: $success;
    font-size: 16px;
    font-weight: 600;

    .anticon {
      margin-right: 5px;
      opacity: 0.6;
    }
  }

  .activate-btn,
  .skip-btn {
    margin-top: 30px;
    margin-right: 10px;
    font-weight: 600;
    padding: 0 15px;
    height: 40px;
    font-size: 16px;
  
    .anticon {
      margin-left: 10px;
      margin-right: -5px;
    }
  }
}