@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height) {
    .research-card {
        height: 100%;
        position: relative;
        // padding-bottom: 40px;

        .research-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .research-title {
            font-size: $h3;
            margin-bottom: 8px;
            margin-top: 10px;
            font-weight: 500;
            line-height: $h3_line_height;
        }

        .research-description {
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 40px;
            flex-grow: 1;
            font-size: $h5;
            display: -webkit-box;
            // -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;

        }

        .research-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .read-time {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }

        &:hover {
            .research-title {
                color: $success
            }
        }
    }
}




@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height );


}