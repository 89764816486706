@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {


    .producttype-box {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        padding: 16px;
        border-radius: $border_radius;
        border: 1px solid #e8e8e8;
        // background-color: $success_bg;
        font-size: $h4;



        .product-box-header {
            color: #666;
            font-size: $h4;
            margin-bottom: 8px;
        }

        .product-content {
            .product-title {
                font-weight: 600;
                font-size: $h4;
                margin-bottom: 4px;
            }

            .product-subtitle {
                color: #666;
                font-size: $h4;
                margin-bottom: 8px;
            }

            .product-directions {
                font-size: $h4;
                margin-bottom: 8px;
            }

            .product-description {
                font-size: $h5;
                color: #666;
                margin-bottom: 12px;
            }

            .product-buy {
                margin-top: 8px;
            }
        }
    }
}



@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}