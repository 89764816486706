@import '../../colors.scss';

.flow-progress-affix {
  height: 8px;
}

.flow-progress {
  left: 0;
  width: 100%;
  z-index: 2;
  margin-bottom: 0;

  .ant-progress-inner {
    height: 100%;
    border-radius: 0;
    vertical-align: top;
    background-color: $secondary_bg;
    border-bottom: 1px solid $border;
  }

  .ant-progress-bg {
    border-radius: 0;
  }
}

.flow {
  position: relative;

  .flow-footer {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }

  .flow-nav {
    border-radius: 5px;
    display: inline-block;
  }

  .flow-nav-up,
  .flow-nav-down {
    width: 40px;
    height: 40px;
    border: 0;

    &:disabled,
    [disabled] {
      opacity: 0.5;
      background: $success;
      color: white;
    }
  }

  .flow-nav-up {
    border-radius: 5px 0 0 5px;
    margin-right: 1px;
  }

  .flow-nav-down {
    border-radius: 0 5px 5px 0;
  }

  .flow-contact {
    height: 40px;
    margin-left: 10px;
    font-weight: 600;
    padding: 0 20px;
  }

  .true-price {
    color: $success;
  }

  .original-price {
    color: #ccc;
    text-decoration: line-through;
  }

  .product-name {
    color: $success;
  }
}

.question-btn {
  font-weight: 600;
  padding: 0 35px;
  height: 40px;
  font-size: 16px;

  &:hover {
    border: 1px solid lighten($success, 15);
    color: lighten($success, 15);
  }

  &:active {
    border: 1px solid $success;
    color: $success;
  }
}

.confirm-step {
  .next-steps-title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .question-btn {
    font-weight: 600;
    padding: 0 35px;
    height: 40px;
    font-size: 16px;

    &:hover {
      border: 1px solid lighten($success, 15);
      color: lighten($success, 15);
    }

    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  .instruction-list {
    background: $secondary_bg;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }

  .instruction-item {
    padding: 12px 0 5px 0;
  }

  .instruction-link {
    color: $success;
    text-decoration: underline;

    &:hover {
      color: lighten($success, 15);
      text-decoration: underline;
    }

    &:active {
      color: darken($success, 10);
      text-decoration: underline;
    }
  }

  .instruction-index {
    color: $success;
    background: $green_bg;
    font-size: 13px;
    padding: 5px 0;
    width: 60px;
    display: block;
    text-align: center;
    border-radius: 3px;
    margin-top: 5px;
  }

  .ant-col .section-item:not(:first-child) {
    margin-top: 20px;
  }

  .section-title {
    font-weight: 600;
    font-size: 15px;
  }

  .section-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.prescription-approval {
  .question-btn {
    font-weight: 600;
    padding: 0 35px;
    height: 40px;
    font-size: 16px;

    &:hover {
      border: 1px solid lighten($success, 15);
      color: lighten($success, 15);
    }

    &:active {
      border: 1px solid $success;
      color: $success;
    }
  }

  .instruction-list {
    background: $secondary_bg;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
  }

  .instruction-item {
    padding: 12px 0 5px 0;
  }

  .instruction-link {
    color: $success;
    text-decoration: underline;

    &:hover {
      color: lighten($success, 15);
      text-decoration: underline;
    }

    &:active {
      color: darken($success, 10);
      text-decoration: underline;
    }
  }

  .instruction-index {
    color: $success;
    background: $green_bg;
    font-size: 13px;
    padding: 5px 0;
    width: 60px;
    display: block;
    text-align: center;
    border-radius: 3px;
    margin-top: 5px;
  }

  .ant-col .section-item:not(:first-child) {
    margin-top: 20px;
  }

  .section-title {
    font-weight: 600;
    font-size: 15px;
  }

  .section-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
}