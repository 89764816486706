@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .support-sidebar {


    .support-avatars {
      margin-bottom: 24px;

      .support-avatar {
        // border: 2px solid #fff;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:not(:first-child) {
          margin-left: -8px;
        }

        img {
          border-radius: 50%;
        }
      }
    }

    .support-card {
      margin-bottom: 16px;
      background: #f8f9fa;

      h5.ant-typography {
        margin: 0 0 8px 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .support-description {
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 8px;

      .secondary-link {
        color: #1890ff;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .members-only-message {
      color: #1890ff;
      cursor: pointer;
      margin-bottom: 16px;

      &:hover {
        text-decoration: underline;
      }
    }

    .blur-effect {
      filter: blur(3px);
      pointer-events: none;
    }

    .card-description {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.45);
      font-weight: normal;
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}