@import '../../colors.scss';
@import '../../global.scss';

.product-types {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .product-type-cost {
    color: $success;
    font-size: 14px;
  }
}