$success_light: rgb(12, 163, 127, 0.5);
$warning: rgb(245, 191, 71);
$error: rgb(237, 110, 127);
$success: #0ca37f;

$success_bg: #0ca37f1a;
$success_bg_transparent: rgba(12, 163, 127, 0.102);
$warning_bg: #f5bf4717;
$warning_bg_transparent: rgba(245, 191, 71, 0.09);
$error_bg: #FDF0F2;
$error_bg_transparent: rgba(237, 110, 127, 0.15);

$primary_bg: #E6F5ED;
$secondary_bg: #F4F4F4;

$primary_text: #161718;
$secondary_text: rgba(0, 0, 0, 0.6);

$primary_button: #1aabb8;
$primary_button_text: #FFFFFF;

$border: rgb(230, 230, 230);

$green_bg: #dcf5e7;
$blue: #1890ff;

$mono_bg: #f5f5f5;
$mono_text: #666;
$mono_border: darken($mono_bg, 20%);

:export {
  green_bg: $green_bg;
  success: $success;
  secondary_bg: $secondary_bg;
  warning: $warning;
  success_bg: $success_bg;
  warning_bg: $warning_bg;
  error: $error;
  error_bg: $error_bg;
  primary_bg: $primary_bg;
  border: $border;
  primary_text: $primary_text;
  secondary_text: $secondary_text;
  primary_button_text: $primary_button_text;
  success_bg_transparent: $success_bg_transparent;
  warning_bg_transparent: $warning_bg_transparent;
  error_bg_transparent: $error_bg_transparent;
  mono_bg: $mono_bg;
  mono_text: $mono_text;
  mono_border: $mono_border;
}