.appointment-calendar {
  .calendar-component {
    margin-top: 30px;
    padding: 0px 10px;
  }

  .event-product-name {
    display: block;
    margin-left: 5px;
    line-height: 12px;
    font-size: 10px;
  }



}