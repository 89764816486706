@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .product-details-timeline {
    .ant-collapse .ant-collapse-header {
      padding: 0 !important;
    }

    .section-title {
      font-size: $h2;
      line-height: $h2_line_height;
    }

    .section-subtitle {
      font-size: $h4;
      line-height: $h4_line_height;
    }

    .section-title,
    .section-subtitle {
      text-align: left;
    }

    .section-label {
      text-align: left;
      margin: 0 0 10px;
    }

    .timeline {
      margin-top: $h4;
    }

    .timeline-header {
      font-weight: 700;
      font-size: $h4;
      line-height: $h4_line_height;
    }

    .timeline-title {

      .ant-collapse-header-text {
        font-weight: 500;
        margin-top: -1px;
        display: inline-block;
        font-size: $h4;
      }

      .ant-collapse-header .ant-collapse-expand-icon {
        height: unset;
        margin-top: 0;
      }
    }

    .ant-timeline .ant-timeline-item-head-green {
      border-color: $success !important;
    }

    .ant-timeline .ant-timeline-item:last-child {
      padding-bottom: 0px;
    }

    .ant-timeline .ant-timeline-item-content {
      margin-top: 0px;
    }

    .timeline-description {
      color: $secondary_text;
      font-weight: 400;
      font-size: $h4;
      line-height: $h4_line_height;
      margin: 10px 0px 0px
    }

    .timeline-image {
      width: 250px;
      margin: 15px 0 0;
    }

    .price-tag {
      font-weight: bold;
    }

    .center-svg {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      /* or any desired height */
    }



  }
}



@media screen and (max-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

}

@media screen and (min-width: $breakpoint_tablet) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}