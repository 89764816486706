@import '../../colors.scss';
@import '../../global.scss';

.patients {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  a {
    color: $success;
  }

  .no-value {
    color: $secondary_text;
  }

  .page-header-actions {
    display: flex;
  }

  .ant-input-clear-icon {
    margin-top: 2px;
  }

  .ant-input-clear-icon svg {
    margin-right: 0;
  }
}

.downloading-text {
  color: #888;
}