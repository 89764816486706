@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {

  .page-header {
    border-bottom: 1px solid $border;
    position: relative;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // background-color: white;

    .page-header-content {
      padding: 5px 0;
    }

    .page-header-title {
      font-size: $h4;
      line-height: $h4_line_height;
      color: $primary_text;
      font-weight: 600;
    }

    .filtered-count {
      color: $secondary_text;
      font-size: 16px;
      margin-left: 5px;
    }

    .page-header-description {
      display: block;
      font-size: calc(0.9* $h5);
      line-height: calc(0.9* $h5_line_height);
      color: $secondary_text;
    }

    button {
      font-weight: 500;
      font-size: calc(0.9*$h5);
      line-height: $h5_line_height;
      height: 34px;
      // padding: 0 15px;
    }
  }
}



@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );

  .page-header {
    padding: 10px 15px;
  }
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}