@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {
    .gameplan-table-of-contents {
        min-height: 100vh;

        .toc-container {
            background: white;
            border-right: 1px solid #f0f0f0;
            height: 100vh;

            .toc-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 16px 12px;
                border-bottom: 1px solid #f0f0f0;
                margin-bottom: 0;

                .toc-title {
                    font-size: 16px;
                    font-weight: 600;
                    color: #262626;
                }

                .ant-btn {
                    color: #8c8c8c;

                    &:hover {
                        color: $success;
                        background: rgba(0, 171, 85, 0.08);
                    }
                }
            }

            .view-toggle {
                padding: 16px 10px 0;

                .ant-radio-group {
                    border: 1px solid #f0f0f0;
                    border-radius: 8px;

                    .ant-radio-button-wrapper {
                        height: 36px;
                        line-height: 34px;
                        border: none;
                        color: #595959;

                        &:first-child {
                            border-radius: 8px 0 0 8px;
                        }

                        &:last-child {
                            border-radius: 0 8px 8px 0;
                        }

                        &.ant-radio-button-wrapper-checked {
                            background: $success;
                            color: white;
                            box-shadow: none;
                        }
                    }
                }
            }

            .gameplan-menu {
                margin-top: 8px;
                border-right: none;

                .ant-menu-item {
                    height: 44px;
                    line-height: 44px;
                    padding: 0 16px !important;
                    margin: 4px 8px;
                    border-radius: 8px 0px 0px 8px;
                    color: #595959;
                    font-size: 14px;

                    &:hover {
                        color: $success;
                        background: rgba(0, 171, 85, 0.08);
                    }

                    &.ant-menu-item-selected {
                        background: rgba(0, 171, 85, 0.08);
                        color: $success;
                        font-weight: 500;

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .update-button-container {
            margin: 16px;
            display: block;
        }

        .update-button {
            display: block;
            margin: 0 auto;
            width: 100% !important;

            .anticon {
                margin-right: 8px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );
}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );
}