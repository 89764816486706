@import '../../colors.scss';

.loading {
  text-align: center;
  padding: 100px 0;

  .loading-text {
    color: $secondary_text;
  }
}

.single-results-table {

  .incomplete-test-name {
    margin: 0;
  }

  .incoming-tests-message {

    // display: flex;
    // align-items: center;
    padding: 10px;
    background: #e6f4ff;
    text-align: center;
    cursor: pointer;

    ul {
      margin: 0;
    }
  }

  .incoming-tests-message .anticon {
    margin-right: 5px;
  }

  .test-name-container {
    display: flex;
    align-items: center;
    line-height: 1;
  }

  .incomplete-indicator {
    color: darkgray;
    margin-right: 5px;
    font-size: 12px;
    width: 12px;

    &.next-to-result {
      margin-left: 5px;
      margin-right: 0;

    }
  }


  .ant-table-row.is-category .ant-table-cell {
    color: $secondary_text;
    background: $secondary_bg !important;
    padding-top: 50px !important;
    font-weight: 500;
  }

  .single-results-table .ant-table-row.is-category {
    color: rgba(0, 0, 0, 0.6);
    background: #F4F4F4 !important;
    font-weight: 500;
    position: relative;
    /* Make the row relative for positioning */
  }

  .single-results-table .ant-table-row.is-category::before {
    content: "";
    display: block;
    height: 20px;
    /* Adjust the padding height here */
    position: absolute;
    top: -20px;
    /* Move the pseudo-element above the row */
    left: 0;
    right: 0;
    background: #F4F4F4;
    /* Ensure the pseudo-element matches the background */
  }

  .primary-table {
    width: 100%;
    border-collapse: collapse;
    // margin-top: 20px;
  }

  .primary-table th,
  .primary-table td {
    padding: 12px 16px;
    /* Adds padding for a spacious look */
    text-align: left;
    vertical-align: middle;
  }

  .primary-table th {
    background-color: #f8f8f8;
    font-weight: 600;

  }

  .primary-table td {
    border-bottom: 1px solid #e0e0e0;
  }

  .primary-table tbody tr:hover {
    background-color: #f4f4f4;
    cursor: pointer;
    /* Light hover effect for rows */
  }



  .percent-complete-container {
    position: absolute;
    top: -35px;
    left: -62%; //-50px;
    width: calc(100% + 80px);
    text-align: center;
  }

  .percent-complete-position {
    position: relative;
  }

  .percent-complete {
    padding: 3px 7px;
    border-radius: 3px;
    color: $secondary_text;
    border: 1px solid darken($secondary_bg, 5);
    font-size: 10px;
    background: rgba(255, 255, 255, 0.6);
  }

  .percent-complete-icon {
    margin-right: 5px;
  }

  .all-complete-icon {
    color: $success;
  }

  .centered-col {
    text-align: center;
  }

  .current-risk-col {
    text-align: right;
    padding-right: 1px !important;
  }

  .absolute-category-row,
  .score-header-row {
    margin-bottom: 20px !important;
  }

  .absolute-category-row .ant-table-cell,
  .score-header-row .ant-table-cell {
    color: $secondary_text;
    background: $secondary_bg !important;
    padding-top: 50px !important;
    font-weight: 500;
  }

  .test-row,
  .score-value-row {
    cursor: pointer;
  }

  .aggregate-apoe {
    position: absolute;
    top: 13px;
    left: 15px;
    text-align: center;
    z-index: 1;
  }

  .risk-tag {
    padding: 0 5px;
    min-width: 60px;
    text-align: center;
    border-radius: 3px;
    display: inline-block;
  }

  .optimal-risk {
    color: $success;
    background: $success_bg;
    box-shadow: 0 0 1px $success;
  }

  .abnormal-risk,
  .high-risk {
    color: $error;
    background: $error_bg;
    box-shadow: 0 0 1px $error;
  }

  .moderate-risk {
    color: $warning;
    background: $warning_bg;
    box-shadow: 0 0 1px $warning;
  }

  .unknown-risk {
    color: $secondary_text;
    background: rgba(178, 176, 176, 0.05);
    box-shadow: 0 0 1px $secondary_text;
  }

  .pending-risk {
    color: $secondary_text;
    background: rgba(255, 255, 255, 0.05);
  }

  .outdated-risk {
    color: $secondary_text;
    background: rgba(255, 255, 255, 0.05);
  }

  /* Unit and Date Column Styling */
  .primary-table td.unit,
  .primary-table td.date {
    text-align: right;
    color: #888;
  }

  /* Goal Column Styling */
  .primary-table td.goal {
    font-weight: 600;
    color: #333;
  }

  /* Test Name Column Styling */
  .primary-table td.testName {
    font-weight: 500;
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }

  .result-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the content horizontally */
    position: relative;
    /* Ensure trend icon is positioned properly */
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    /* Ensure the value occupies equal space */
    text-align: center;
    padding: 4px 8px;
  }


  .risk-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .trend-icon {
    margin-left: 3px;
    font-size: 14px;
    font-weight: 600;
    visibility: visible;
    width: 12px;


    &.trend-improving {
      color: $success;
    }

    &.trend-worsening {
      color: $error;
    }

  }

  .no-trend-icon {
    visibility: hidden;
    /* Hide the icon if not applicable */
  }
}