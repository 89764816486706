@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .flow-step {
    width: 100%;
    height: 100%;

    .flow-step-dynamic {
      max-width: 600px;
      margin: 75px auto;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 20px;
    }



    .flow-step-header {
      position: relative;
      margin-bottom: 25px;
    }

    .flow-step-count {
      position: absolute;
      top: 4px;
      left: -45px;
    }

    .flow-step-index {
      margin-right: 5px;
      font-size: 12px;
      font-weight: 600;
    }

    .flow-step-arrow {
      margin-right: 10px;
      font-size: 9px;
    }

    .flow-step-title {
      margin: 0 0 10px 0;
      font-size: $h4; //19px;
      line-height: $h4_line_height; //26px;
      font-weight: 600;
    }

    .flow-step-description {
      font-size: $h5; //14px;
      line-height: $h5_line_height;
      color: $secondary_text;
    }

    .flow-step-description a {
      color: $success;
      text-decoration: underline;
    }

    .flow-step-body a {
      color: $success;
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}