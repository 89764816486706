@import '../../colors.scss';
@import '../../global.scss';

@mixin styling($horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .fixed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white !important;
    z-index: 10000;

    // Adjust the height to make it less tall
    height: 70px;

    // Stronger shadow effect to differentiate from content
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); // Slightly increase shadow

    // Border at the top of the footer
    border-top: 1px solid $border;

    .adm-tab-bar-wrap {
      height: 100%;
    }

    .adm-tab-bar {
      width: 100%; // Ensure the TabBar spans the full width of the footer
      height: 100%; // Full height of the footer
      display: flex;
      justify-content: space-between; // Evenly distribute items across the width
      align-items: center;
    }

    .adm-tab-bar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%; // Vertically center the items
    }

    .adm-tab-bar-item-icon {
      margin-bottom: 4px; // Space between icon and text
    }

    .adm-tab-bar-item-title {
      font-size: 12px; // Adjust the font size as needed
    }

    // Active tab styling
    .adm-tab-bar-item-active {
      color: $success !important // Set active tab color
    }

    .tab-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }


    .tab-title {
      margin-top: 4px;
      font-size: 12px;
    }

  }

  // Health Menu Styling
  .health-menu,
  .orders-menu,
  .care-menu {
    position: fixed;
    bottom: 70px; // Above the footer
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    z-index: 99999;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: center;
      font-size: 16px;

      li {
        padding: 12px 0;
        border-bottom: 1px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }


}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}