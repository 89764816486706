@import "../../../colors.scss";
@import "../../../global.scss";

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
) {



    .feature-post {
        display: flex;
        gap: 2rem;
        // padding: 2rem 0;

        &-content {
            position: relative;
            display: flex;
            gap: 2rem;
            align-items: flex-start;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            &--no-image {
                .feature-post-text {
                    flex: 1;
                    max-width: 100%;
                }
            }
        }

        &-text {
            flex: 1;
            max-width: 50%;

            .no-image & {
                max-width: 100%;
            }
        }

        &-img {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-top: 3rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: transform 0.3s ease;

                // &:hover {
                //     transform: scale(1.02);
                // }
            }

            @media (max-width: 768px) {
                order: -1; // Makes image appear first on mobile
            }
        }

        .featured-label {
            color: $secondary_text;
            position: relative;
            display: inline-block;
            padding-left: 2rem;
            font-size: 0.9rem;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 0.5rem;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 1.5rem;
                height: 1px;
                background-color: $secondary_text;
                transform: translateY(-50%);
            }
        }

        .feature-post-title {
            margin: 1rem 0;
            font-size: $h2;
            line-height: $h2_line_height;
            font-weight: 600;
            position: relative;
            padding-bottom: 1.5rem;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100px;
                height: 7px;
                background-color: $success;
            }
        }

        .feature-post-description {
            color: $primary_text;
            font-size: 1.1rem;
            line-height: 1.8;
            margin-top: 2rem;
        }

        .feature-post-read-more {
            width: 100%;
            margin-top: 2rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 500;
            font-size: 0.9rem;
            color: $primary_text;
            transition: color 0.2s ease;

            &:hover {
                color: darken($primary_text, 15%);
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;

            .feature-post-content {
                display: flex;
                flex-direction: column;

                .featured-label {
                    order: 0;
                }

                .feature-post-title {
                    order: 1;
                }
            }

            .feature-post-img {
                display: none;
                order: 2;
                width: 100%;
                margin-top: 0;

                img {
                    width: 100%;
                    // max-height: 300px;
                }
            }

            .feature-post-description {
                order: 3;
            }

            .read-more {
                order: 4;
                margin-top: 1rem;
            }

            .feature-post-text {
                max-width: 100%;
            }
        }
    }
}


@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
    );
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
    );
}