@import '../../colors.scss';
@import '../../global.scss';

.admin-testkits {
  // max-width: $width;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .page-header {
    padding: 10px 15px;
  }

  .patient-name {
    color: $success;
  }

  a.tracking-link {
    color: $success;
  }

  .charge-amount {
    font-size: 12px;
  }

  .testkit-status {
    padding: 3px 8px;
    border-radius: 5px;
    // font-size: 13px;
    font-weight: 600;
  }

  .pending-status,
  .cancel-status {
    color: $secondary_text;
    background: $secondary_bg;
  }

  .collected-status {
    background: $warning_bg;
    color: $warning;
  }

  .complete-status {
    background: $success_bg;
    color: $success;
  }

  .canceled-status,
  .failed-status,
  .deactivated-status {
    color: $error;
    background: $error_bg;
  }


  .confirmed-status,
  .scheduled-status,
  .delivered-status,
  .shipped-status,
  .fulfilled-status,
  .fulfilling-status,
  .unresponsive-status {
    color: $primary_text;
    background: $secondary_bg;
  }

  .scroll-indicator {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 20px;
    background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    pointer-events: none; // to allow table scrolling
  }
}