@import '../../colors.scss';
@import '../../global.scss';
@import '../../mixins.scss';

.mobile-drawer-trigger {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(107, 114, 128, 0.95) 0%, #4b5563 100%); // Default gray gradient
    border-radius: 14px 14px 0 0;
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
    padding: 16px 20px;
    transition: transform 0.3s ease;
    z-index: 100;

    &.has-results {
        background: linear-gradient(180deg, rgba(12, 163, 127, 0.95) 0%, #0ca37f 100%);
    }

    &:active {
        transform: translateY(-4px);
    }

    .drawer-trigger-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .trigger-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        h2 {
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            color: white;
        }



        .pull-up-indicator {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .pull-up-indicator {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-left: auto;
        padding-left: 16px;

        .pull-text {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.9);
        }

        .pull-icons {
            display: flex;
            flex-direction: column;
            gap: 2px;
        }

        .pull-icon {
            color: rgba(255, 255, 255, 0.9);
            font-size: 16px;
            animation: float 1.5s ease-in-out infinite;

            &.delayed {
                animation-delay: 0.75s;
            }
        }
    }

    .results-preview {
        display: flex;
        align-items: center;
        gap: 6px;
        background: rgba(255, 255, 255, 0.2);
        padding: 6px 12px;
        border-radius: 20px;
        margin-top: 8px;
        margin-left: 16px;

        .results-icon {
            color: white;
        }

        span {
            color: white;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

.mobile-results-drawer {

    border-radius: 14px 14px 0 0;

    .results-panel {
        padding: 0;
        box-shadow: none;
        margin: none;
    }


    .ant-drawer-header {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;



        h2 {
            margin: 0;
            display: flex;
            align-items: center;

            .results-count {
                margin-left: 8px;

                &.ant-badge .ant-badge-count {
                    font-weight: 600;
                    background-color: $success;
                }
            }
        }

        .ant-drawer-close {
            display: none;
        }

        .close-icon {
            margin-left: auto;
        }

    }




    &.ant-drawer-open {
        .ant-drawer-content-wrapper {
            transform: translateY(0) !important;
        }
    }

    &:not(.ant-drawer-open) {
        .ant-drawer-content-wrapper {
            transform: translateY(100%) !important;
        }
    }


}