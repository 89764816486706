@import "../../colors.scss";

.admin-layout {
  position: relative;
  min-height: calc(100vh - 101px);

  .admin-row {
    width: 100%;  
  }

  .affix-sidebar {
    min-height: 100%;
    background: $secondary_bg;
  }

  .sidebar-column {
    border-right: 1px solid $border !important;
    z-index: 1;
  }
}