@import '../../global.scss';
@import '../../colors.scss';

.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $success_bg;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-top: -5vh;

  @media (max-width: 768px) {
    margin-top: 0;
    padding: 1rem 1rem 2rem;
  }

  .login-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    overflow: hidden;

    .ant-tabs {
      width: 100%;
      margin-top: 1rem;

      .ant-tabs-nav {
        border-bottom: none;
        margin-bottom: 24px;

        &::before {
          border: none;
        }

        .ant-tabs-nav-list {
          width: 100%;
          display: flex;
          gap: 12px;

          .ant-tabs-tab {
            flex: 1;
            margin: 0;
            padding: 12px;
            background: #f5f5f5;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            justify-content: center;

            &.ant-tabs-tab-active {
              background: #ffffff;
              border-color: $success;

              .ant-tabs-tab-btn {
                color: $success;
              }

              &:hover {
                border-color: $success;
              }
            }
          }

          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
    }



    .signup-redirect {
      margin: -1rem -2rem -2rem;
      padding: 2rem;

      p {
        margin-bottom: 1rem;
      }
    }

    h1 {
      text-align: center;
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #1a1a1a;
      word-wrap: break-word;
      overflow-wrap: break-word;

      @media (min-width: 768px) {
        font-size: 1.75rem;
      }
    }

    .ant-form-item-label {
      font-weight: 500;
    }

    // .ant-input,
    // .ant-input-password {
    //   height: 42px;
    //   border-radius: 6px;
    // }

    .ant-btn {
      width: 100%;
      height: 42px;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 6px;
    }
  }

  .login-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
    width: 100%;

    a {
      cursor: pointer;
      color: $success;
      transition: color 0.3s;
      font-size: 0.95rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}