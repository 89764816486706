@import '../../../colors.scss';

.next-steps {
  .next-steps-section {
    background: $secondary_bg;
    padding: 0;
    border: 0 !important;
  }

  a {
    color: $success !important;
    text-decoration: underline;
  }
}