.admin-notes-modal {
  .ant-modal-content,
  .ant-modal-header {
    background: white;
  }

  .ant-modal-title {
    color: #161718;
  }

  .test-details {
    color: #7B7E86;
  }

  .test-details strong {
    color: #161718;
  }

  .ant-modal-close-icon {
    color: #7B7E86;
  }

  .category-title {
    font-weight: 700;
    font-size: 13px;
    color: #161718;
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    text-decoration: underline;
  }

  .category-test-list .category-test-item:nth-child(2n+1) {
    background: #F9FBFC;
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }

  .category-test-item {
    display: flex;
    justify-content: space-between;
  }

  .test-name {
    font-size: 12px;
    color: #161718;
    width: 160px;
    font-weight: 700;
    padding: 7px 15px;
    line-height: 22px;
  }

  .test-tagline {
    font-size: 12px;
    color: #161718;
    flex: 1;
    border-left: 1px solid #f0f0f0;
    padding: 7px 15px;
    line-height: 22px;
  }

  .noteText {
    margin: 0;
  }

  .noteText p:first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 640px) {

    /* For mobile phones: */
    .test-name {
      padding: 5px 15px 0px;
      display: block;
    }

    .test-tagline {
      display: block;
      padding: 0px 15px 5px;
      border: 0px;
    }

    .category-test-item {
      display: block;
      //border: 1px solid #f0f0f0;
    }


  }
}