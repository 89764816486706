@import '../../colors.scss';

// Loading

.test-chart-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $secondary_bg;
  border-radius: 5px;

  .loading-icon {
    margin-right: 10px;
  }

  .loading-text {
    font-size: 16px;
    color: $secondary_text;
  }
}

// Prescription event

.prescription-event {
  position: absolute;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 3px;
  border: 1px solid $border;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 7px;

  &:hover {
    z-index: 10; // Bring to front on hover
    transform: translateX(-50%) scale(1.05); // Optional: slight scale effect
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); // Optional: add shadow on hover
  }
  
  &.paused {
    border-left: 3px solid $error;
  }
  
  &.started {
    border-left: 3px solid $success;
  }

  .event-type {
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 1px;
    white-space: nowrap;
  }

  .timestamp {
    color: #999;
    font-size: 11px;
    white-space: nowrap;
  }
}


@media (max-width: 1200px) {
  .test-chart-component {
    display: block !important;
    height: auto !important;
    overflow: hidden;

    .chart-area {
      height: 450px !important;
      width: 100% !important;
    }

    .chart-container {
      height: 450px !important;
    }
    
    .test-chart-sidebar {
      width: 100% !important;
      position: relative;
      transform: none;
      border-left: none;
      border-top: 1px solid #eee;
    }
  }

  .sidebar-content {
    width: 100% !important;
  }
}

// Component

.test-chart-component {
  display: flex;
  height: 100%;

  .test-chart-row,
  .test-chart-row .ant-col{
    height: 100%;
  }

  .chart-area {
    flex: 1;
    transition: width 0.3s ease;
    height: 100%;
  }
  
  .chart-container {
    position: relative;
    padding: 20px 20px 20px 20px;
    height: 100%;
    overflow: visible; // Show overflow items
    z-index: 2;
  }
  
  .test-chart-sidebar {
    width: 100%;
    background-color: $secondary_bg;
    transition: width 0.3s ease;
    position: relative;
    border-left: 1px solid $border;
    z-index: 1;
    height: 100%;
    overflow-y: auto;
  }
  
  .sidebar-content {
    padding: 20px 20px 10px 20px;
    overflow-y: auto;
    height: 100%;
    transition: opacity 0.3s ease;
  }

  // Sidebar content
  
  .latest-result {
    .ant-statistic {
      margin-bottom: 20px;
      background: rgba(255,255,255,0.6);
      padding: 15px 15px 10px 15px;
      margin-bottom: 10px;
      border-radius: 5px;
    }
  
    .ant-statistic-title {
      font-size: 14px;
      color: #999;
    }
  
    .anticon {
      font-size: 19px;
    }
  
    .ant-statistic-content-value {
      font-size: 22px;
    }
  
    .ant-statistic-content-suffix {
      font-size: 14px;
      color: #999;
    }
  }
  
  .test-details {
    font-size: 14px;
    color: $primary_text;
  }

  .event-label {
    .box {
      background: white;
      border-radius: 4px;
      padding: 8px 12px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      border-left: 3px solid $success;
      margin-bottom: 8px;
      min-width: 200px;
      max-width: 300px;

      &.negative {
        border-left-color: $error;
      }

      &.positive {
        border-left-color: $success;
      }

      .title {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
      }

      .subtitle {
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-bottom: 4px;
      }

      .content {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        word-wrap: break-word;
      }
    }
  }
}