@import '../../colors.scss';

.transfer-modal {
  .adm-selector-item {
    background: $secondary_bg;
    color: $primary_text;
    border-radius: 3px;
    font-size: 12px;
    line-height: 22px;
    border: 1px solid $border;
  }

  .adm-selector-item-multiple-active,
  .adm-selector-item-active {
    color: $success;
    background: $primary_bg;
    border: 1px solid $success;
  }

  .adm-selector-check-mark-wrapper {
    border-right-color: $success;
    border-bottom-color: $success;
  }

  .adm-list-item {
    padding-left: 0;
    background: rgba(0,0,0,0);
  }  

  .ant-form-item {
    margin-bottom: 10px;
    margin-top: 0;
  }
}