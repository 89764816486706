@import '../../../global.scss';
@import '../../../colors.scss';


@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
) {
    .product-detail-testimonials {
        margin: auto;
        display: flex;
        list-style: none;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-padding: 1rem;
        gap: 2rem;
        overflow-y: auto;

        .product-detail-testimonial {
            scroll-snap-align: start;
            max-width: 25rem;
            min-width: 15rem;

            .product-detail-testimonial-name {
                font-weight: 600;
                padding: 15px 0px;
                font-size: $h4;
            }

            .product-detail-testimonial-quote {
                font-size: $h4;
                line-height: $h4_line_height;
                color: $secondary_text;
            }

            .product-detail-testimonial-thumbnail {
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: grayscale(33%);
                    object-position: center;
                }


            }

            .product-detail-testimonial-photo {


                img {
                    filter: grayscale(33%);
                    object-fit: cover;
                    object-position: center;
                    width: 15rem;
                }
            }

            .product-detail-testimonial-title {
                display: block;
                font-weight: normal;
            }
        }

        .video-thumbnail {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;

            img {
                width: 30px; // Adjust the width to a smaller size
                height: 30px; // Adjust the height to a smaller size
                display: block;
                pointer-events: none; // Ensure the image does not block clicks
            }
        }
    }

    @media (max-width: $breakpoint_desktop) {
        .product-detail-testimonial-section-container {
            padding-right: 0px !important;

            .product-detail-testimonial:last-child {
                margin-right: 15px;
            }
        }
    }

    .video-modal {
        .ant-modal-content {
            max-width: 90vw;
            padding: 0;
        }

        .video-container {
            position: relative;
            padding-bottom: 56.25%;
            /* 16:9 */
            height: 0;
            overflow: hidden;
            max-width: 100%;
            background: #000;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }
    }

    .video-modal.ant-modal {
        border: none !important
    }
}


@media screen and (max-width: $breakpoint_mobile) {
    .video-modal {
        .ant-modal-content {
            max-width: 100vw;
            margin: 0 auto;
            padding: 0;
        }

        .video-container {
            padding-bottom: 75%;
            /* 4:3 aspect ratio for mobile */
        }
    }

}

@media screen and (max-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
    );

    .video-modal {
        .ant-modal-content {
            max-width: 90vw;
            margin: 0;
            padding: 0;
        }

        .video-container {
            padding-bottom: 56.25%;
            /* 16:9 aspect ratio for tablets */
        }
    }
}

@media screen and (min-width: $breakpoint_tablet) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
    );

    .video-modal {
        .ant-modal-content {
            max-width: 80vw;
        }

        .video-container {
            padding-bottom: 56.25%;
            /* 16:9 aspect ratio for desktop */
        }
    }
}