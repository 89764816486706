@import '../../colors.scss';
@import '../../global.scss';
@import '../../mixins.scss';

@mixin styling($horizontal_padding,
    $vertical_padding,
    $h1,
    $h1_line_height,
    $h2,
    $h2_line_height,
    $h3,
    $h3_line_height,
    $h4,
    $h4_line_height,
    $h5,
    $h5_line_height,
    $h6,
    $h6_line_height,
) {
    .test-recommender-container {

        .intro-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }


    .test-recommender-container,
    .mobile-results-drawer {

        .desktop-only {
            display: block;
        }

        .mobile-only {
            display: none;
        }


        .panels-container {

            max-width: 1200px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            margin: 2rem auto;
            padding: 0 2rem;
            min-height: 600px;



            @media (max-width: 768px) {
                margin: 0rem auto;
            }
        }

        .results-wrapper {
            order: 1; //
        }

        .questions-panel,
        .results-panel {
            background: white;
            border-radius: 5px;
            padding: 2.5rem;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);

            h2 {
                color: #2c3e50;
                margin-bottom: 2rem;
                font-size: $h3;

                .results-count {

                    &.ant-badge .ant-badge-count {
                        font-weight: 600;
                        background-color: $success;
                    }

                    &.animateBadge {
                        animation: pulsateBadge 1.5s ease-in-out infinite;
                    }
                }
            }
        }

        .questions-panel {
            background-color: #f8fafc;
        }

        .loading-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 200px;
        }


        .form-group {
            margin-bottom: 24px;
            padding: 20px;
            background-color: #fff;
            border: 1px solid #e4e9f0;
            border-radius: 5px;
            transition: box-shadow 0.2s ease;

            &:hover {
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
            }
        }

        .form-group label {
            font-weight: 600;
            display: block;
            margin-bottom: 9px;
            font-size: $h5;
        }

        .form-input {
            padding: 0.75rem;
            border: 1px solid #e2e8f0;
            border-radius: 5px;
            font-size: 1rem;
            transition: border-color 0.2s ease;

            &.invalid {
                border-color: $error;
                background-color: #fff2f0;

                &:focus {
                    border-color: #ff7875;
                    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
                    outline: none;
                }
            }
        }

        .form-input:focus {
            border-color: #73b8ff;
            outline: none;
        }


        .radio-cards-group,
        .checkbox-cards-group {
            display: grid;
            gap: 8px;
            margin-top: 8px;

            @media (min-width: 768px) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }

            label {
                display: flex;
                font-size: $h6;
                font-weight: 500;
                margin-bottom: 0;
            }
        }

        .radio-card,
        .checkbox-card {
            padding: 12px 16px;
            border: 1px solid #e2e8f0;
            border-radius: 5px;
            cursor: pointer;
            transition: all 0.2s;
            display: flex;
            align-items: center;

            &:hover {
                border-color: #73b8ff;

                .ant-checkbox-inner,
                .ant-radio-inner {
                    border-color: #1890ff;
                }

            }

            &.selected {
                border-color: #73b8ff;
                background-color: rgba(24, 144, 255, 0.1);
            }

            input {
                // accent-color: $success;
                margin-right: 5px;
            }



        }

        .description {
            font-size: 0.9rem;
            color: #666;
            margin-top: 5px;
        }

        .results-panel {
            max-height: calc(100vh - 48px);
            display: flex;
            flex-direction: column;
            position: relative;

            .results-subtitle {
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 16px;
                font-size: 14px;
            }
        }

        .results-scroll-container {
            flex: 1;
            overflow-y: auto;
            padding-right: 8px;

            &.has-overflow {
                mask-image: linear-gradient(to bottom, black calc(100% - 40px), transparent 100%);
                -webkit-mask-image: linear-gradient(to bottom, black calc(100% - 40px), transparent 100%);
            }

            &::-webkit-scrollbar {
                width: 8px;
            }

            &::-webkit-scrollbar-track {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 4px;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.3);
                }
            }

            &:not(:hover)::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.1);
            }

            scrollbar-width: thin;
            scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        }

        .tests-list {

            margin-top: 12px;

            .test-card {
                background: #fff;


                &.animate-in {
                    animation: highlightBorder 2s ease;
                }

                .test-card-content {
                    display: flex;
                    gap: 12px;

                    .rank-column {
                        flex-shrink: 0;

                        .test-number {
                            font-size: 18px;
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.45);
                        }
                    }

                    .test-info {
                        flex-grow: 1;

                        h3 {
                            margin-bottom: 4px;
                            margin-top: 1px;
                            font-size: $h4;
                            font-weight: 700;
                        }

                        .test-tagline {
                            margin-bottom: 12px;
                            color: rgba(0, 0, 0, 0.65);
                        }

                        .test-footer {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .frequency-badge {
                                background-color: $success_bg;
                                color: $success;
                                padding: 4px 8px;
                                border-radius: 12px;
                                font-size: 12px;
                                font-weight: 600;
                                margin-right: 6px;
                            }

                            .cost-badge {
                                font-family: monospace;

                            }

                            .insurance-badge {
                                background-color: $success_bg;
                                color: $success;
                                padding: 4px 8px;
                                border-radius: 12px;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .test-actions {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    margin-top: 12px;
                }
            }

            .test-actions {
                display: flex;
                flex-direction: column;
                gap: 8px;
                margin-top: 12px;
            }
        }

        .test-header {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 4px;
        }

        .test-number {
            font-size: 1.2em;
            font-weight: 600;
            color: #666;
            min-width: 32px;
        }

        .test-title {
            font-weight: 500;
            flex: 1;
        }




        .empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 200px;
            color: #718096;
            text-align: center;
            background: #f8fafc;
            border-radius: 5px;
            padding: 2rem;


            p {
                max-width: 400px;
                margin: 1rem auto;
                font-size: $h5;
            }

            .arrow-up {
                display: none;
                font-size: $h2;
                text-align: center;
                margin-top: 10px;
                animation: bounce-up 1s infinite;
            }

            .arrow-left {
                display: block;
                font-size: $h2;
                line-height: 1;
                color: #666;
                margin: 0px auto;
                animation: bounce-left 1s infinite;

            }

            @media (max-width: 768px) {
                .arrow-left {
                    display: none;
                }

                .arrow-up {
                    display: block;
                }

                padding: 0rem 2rem 2rem;
            }



        }



        .membership-upsell {
            margin-bottom: 20px;
            background: linear-gradient(to right, #f0f7ff, #ffffff);
            border: 1px solid #91caff;

            .upsell-content {
                .card-title {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 16px;
                    color: #161718;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }

                p {
                    margin-bottom: 8px;
                    font-size: $h6;
                }

                ul {

                    font-size: $h6;
                    list-style: none;
                    padding: 0;
                    margin-bottom: 16px;


                    .benefit-item {
                        display: flex;
                        align-items: center;
                        gap: '8px';
                        margin-bottom: 12px;
                        font-size: 14px;
                    }

                    .anticon {
                        color: $success;
                    }
                }
            }

            button {
                width: 100%;
            }
        }
    }

}


@media screen and (max-width: 768px) {
    @include styling($horizontal_padding: $mobile_horizontal_padding,
        $vertical_padding: $mobile_vertical_padding,
        $h1: $mobile_h1,
        $h1_line_height: $mobile_h1_line_height,
        $h2: $mobile_h2,
        $h2_line_height: $mobile_h2_line_height,
        $h3: $mobile_h3,
        $h3_line_height: $mobile_h3_line_height,
        $h4: $mobile_h4,
        $h4_line_height: $mobile_h4_line_height,
        $h5: $mobile_h5,
        $h5_line_height: $mobile_h5_line_height,
        $h6: $mobile_h6,
        $h6_line_height: $mobile_h6_line_height,
    );

    .test-recommender-container,
    .mobile-results-drawer {

        .desktop-only {
            display: none;
        }

        .mobile-only {
            display: block;
        }

        .results-panel {
            padding: 0;
            box-shadow: none;
        }

    }

    .mobile-results-drawer {
        .test-card {
            .test-card-content {
                gap: 8px !important;
            }
        }
    }

    .test-recommender-container {
        background: #f8fafc;
        padding-bottom: 60px;


        .panels-container {
            grid-template-columns: 1fr;
            padding: 0 1rem;


        }

        .questions-panel {
            order: -1; // Questions first on mobile
            padding: 1rem;

            h2 {
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .results-wrapper {
            order: 1; // Results second on mobile

        }


        .questions-panel {
            grid-template-columns: 1fr;
            padding-top: 1rem;
            background: none;
            box-shadow: none;
        }


    }


}

@media screen and (min-width: 769px) {
    @include styling($horizontal_padding: $desktop_horizontal_padding,
        $vertical_padding: $desktop_vertical_padding,
        $h1: $desktop_h1,
        $h1_line_height: $desktop_h1_line_height,
        $h2: $desktop_h2,
        $h2_line_height: $desktop_h2_line_height,
        $h3: $desktop_h3,
        $h3_line_height: $desktop_h3_line_height,
        $h4: $desktop_h4,
        $h4_line_height: $desktop_h4_line_height,
        $h5: $desktop_h5,
        $h5_line_height: $desktop_h5_line_height,
        $h6: $desktop_h6,
        $h6_line_height: $desktop_h6_line_height,
    );

    .desktop-only {
        display: block;
    }

    .mobile-only {
        display: none;
    }

}