@import '../../colors.scss';

.admin-location-modal {
  .ant-input-status-error {
    background: $error_bg !important;
  }

  .ant-form-item-explain-error {
    float: left;
    background: $error_bg;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}