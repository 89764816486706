.gift-banner {
    background: linear-gradient(135deg, #c41e3a 0%, #d42426 100%);
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid #a41830;
    font-size: 16px;
    font-weight: 500;
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
    }

    &::before {
        background:
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.25)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 85% 70% / 16px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.2)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 10% 40% / 24px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.22)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 50% 30% / 20px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.24)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 15% 15% / 18px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.21)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 80% 85% / 16px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.23)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 30% 90% / 22px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.25)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 95% 25% / 19px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.22)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 20% 65% / 21px no-repeat;
    }

    &::after {
        background:
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.18)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 25% 60% / 18px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.23)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 70% 20% / 22px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.21)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 40% 80% / 14px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.19)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 90% 40% / 20px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.22)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 5% 70% / 16px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.2)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 60% 50% / 24px no-repeat,
            url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgba(255,255,255,0.24)' d='M11 2h2v5.17l4.59-4.58L19 4l-4.58 4.59L20 8.59v2h-5.17l4.58 4.59L19 16.59l-4.59-4.58L14.41 18H12v-5.17l-4.59 4.58L6 16l4.58-4.59L5 11.41v-2h5.17L5.59 4.82 7 3.41 11.41 7.83V2z'/%3E%3C/svg%3E") 75% 75% / 17px no-repeat;
    }

    .gift-icon {
        margin-right: 8px;
        font-size: 24px;
        display: flex;
        align-items: center;
    }

    .gift-button {
        color: #c41e3a;
        font-weight: 600;
        cursor: pointer;
        padding: 6px 16px;
        background: white;
        border-radius: 20px;
        display: inline-block;
        margin-left: 12px;
        transition: all 0.2s ease;
        text-shadow: none;
        font-size: 16px;

    }

    .arrow-icon {
        margin-left: 4px;
        font-size: 12px;
    }

    .mobile-message {
        display: none;
    }

    @media (max-width: 768px) {
        .desktop-message {
            display: none;
        }

        .mobile-message {
            display: inline;
        }
    }



    .close-button {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        padding: 4px;
        color: inherit;


        &:hover {
            opacity: 0.8;
        }
    }

    @media (max-width: 768px) {
        .close-button {
            right: 4px;
        }

        .gift-button {
            margin-left: 6px;
        }
    }
}