@import '../../colors.scss';

.intake-modal {
  .intake-description {
    color: $primary_text;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .section-progress {
    float: left;
    width: 100%;
    margin-bottom: 3px;

    .adm-progress-bar-trail,
    .adm-progress-bar-fill {
      border-radius: 0;
      margin-right: 5px;
    }

    &:first-child {
      .adm-progress-bar-trail,
      .adm-progress-bar-fill {
        border-radius: 5px 0 0 5px;
      }
    }

    &:last-child {

      .adm-progress-bar-trail,
      .adm-progress-bar-fill {
        border-radius: 0 5px 5px 0;
        margin-right: 0;
      }
    }
  }

  .adm-selector-item-active,
  .adm-selector-item-multiple-active {
    background: $success_bg;
    color: $success;
    
    .adm-selector-check-mark-wrapper {
      border-bottom-color: $success;
      border-right-color: $success;
    }
  }

  .step-counter {
    color: $secondary_text;
    width: 100%;
    display: block;
    text-align: right;
    font-size: 12px;
  }

  .step-title {
    color: $primary_text;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;

    span {
      color: $secondary_text;
      font-size: 15px;
      line-height: 1.2rem;
      font-weight: normal;
      display: block;
      margin-top: 5px;
    }
  }

  .step-caption {
    color: gray;
    margin-left: 10px;
    font-weight: 500;
  }

  .intake-form {
    margin-top: 30px;
    margin-bottom: 30px;
  }

}

// .ant-modal-root .ant-modal-mask {
//   background-color: gray !important
// }