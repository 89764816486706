@import '../../global.scss';
@import '../../colors.scss';

.admin-referrals {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .no-provider .ant-table-cell {
    background-color: $error_bg !important;
  }


}

.admin-referrals-form {

  .ant-select {
    width: 100%;

    .ant-select-selector {
      padding: 8px 12px !important;
      font-size: 14px;
      display: flex;
      align-items: center;

      .ant-select-selection-item {
        line-height: 1.5;
      }

      input {
        padding: 8px 12px !important;

      }


    }

    .ant-select-selection-placeholder {
      line-height: 1.5;
    }

    .ant-select-arrow {
      // top: 50% !important;
      /* Adjust to vertically center the caret */
      transform: translateY(50%) !important;
    }
  }
}