@import '../../colors.scss';
@import '../../global.scss';

.admin-appointments-table {
  .panel-count {
    color: $secondary_text;
  }

  .appointment-status {
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 700;
  }

  .pending-status {
    color: $secondary_text;
    background: $secondary_bg;
    font-size: 13px;
  }

  .collected-status {
    background: $warning_bg;
    color: $warning;
  }

  .complete-status {
    background: $success_bg;
    color: $success;
  }

  .canceled-status,
  .deactivated-status {
    color: $error;
    background: $error_bg;
  }

  .delivered-status,
  .confirmed-status,
  .scheduled-status {
    color: $primary_text;
    background: $secondary_bg;
  }

  .patient-name {
    color: $success;
    font-size: 13px;
  }

  .invite-contact {
    color: $secondary_text;
    font-size: 13px;
  }

  .edit-btn {
    margin: -5px 0;
  }

  .ant-table-row {
    &.pending-row td.ant-table-cell {
      background-color: #fff1f0 !important;
    }
  }

  .ant-table-cell {
    .anticon-file-text {
      font-size: 16px;
      vertical-align: middle;
      cursor: pointer;
      opacity: 0.65;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.charging-text {
  color: #888;
}