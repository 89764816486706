@import '../../../colors.scss';
@import '../../../global.scss';

@mixin styling(
  $horizontal_padding,
  $vertical_padding,
  $h1,
  $h1_line_height,
  $h2,
  $h2_line_height,
  $h3,
  $h3_line_height,
  $h4,
  $h4_line_height,
  $h5,
  $h5_line_height,
) {
  .pro {
    // shared

    .section-label {
      color: $success;
      font-size: 16px;
      margin: 0;
    }

    .section-title {
      font-weight: 600;
      font-size: $h1;
      line-height: $h1_line_height;
      margin: 0 0 15px 0;
      color: $primary_text;

      a {
        color: $success;
        text-decoration: underline;
      }
    }

    .section-subtitle {
      color: $secondary_text;
      font-size: $h3;
      line-height: $h3_line_height;
      margin: 0;
    }

    .section-btn {
      height: 44px;
      font-weight: 600;
      font-size: 16px;
      width: 250px;
    }

    .default-btn {

      &:hover {
        border-color: $success !important;
        color: $success !important;
      }

      &:active {
        border-color: darken($success, 10) !important;
        color: darken($success, 10) !important;
      }
    }

    // sections

    .pro-sections {
      max-width: 1200px;
      margin: 0 auto;
      padding: $vertical_padding $horizontal_padding;
    
      .intro {
        text-align: center;
        margin-top: $vertical_padding;

        .section-subtitle {
          margin-bottom: 30px;
        }

        .section-btn {
          margin: 10px 5px 0 5px;
        }
  
        .intro-img {
          width: 100%;
          margin-top: 60px;
        }
      }
  
      .how {
        text-align: center;
        margin-top: calc($vertical_padding * 2);
  
        .how-item {
          text-align: left;
        }
  
        .how-index {
          background: $secondary_bg;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
          color: $primary_text;
          border-radius: 4px;
          margin-top: $vertical_padding;
        }
  
        .how-title {
          color: $primary_text;
          font-weight: 600;
          font-size: 20px;
          margin: calc($vertical_padding / 2) 0 0 0;
        }
  
        .how-description {
          color: $primary_text;
          font-size: 16px;
          margin: 10px 0px 30px 0;
        }
  
        .how-description a {
          color: $success;
          text-decoration: underline;
        }
  
        .how-img {
          width: 100%;
          padding: 10px;
          background: $secondary_bg;
          border-radius: 10px;
        }
      }
  
      .quotes {
        text-align: center;
        margin-top: calc($vertical_padding * 2);
  
        .quote-list {
          margin-top: 40px;
        }
  
        .quote-item {
          text-align: left;
          margin-bottom: 10px;
        }
  
        .quote-img {
          width: 100%;
        }
  
        .quote-text {
          padding: 25px;
          background: $secondary_bg;
          border-radius: 0 0 10px 10px;
          position: relative;
          margin-top: -5px;
        }
  
        .triangle-img {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 100%;
        }
  
        .quote-content {
          font-size: 16px;
        }
  
        .quote-name {
          font-size: 16px;
          font-weight: 600;
        }
      }
  
      .access {
        background: $success;
        border-radius: 10px;
        margin-top: calc($vertical_padding * 2);
        padding: $vertical_padding $horizontal_padding;
  
        .access-title {
          color: white;
          margin-bottom: 40px;
          font-weight: 600;
          font-size: $h2;
          line-height: $h2_line_height;
        }
  
        .check-col {
          max-width: 30px;
        }
  
        .access-icon {
          color: rgba(255,255,255,0.8);
          margin-top: 5px;
        }
  
        .access-content {
          font-size: 16px;
          color: white;
        }
  
        .section-btn {
          margin-top: calc($vertical_padding / 2);
        }
      }
  
      .faq {
        margin-top: calc($vertical_padding * 2);
        text-align: center;
  
        .questions {
          text-align: left;
          max-width: 100%;
          padding: 0;
        }
  
        .answer a {
          color: $success;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  @include styling($horizontal_padding: $mobile_horizontal_padding,
    $vertical_padding: $mobile_vertical_padding,
    $h1: $mobile_h1,
    $h1_line_height: $mobile_h1_line_height,
    $h2: $mobile_h2,
    $h2_line_height: $mobile_h2_line_height,
    $h3: $mobile_h3,
    $h3_line_height: $mobile_h3_line_height,
    $h4: $mobile_h4,
    $h4_line_height: $mobile_h4_line_height,
    $h5: $mobile_h5,
    $h5_line_height: $mobile_h5_line_height,
  );
}

@media screen and (min-width: 769px) {
  @include styling($horizontal_padding: $desktop_horizontal_padding,
    $vertical_padding: $desktop_vertical_padding,
    $h1: $desktop_h1,
    $h1_line_height: $desktop_h1_line_height,
    $h2: $desktop_h2,
    $h2_line_height: $desktop_h2_line_height,
    $h3: $desktop_h3,
    $h3_line_height: $desktop_h3_line_height,
    $h4: $desktop_h4,
    $h4_line_height: $desktop_h4_line_height,
    $h5: $desktop_h5,
    $h5_line_height: $desktop_h5_line_height,
  );
}